// material-ui
import { blue, grey } from '@mui/material/colors';
import { Grid, Button, Typography, Link, Popover, Menu, MenuItem, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/system';
import TopLayout from '../TopLayout';
import FilterERC20Token from '../FilterERC20Token';
import FilterERC20Amount from '../FilterERC20Amount';
import FilterERC20Duration from '../FilterERC20Duration';
import LeaseMyCard from './LeaseMyCard';
import LeaseSimpleCard from './LeaseSimpleCard';
import ArticleIcon from '@mui/icons-material/Article';
import CreateLendOffer from './CreateLendOffer';
import { getOfferInRange } from 'components/wallet/sharesABI';
import { useWeb3React } from '@web3-react/core';
import ConnectWalletCard from '../ConnectWalletCard';
import { brandColor } from 'themes/constants';
import TelegramIcon from '@mui/icons-material/Telegram';
import FilterERC20PaymentToken from '../FilterERC20PaymentToken';
import CancelIcon from '@mui/icons-material/Cancel';
import LearnHowItWorksLease from './LearnHowItWorksLease';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { FaHandshake } from 'react-icons/fa';

const LendAndBorrowMain = () => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [offers, setOffers] = useState([]);
    const [howitworksModal, setHowitworksModal] = useState(false);
    const [filters, setFilters] = useState({
        amount: [0, 0],
        duration: [0, 0],
        paymentToken: ''
    });
    const [cards, setCards] = useState({
        offerCard: false,
        tokenCard: false,
        amountCard: false,
        paymentTokenModal: false,
        durationCard: false
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeContent, setActiveContent] = useState();
    const [activeSteps, setActiveSteps] = useState();

    const handleFilterChange = (field, value) => {
        setFilters((prev) => ({ ...prev, [field]: value }));
    };

    const handleCardToggle = (card, state) => {
        setCards((prev) => ({ ...prev, [card]: state }));
    };

    const refetchOffers = async () => {
        const tmpoffers = await getOfferInRange(0, 50);
        setOffers(tmpoffers);
        console.log(tmpoffers);
    };

    useEffect(() => {
        const fetchOffers = async () => {
            const tmpoffers = await getOfferInRange(0, 10);
            setOffers(tmpoffers);
            console.log(tmpoffers);
        };
        fetchOffers();
    }, [account, active]);

    const filteredOffers = offers
        .map((item, index) => ({ ...item, originalIndex: index })) // Step 1: Map to add original index
        .filter((item) => parseFloat(item[4]) !== 0) // Step 2: Filter out items where amountA is zero
        .filter((item) =>
            filters.amount[0] === 0 && filters.amount[1] === 0 ? true : item[4] >= filters.amount[0] && item[4] <= filters.amount[1]
        ) // Filter by amount
        .filter((item) =>
            filters.duration[0] === 0 && filters.duration[1] === 0
                ? true
                : item[7] * item[6] - item[7] >= filters.duration[0] && item[7] * item[6] - item[7] <= filters.duration[1]
        ) // Filter by duration
        .filter((item) => (filters.paymentToken.address ? item[2] === filters.paymentToken.address : true)); // Filter by payment token

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

    return (
        <>
            <Grid
                container
                pt={15}
                bgcolor={theme.palette.mode === 'dark' ? '#191818' : 'transparent'}
                borderBottom={1}
                pb={8}
                display="flex"
                sx={{ justifyContent: { lg: 'space-evenly', md: 'space-evenly', sm: 'center', xs: 'center' } }}
            >
                <Grid item lg={4} md={7} sm={9} xs={11.5}>
                    <TopLayout
                        title="Lend for interest or borrow for capital."
                        text="Enhance your crypto portfolio with DeFi. Lend tokens to earn interest or borrow to access extra capital. Experience secure, transparent, and efficient financial services."
                    />
                    <Grid
                        item
                        display="flex"
                        mb={2}
                        mt={5}
                        sx={{
                            justifyContent: { lg: 'left', md: 'left', sm: 'space-between', xs: 'center' },
                            flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' }
                        }}
                    >
                        <Button
                            startIcon={<AddIcon sx={{ color: grey[200] }} />}
                            onClick={() => handleCardToggle('offerCard', true)}
                            sx={{
                                pr: 2,
                                height: 40,
                                bgcolor: brandColor,
                                borderRadius: 2,
                                color: grey[100],
                                textTransform: 'none',
                                width: { lg: 'auto', md: 'auto', sm: '48%', xs: '100%' }
                            }}
                        >
                            I want to lend tokens
                        </Button>
                        <Button
                            startIcon={<ArticleIcon sx={{ color: grey[200] }} />}
                            onClick={handlePopoverClick}
                            sx={{
                                px: 2,
                                height: 40,
                                borderRadius: 2,
                                color: grey[100],
                                textTransform: 'none',
                                bgcolor: theme.palette.card.light,
                                ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                                mt: { lg: 0, md: 0, sm: 0, xs: 2 },
                                width: { lg: 'auto', md: 'auto', sm: '48%', xs: '100%' }
                            }}
                        >
                            Learn how it works
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    lg={3.3}
                    md={4}
                    sm={8}
                    flexDirection="column"
                    sx={{ justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }, mt: 4 }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 14,
                            fontWeight: 500,
                            textAlign: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        Looking for a partnership?
                    </Typography>
                    <Typography
                        sx={{
                            color: blue[500],
                            fontSize: 14,
                            fontWeight: 500,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        <Link
                            href="https://www.t.me/alphadao_token"
                            color={blue[500]}
                            underline="none"
                            fontWeight={500}
                            target="_blank"
                            rel="noopener"
                        >
                            Apply here
                        </Link>
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 14,
                            mt: 2,
                            fontWeight: 500,
                            textAlign: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        Need help?
                    </Typography>
                    <Typography
                        sx={{
                            color: blue[500],
                            fontSize: 14,
                            fontWeight: 500,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        <TelegramIcon sx={{ mr: 0.5 }} />
                        <Link
                            href="https://www.t.me/alphadao_token"
                            color={blue[500]}
                            underline="none"
                            fontWeight={500}
                            target="_blank"
                            rel="noopener"
                        >
                            Join our Telegram
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 5,
                    backgroundColor: theme.palette.background.default
                }}
            >
                {account && active ? (
                    <Grid item xs={11.7} lg={8.9} md={9.7} display="flex" justifyContent="center" flexDirection="row">
                        <Grid container justifyContent="left">
                            <LeaseMyCard />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item display="flex" justifyContent="center" lg={11} xs={11.7} md={12}>
                        <ConnectWalletCard text="lend, borrow or view your dashboard" />
                    </Grid>
                )}
                <Grid item xs={11.4} lg={8.9} md={9.6} sm={11.4}>
                    <Grid item width="100%" mt={2}>
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 24 }}>Filters</Typography>
                    </Grid>
                    <Grid
                        item
                        display="flex"
                        justifyContent="space-between"
                        lg={5.5}
                        md={7}
                        mt={1}
                        boxShadow={6}
                        px={1}
                        border={1}
                        borderRadius={2}
                        sx={{ background: theme.palette.card.main }}
                    >
                        <Button
                            onClick={() => handleCardToggle('tokenCard', true)}
                            variant="text"
                            endIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />}
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                Any token
                            </Typography>
                        </Button>
                        <Button
                            onClick={() =>
                                filters.amount[1] !== 0 ? handleFilterChange('amount', [0, 0]) : handleCardToggle('amountCard', true)
                            }
                            variant="text"
                            endIcon={
                                filters.amount[1] === 0 ? (
                                    <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                                )
                            }
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                {filters.amount[1] === 0
                                    ? `Amount`
                                    : `${(filters.amount[0] / 1e18).toLocaleString('en-US', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                      })} - ${(filters.amount[1] / 1e18).toLocaleString('en-US', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                      })}`}
                            </Typography>
                        </Button>
                        <Button
                            onClick={() =>
                                filters.paymentToken.address
                                    ? handleFilterChange('paymentToken', '')
                                    : handleCardToggle('paymentTokenModal', true)
                            }
                            variant="text"
                            endIcon={
                                filters.paymentToken === '' ? (
                                    <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                                )
                            }
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                {filters.paymentToken === '' ? 'Collateral' : filters.paymentToken.symbol}
                            </Typography>
                        </Button>
                        <Button
                            variant="text"
                            onClick={() =>
                                filters.duration[1] !== 0 ? handleFilterChange('duration', [0, 0]) : handleCardToggle('durationCard', true)
                            }
                            endIcon={
                                filters.duration[1] === 0 ? (
                                    <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                                )
                            }
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                {filters.duration[1] === 0 ? `Grace period` : `${filters.duration[0]} - ${filters.duration[1]} days`}
                            </Typography>
                        </Button>
                    </Grid>
                    <FilterERC20Token onClose={() => handleCardToggle('tokenCard', false)} isOpen={cards.tokenCard} />
                    <FilterERC20Amount
                        onClose={() => handleCardToggle('amountCard', false)}
                        isOpen={cards.amountCard}
                        onAmountChange={(num1, num2) => handleFilterChange('amount', [num1, num2])}
                    />
                    <FilterERC20PaymentToken
                        onClose={() => handleCardToggle('paymentTokenModal', false)}
                        isOpen={cards.paymentTokenModal}
                        onTokenSelect={(token) => handleFilterChange('paymentToken', token)}
                    />
                    <FilterERC20Duration
                        onClose={() => handleCardToggle('durationCard', false)}
                        isOpen={cards.durationCard}
                        onDurationChange={(num1, num2) => handleFilterChange('duration', [num1, num2])}
                    />
                </Grid>
                <Grid container sx={{ width: { lg: '75%', md: '82%' } }} mb={8}>
                    {filteredOffers.map((item) => (
                        <Grid item lg={3} md={4} key={item.originalIndex} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LeaseSimpleCard
                                lessor={item[0]}
                                tokenA={item[1]}
                                tokenB={item[2]}
                                originalSize={item[3]}
                                amountA={item[4]}
                                reservedPrice={item[5]}
                                installments={item[6]}
                                offset={item[7]}
                                id={item.originalIndex} // Use original index as id
                                onPayment={refetchOffers}
                            />
                        </Grid>
                    ))}
                </Grid>
                <LearnHowItWorksLease
                    open={howitworksModal}
                    setOpen={setHowitworksModal}
                    activeContent={activeContent}
                    activeSteps={activeSteps}
                />
                <CreateLendOffer
                    handleOpen={cards.offerCard}
                    onClose={() => handleCardToggle('offerCard', false)}
                    onOfferCreated={refetchOffers}
                />
            </Grid>
            <Popover
                id={popoverId}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    sx: {
                        width: 'auto',
                        bgcolor: 'transparent',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 3,
                        p: 0.5
                    }
                }}
            >
                <Menu
                    open={isPopoverOpen}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    sx={{
                        '.MuiPaper-root': {
                            backgroundColor: 'transparent',
                            backdropFilter: 'blur(10px)',
                            borderRadius: 2
                        }
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setActiveSteps(0);
                            setActiveContent(0);
                            setHowitworksModal(true);
                        }}
                    >
                        <Grid container width="100%" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container width="100%" sx={{ display: 'flex' }}>
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 500, mr: 1 }}>
                                    Take lease offer
                                </Typography>
                                <Chip
                                    size="small"
                                    label={<FaHandshake style={{ color: theme.palette.text.invertedsecondary, marginTop: 5 }} />}
                                />
                            </Grid>
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 13 }}>
                                Quick guide on how to take a lease in an instant.
                            </Typography>
                        </Grid>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setActiveSteps(1);
                            setActiveContent(1);
                            setHowitworksModal(true);
                        }}
                    >
                        <Grid container width="100%" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container width="100%" sx={{ display: 'flex' }}>
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 500, mr: 1 }}>
                                    Create lease offer
                                </Typography>
                                <Chip
                                    size="small"
                                    label={<AddIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13, mt: 0.75 }} />}
                                />
                            </Grid>
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 13 }}>
                                Learn how to create a lease offer.
                            </Typography>
                        </Grid>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setActiveSteps(2);
                            setActiveContent(2);
                            setHowitworksModal(true);
                        }}
                    >
                        <Grid container width="100%" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container width="100%" sx={{ display: 'flex' }}>
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 500, mr: 1 }}>
                                    Make a payment
                                </Typography>
                                <Chip
                                    size="small"
                                    label={
                                        <AccountBalanceWalletIcon
                                            sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13, mt: 0.75 }}
                                        />
                                    }
                                />
                            </Grid>
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 13 }}>
                                Quick guide on how to pay lease installment.
                            </Typography>
                        </Grid>
                    </MenuItem>
                </Menu>
            </Popover>
        </>
    );
};

export default LendAndBorrowMain;
