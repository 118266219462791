import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PredictionsMain from './PredictionsMain';
// ==============================|| TYPOGRAPHY ||============================== //

const Predictions = () => {
    useTheme();
    return (
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
            <PredictionsMain />
        </Grid>
    );
};

export default Predictions;
