import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {
    FormControl,
    Grid,
    IconButton,
    Button,
    InputLabel,
    OutlinedInput,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper
} from '@mui/material';
import { useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import alxlogo from '../../assets/alxlogo.svg';
import { grey } from '@mui/material/colors';
import usdtlogo from '../../assets/USDTLogo.svg';
import usdclogo from '../../assets/usdcsvg.svg';
import btclogo from '../../assets/bitcoinsvg.svg';
import ethlogo from '../../assets/ethlogo.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';

export default function FilterERC20Token({ isOpen, onClose }) {
    const theme = useTheme();

    const list = () => (
        <Box
            sx={{
                width: 'auto',
                bgcolor: 'transparent',
                borderRadius: 15,
                flexDirection: 'column',
                p: 2,
                display: 'flex',
                justifyContent: 'left'
            }}
        >
            <Grid
                container
                pb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={1}
                borderColor={theme.palette.text.invertedfourth}
            >
                <Grid item>
                    <Typography color={theme.palette.text.invertedprimary} pl={0.5} fontSize={18}>
                        Select a token
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 22 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel sx={{ color: theme.palette.text.invertedthird }}>Search name or paste address</InputLabel>
                    <OutlinedInput label="Search name or paste token address" />
                </FormControl>
            </Grid>
            <Grid container mt={2}>
                <Typography color={theme.palette.text.invertedprimary} pl={0.5} fontSize={15}>
                    Common tokens
                </Typography>
            </Grid>
            <TableContainer component={Paper} sx={{ bgcolor: 'transparent', boxShadow: 0 }}>
                <Table sx={{ minWidth: 650, bgcolor: 'transparent' }}>
                    <TableHead sx={{ bgcolor: 'transparent' }}>
                        <TableRow sx={{ justifyContent: 'center', display: 'flex' }}>
                            <TableCell width="100%" align="center">
                                <Button
                                    startIcon={<img src={usdtlogo} alt="usdtlogo" width={25} height={25} />}
                                    fullWidth
                                    onClick={onClose}
                                    sx={{
                                        bgcolor: theme.palette.card.main,
                                        color: theme.palette.text.invertedsecondary,
                                        borderRadius: 1,
                                        border: 1,
                                        fontWeight: 505,
                                        borderColor: theme.palette.mode === 'light' ? grey[300] : 'transparent',
                                        boxShadow: theme.palette.mode === 'light' ? '0px 0px 5px 0px rgba(0,0,0,0.1)' : 0
                                    }}
                                >
                                    USDT
                                </Button>
                            </TableCell>
                            <TableCell width="100%" align="center">
                                <Button
                                    startIcon={<img src={usdclogo} alt="usdtlogo" width={25} height={25} />}
                                    fullWidth
                                    onClick={onClose}
                                    sx={{
                                        bgcolor: theme.palette.card.main,
                                        color: theme.palette.text.invertedsecondary,
                                        borderRadius: 1,
                                        border: 1,
                                        fontWeight: 505,
                                        borderColor: theme.palette.mode === 'light' ? grey[300] : 'transparent',
                                        boxShadow: theme.palette.mode === 'light' ? '0px 0px 5px 0px rgba(0,0,0,0.1)' : 0
                                    }}
                                >
                                    USDC
                                </Button>
                            </TableCell>
                            <TableCell width="100%" align="center">
                                <Button
                                    fullWidth
                                    onClick={onClose}
                                    startIcon={<img src={btclogo} alt="usdtlogo" width={25} height={25} />}
                                    sx={{
                                        bgcolor: theme.palette.card.main,
                                        color: theme.palette.text.invertedsecondary,
                                        borderRadius: 1,
                                        border: 1,
                                        fontWeight: 505,
                                        borderColor: theme.palette.mode === 'light' ? grey[300] : 'transparent',
                                        boxShadow: theme.palette.mode === 'light' ? '0px 0px 5px 0px rgba(0,0,0,0.1)' : 0
                                    }}
                                >
                                    WBTC
                                </Button>
                            </TableCell>
                            <TableCell width="100%" align="center">
                                <Button
                                    fullWidth
                                    onClick={onClose}
                                    startIcon={<img src={ethlogo} alt="usdtlogo" width={25} height={25} />}
                                    sx={{
                                        bgcolor: theme.palette.card.main,
                                        color: theme.palette.text.invertedsecondary,
                                        borderRadius: 1,
                                        border: 1,
                                        fontWeight: 505,
                                        borderColor: theme.palette.mode === 'light' ? grey[300] : 'transparent',
                                        boxShadow: theme.palette.mode === 'light' ? '0px 0px 5px 0px rgba(0,0,0,0.1)' : 0
                                    }}
                                >
                                    WETH
                                </Button>
                            </TableCell>
                            <TableCell width="100%" align="center">
                                <Button
                                    fullWidth
                                    onClick={onClose}
                                    startIcon={<img src={alxlogo} alt="alxlogo" width={25} height={25} />}
                                    sx={{
                                        bgcolor: theme.palette.card.main,
                                        color: theme.palette.text.invertedsecondary,
                                        borderRadius: 1,
                                        border: 1,
                                        fontWeight: 505,
                                        borderColor: theme.palette.mode === 'light' ? grey[300] : 'transparent',
                                        boxShadow: theme.palette.mode === 'light' ? '0px 0px 5px 0px rgba(0,0,0,0.1)' : 0
                                    }}
                                >
                                    ALX
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </Box>
    );
    const list2 = () => (
        <Box
            sx={{
                width: 'auto',
                bgcolor: 'transparent',
                borderRadius: 1,
                flexDirection: 'column',
                p: 2,
                display: 'flex',
                justifyContent: 'left'
            }}
        >
            <Grid container>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Circle USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdtlogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDT
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Tether USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={btclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                WBTC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Wrapped Bitcoin - Ethereum
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={alxlogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                ALX
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                AlphaX
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={ethlogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                WETH
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Wrapped Ethereum
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Circle USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Circle USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Circle USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Circle USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
                <Button
                    fullWidth
                    onClick={onClose}
                    endIcon={<ArrowForwardIosIcon sx={{ color: theme.palette.text.invertedsecondary }} />}
                    startIcon={<img src={usdclogo} alt="tokenlogo" width={35} height={35} />}
                    sx={{ bgcolor: 'transparent', my: 1, color: theme.palette.text.invertedsecondary, textTransform: 'none' }}
                >
                    <Grid container display="flex" flexDirection="column">
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontWeight={505}>
                                USDC
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography textAlign="left" color={theme.palette.text.invertedprimary} fontSize={15}>
                                Circle USD Stablecoin
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
        </Box>
    );

    return (
        <div>
            <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
                <Box
                    sx={{
                        bgcolor: theme.palette.card.main,
                        position: 'relative',
                        left: 0,
                        right: 0
                    }}
                >
                    {list()}
                </Box>
                <Box
                    sx={{
                        bgcolor: theme.palette.card.main,
                        maxHeight: 250,
                        overflow: 'scroll'
                    }}
                >
                    {list2()}
                </Box>
            </Drawer>
        </div>
    );
}

// Define prop types for FilterERC20Token
FilterERC20Token.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
