// material-ui
import { useTheme } from '@emotion/react';
import { Typography, Modal, Box, Grid, IconButton, TextField } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import TokenList from 'views/utilities/TokenList';
import { useState } from 'react';
import PropTypes from 'prop-types';

// ==============================|| TransactionModal ||============================== //

const SupplyTokenListModal = ({ open, setOpen, onTokenSelect }) => {
    const theme = useTheme();
    const handleClose = () => setOpen(false);
    const [selectedToken, setSelectedToken] = useState(null);
    const handleChange = (event) => {
        // Access the value from the event and update the state
        setSelectedToken(event.target.value);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                    background: theme.palette.card.main,
                    border: 0,
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: 1,
                        pb: 2,
                        borderColor: theme.palette.text.invertedsecondary
                    }}
                >
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 20, fontWeight: 500 }}>
                        Select a token
                    </Typography>
                    <IconButton
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <ClearRoundedIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                    </IconButton>
                </Grid>
                <Grid container mt={2}>
                    <TextField
                        variant="standard"
                        onChange={handleChange}
                        value={selectedToken}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        sx={{
                            '& .MuiInputBase-input': { color: theme.palette.text.invertedthird, fontSize: 17, mx: 2, height: 50 },
                            bgcolor: theme.palette.card.light,
                            borderRadius: 2,
                            border: 0.5,
                            borderColor: theme.palette.text.invertedfourth,
                            height: 50
                        }}
                    />
                </Grid>
                <Grid container sx={{ maxHeight: '60vh', overflow: 'scroll', mt: 3 }}>
                    <TokenList tokenAddress={selectedToken} onTokenSelect={onTokenSelect} />
                </Grid>
            </Box>
        </Modal>
    );
};

// Define prop types for SupplyTokenListModal
SupplyTokenListModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onTokenSelect: PropTypes.func.isRequired
};

export default SupplyTokenListModal;
