import { useState } from 'react';
import { useTheme } from '@mui/system';
import LogoSection from '../LogoSection';
import { Box, Grid } from '@mui/material';
import MenuList from '../Sidebar/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import { useWeb3React } from '@web3-react/core';
import MenuDrawer from 'views/utilities/MenuDrawer';
import ConnButton from 'components/wallet/ConnButton';
import { grey } from '@mui/material/colors';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const theme = useTheme();
    const { account, active } = useWeb3React();
    const openMenuDrawer = () => {
        setIsMenuDrawerOpen(true);
    };

    const closeMenuDrawer = () => {
        setIsMenuDrawerOpen(false);
    };

    return (
        <>
            <Grid container justifyContent="center" display="flex">
                <Grid
                    lg={10}
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <LogoSection />
                    <Grid item display="flex" alignItems="center" justifyContent="left">
                        <Grid item sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
                            <MenuList />
                        </Grid>
                    </Grid>
                    <Grid item xs="auto" display="flex" justifyContent="right" alignItems="center">
                        <Grid item xs="auto" mr={1}>
                            <ConnButton />
                        </Grid>
                        <Grid item xs="auto" sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' } }}>
                            <Box
                                onClick={() => {
                                    openMenuDrawer();
                                }}
                                sx={{
                                    borderRadius: 2,
                                    background: '#232b52',
                                    width: 37,
                                    height: 37,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ':hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                <MenuIcon sx={{ color: grey[400], fontSize: 18 }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <MenuDrawer isOpen={isMenuDrawerOpen} onClose={closeMenuDrawer} />
        </>
    );
};

export default Header;
