import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Grid, Box, Typography, Card, LinearProgress } from '@mui/material';
import { brandColor } from 'themes/constants';
import { useState, useEffect } from 'react';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import DoDisturbRoundedIcon from '@mui/icons-material/DoDisturbRounded';

const FinishedPredictionCard = () => {
    const theme = useTheme();
    const [progress, setProgress] = useState(0);
    const TOTAL_DURATION = 300000; // 5 minutes in milliseconds

    useEffect(() => {
        const startTime = Date.now();
        const timer = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const newProgress = Math.min((elapsedTime / TOTAL_DURATION) * 100, 100);
            setProgress(newProgress);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <>
            <Grid
                container
                display="flex"
                justifyContent="center"
                mb={3}
                width="95.83%"
                sx={{ opacity: 0.6, ':hover': { opacity: 1 }, minHeight: 400 }}
            >
                <Card sx={{ borderRadius: 3, background: theme.palette.card.main, borderColor: brandColor, width: '100%' }}>
                    <Grid
                        container
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: grey[800], p: 1 }}
                    >
                        <Grid container width="auto" display="flex" alignItems="center">
                            <DoDisturbRoundedIcon sx={{ color: grey[500] }} />
                            <Typography sx={{ color: grey[500], fontSize: 14, fontWeight: 700, ml: 0.5 }}>Expired</Typography>
                        </Grid>
                        <Typography sx={{ color: grey[500], fontSize: 13, fontWeight: 500 }}>#1</Typography>
                    </Grid>
                    <Grid container display="flex" flexDirection="column" justifyContent="center" my={2}>
                        <Grid container display="flex" justifyContent="center" mt={2}>
                            <Grid
                                container
                                width="60%"
                                bgcolor={grey[800]}
                                display="flex"
                                flexDirection="column"
                                py={1}
                                sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                            >
                                <Typography sx={{ color: '#33d0aa', fontSize: 20, fontWeight: 700, textAlign: 'center' }}>UP</Typography>
                                <Typography
                                    sx={{ color: theme.palette.text.invertedthird, fontSize: 14, fontWeight: 700, textAlign: 'center' }}
                                >
                                    2.31x Payout
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" display="flex">
                            <Card
                                sx={{
                                    p: 2,
                                    width: '90%',
                                    border: 2,
                                    borderColor: '#ed4b9e',
                                    borderRadius: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: 'transparent'
                                }}
                            >
                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 12, fontWeight: 700 }}>
                                    CLOSED PRICE
                                </Typography>
                                <Grid container width="100%" mt={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography sx={{ color: '#ed4b9e', fontSize: { lg: 18, md: 18, sm: 16, xs: 14 }, fontWeight: 700 }}>
                                        $575.4000
                                    </Typography>
                                    <Box sx={{ bgcolor: '#ed4b9e', py: 0.5, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                                        <ArrowDownwardRoundedIcon
                                            sx={{
                                                color: grey[50],
                                                mx: { lg: 1, md: 1, sm: 1, xs: 0.5 },
                                                fontSize: { lg: 18, md: 18, sm: 16, xs: 14 }
                                            }}
                                        />
                                        <Typography
                                            sx={{ color: grey[50], fontSize: { lg: 14, md: 14, sm: 14, xs: 10 }, fontWeight: 600, mr: 1 }}
                                        >
                                            $-1.5096
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container width="100%" mt={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.invertedsecondary,
                                            fontSize: { lg: 14, md: 14, sm: 14, xs: 12 },
                                            fontWeight: 600
                                        }}
                                    >
                                        Locked Price:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.invertedsecondary,
                                            fontSize: { lg: 14, md: 14, sm: 14, xs: 12 },
                                            fontWeight: 600
                                        }}
                                    >
                                        $576.9096
                                    </Typography>
                                </Grid>
                                <Grid container width="100%" mt={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.invertedsecondary,
                                            fontSize: { lg: 16, md: 16, sm: 14, xs: 14 },
                                            fontWeight: 700
                                        }}
                                    >
                                        Prize Pool:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.invertedsecondary,
                                            fontSize: { lg: 16, md: 16, sm: 14, xs: 14 },
                                            fontWeight: 700
                                        }}
                                    >
                                        3.2705 BNB
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid container display="flex" justifyContent="center" mb={2}>
                            <Grid
                                container
                                width="60%"
                                bgcolor="#ed4b9e"
                                display="flex"
                                flexDirection="column"
                                sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                            >
                                <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700, textAlign: 'center', pt: 1 }}>
                                    1.76x Payout
                                </Typography>
                                <Typography sx={{ color: grey[50], fontSize: 20, fontWeight: 700, textAlign: 'center', pb: 1 }}>
                                    DOWN
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
};

export default FinishedPredictionCard;
