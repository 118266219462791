// theme.js
import { lightColorUI } from './constants';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Light Theme
export const lightTheme = createTheme({
    // Define your light theme properties here
    palette: {
        mode: 'light',
        primary: {
            main: '#0052ff'
        },
        secondary: {
            main: '#388E3C'
        },
        icons: {
            main: '#9399bc'
        },
        text: {
            transparent: 'transparent',
            primary: grey[100],
            secondary: grey[200],
            third: grey[300],
            invertedprimary: grey[800],
            invertedsecondary: grey[700],
            invertedthird: grey[700],
            invertedfourth: grey[600],
            brandUI: lightColorUI
        },
        background: {
            default: grey[200],
            paper: grey[200]
        },
        card: {
            main: grey[50],
            secondary: grey[300],
            third: grey[400],
            ultra: grey[100],
            light: grey[600]
        },
        borderColor: '#21293a'
    },
    typography: {
        fontFamily: 'Inter'
    }
});

// Dark Theme
export const darkTheme = createTheme({
    // Define your dark theme properties here
    palette: {
        mode: 'dark',
        primary: {
            main: '#0052ff'
        },
        secondary: {
            main: '#388E3C'
        },
        icons: {
            main: '#9399bc'
        },
        text: {
            transparent: 'transparent',
            primary: grey[800],
            secondary: grey[700],
            third: grey[600],
            invertedprimary: grey[100],
            invertedsecondary: grey[200],
            invertedthird: grey[300],
            invertedfourth: grey[400],
            brandUI: lightColorUI
        },
        background: {
            default: '#11172d',
            paper: '#11172d'
            // paper: '#010e3d'
        },
        card: {
            main: '#1e233d',
            alternative: '#5F5C66',
            secondary: grey[700],
            third: grey[600],
            ultra: grey[900],
            light: '#21293a'
        },
        borderColor: '#21293a'
    },
    typography: {
        fontFamily: 'Inter'
    }
});
