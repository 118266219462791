import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { brandColor } from 'themes/constants';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function FilterERC20Duration({ isOpen, onClose, onDurationChange }) {
    const theme = useTheme();
    const [lowerRange, setLowerRange] = useState(0);
    const [higherRange, setHigherRange] = useState(0);

    const handleDurationChange = (amount1, amount2) => {
        if (onDurationChange) {
            onDurationChange(amount1, amount2);
        }
    };

    const list = () => (
        <Box
            sx={{
                width: 'auto',
                bgcolor: 'transparent',
                borderRadius: 15,
                flexDirection: 'column',
                p: 2,
                display: 'flex',
                justifyContent: 'left'
            }}
        >
            <Grid
                container
                pb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={1}
                borderColor={theme.palette.text.invertedfourth}
            >
                <Grid item>
                    <Typography color={theme.palette.text.invertedprimary} pl={0.5} fontSize={18}>
                        Select a duration
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 22 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <FormControl>
                    <RadioGroup
                        defaultValue={JSON.stringify([1, 10])}
                        onChange={(event) => {
                            handleDurationChange(...JSON.parse(event.target.value));
                            onClose();
                        }}
                    >
                        <FormControlLabel
                            value={JSON.stringify([1, 10])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="1 Day - 10 Days"
                        />
                        <FormControlLabel
                            value={JSON.stringify([10, 100])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="10 Days - 100 Days"
                        />
                        <FormControlLabel
                            value={JSON.stringify([100, 365])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="100 Days - 365 Days"
                        />
                        <FormControlLabel
                            value={JSON.stringify([365, 730])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="365 Days - 730 Days"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid container mt={2} display="flex" justifyContent="space-evenly" alignItems="center">
                <FormControl variant="outlined" fullWidth size="small" sx={{ width: '40%' }}>
                    <InputLabel
                        sx={{
                            color: theme.palette.text.invertedthird,
                            '&.Mui-focused': {
                                color: theme.palette.text.invertedthird
                            }
                        }}
                    >
                        Min. days
                    </InputLabel>
                    <OutlinedInput
                        onChange={(event) => {
                            setLowerRange(parseFloat(event.target.value));
                        }}
                        label="Min. days"
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            input: { color: theme.palette.text.invertedsecondary }
                        }}
                    />
                </FormControl>
                <Typography sx={{ fontSize: 40, color: theme.palette.text.invertedfourth }}>-</Typography>
                <FormControl variant="outlined" fullWidth size="small" sx={{ width: '40%' }}>
                    <InputLabel
                        sx={{
                            color: theme.palette.text.invertedthird,
                            '&.Mui-focused': {
                                color: theme.palette.text.invertedthird
                            }
                        }}
                    >
                        Max. days
                    </InputLabel>
                    <OutlinedInput
                        onChange={(event) => {
                            setHigherRange(parseFloat(event.target.value));
                        }}
                        label="Max. days"
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            input: { color: theme.palette.text.invertedsecondary }
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid container mt={2} display="flex" justifyContent="space-evenly" alignItems="center">
                <Button
                    fullWidth
                    onClick={() => {
                        handleDurationChange(lowerRange, higherRange);
                        onClose();
                    }}
                    sx={{
                        textTransform: 'none',
                        borderRadius: 2,
                        bgcolor: brandColor
                    }}
                >
                    <Typography color={theme.palette.text.invertedprimary} fontSize={17}>
                        Set duration
                    </Typography>
                </Button>
            </Grid>
        </Box>
    );

    return (
        <div>
            <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
                <Box
                    sx={{
                        bgcolor: theme.palette.card.main
                    }}
                >
                    {list()}
                </Box>
            </Drawer>
        </div>
    );
}

FilterERC20Duration.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDurationChange: PropTypes.func.isRequired
};
