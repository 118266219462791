// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Ecosystem',
    type: 'group',
    url: '/',
    children: [
        {
            id: 'dashboard',
            title: `Dashboard`,
            type: 'item',
            url: '/dashboard',
            breadcrumbs: false
        },
        {
            id: 'lease',
            title: `Lease`,
            type: 'item',
            url: '/lease',
            breadcrumbs: false
        },
        {
            id: 'lendborrow',
            title: `Borrow`,
            disabled: true,
            type: 'item',
            url: '/lendborrow',
            breadcrumbs: false
        },
        {
            id: 'dex',
            title: `Swap`,
            type: 'item',
            url: '/dex',
            breadcrumbs: false
        },
        {
            id: 'auctions',
            title: `Auctions`,
            type: 'item',
            url: '/auctions',
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            url: '/analytics',
            breadcrumbs: false
        }
    ]
};

export default utilities;
