import { grey } from '@mui/material/colors';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { brandColor } from 'themes/constants';

// Generate data for each day from Day 0 to Day 100, increasing by 200,000 each day
const data = Array.from({ length: 101 }, (v, i) => ({
    name: `Day ${i}`,
    pv: 200000 * i
}));

const formatYAxisTick = (value) => {
    // Convert values to 'K' for thousands, 'M' for millions
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}K`;
    }
    return value;
};

const AuctionsGraph = () => {
    const theme = useTheme();

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const formattedValue = new Intl.NumberFormat('en-US').format(payload[0].value);
            const day = payload[0].payload.name;
            return (
                <Box sx={{ p: 1, background: theme.palette.mode === 'dark' ? '#28242b' : grey[50] }}>
                    <Typography variant="subtitle2">Total ALX minted from Day 1 to {day}</Typography>
                    <Typography variant="body2">{formattedValue} ALX</Typography>
                </Box>
            );
        }

        return null;
    };

    return (
        <>
            <ResponsiveContainer width="100%" height={280}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        bottom: 5
                    }}
                >
                    <defs>
                        <linearGradient id="colorPrice1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={brandColor} stopOpacity={1} />
                            <stop offset="95%" stopColor={brandColor} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} stroke={grey[500]} vertical={false} fill="transparent" />
                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        dataKey="name"
                        tickFormatter={(value) => (value === 'Day 0' || value === 'Day 100' ? value : '')}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        domain={[0, 200000 * 100]}
                        width={65}
                        interval={0}
                        tickFormatter={formatYAxisTick}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="pv"
                        stroke={brandColor}
                        fill="url(#colorPrice1)"
                        fillOpacity={1}
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 8 }}
                        isAnimationActive={false}
                        filter={(item) => item.name <= 'Day 50'}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default AuctionsGraph;
