import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Grid, Typography, Card } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const LaterPredictionCard = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container display="flex" justifyContent="center" width="95.83%" minHeight={400} alignItems="center">
                <Card sx={{ borderRadius: 3, background: theme.palette.card.main, width: '100%' }}>
                    <Grid
                        container
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, bgcolor: grey[800], py: 1 }}
                    >
                        <Grid container width="auto" display="flex" alignItems="center">
                            <PlayCircleIcon sx={{ color: grey[500] }} />
                            <Typography sx={{ color: grey[500], fontSize: 14, fontWeight: 700, ml: 0.5 }}>Later</Typography>
                        </Grid>
                        <Typography sx={{ color: grey[500], fontSize: 13, fontWeight: 500 }}>#1</Typography>
                    </Grid>
                    <Grid container display="flex" flexDirection="column" justifyContent="center" mb={2}>
                        <Grid container display="flex" justifyContent="center" mt={2}>
                            <Grid
                                container
                                width="60%"
                                bgcolor={grey[800]}
                                display="flex"
                                flexDirection="column"
                                py={2}
                                sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                            >
                                <Typography sx={{ color: grey[500], fontSize: 20, fontWeight: 700, textAlign: 'center' }}>UP</Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" display="flex">
                            <Card
                                sx={{
                                    p: 2,
                                    width: '80%',
                                    border: 2,
                                    borderRadius: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: 'transparent'
                                }}
                            >
                                <Typography
                                    sx={{ color: theme.palette.text.invertedsecondary, textAlign: 'center', fontSize: 16, fontWeight: 700 }}
                                >
                                    Entry starts
                                </Typography>
                                <Typography
                                    sx={{ color: theme.palette.text.invertedprimary, textAlign: 'center', fontSize: 22, fontWeight: 800 }}
                                >
                                    ~3:23
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid container display="flex" justifyContent="center" mb={2}>
                            <Grid
                                container
                                width="60%"
                                bgcolor={grey[800]}
                                display="flex"
                                flexDirection="column"
                                py={2}
                                sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                            >
                                <Typography sx={{ color: grey[500], fontSize: 20, fontWeight: 700, textAlign: 'center' }}>DOWN</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
};

export default LaterPredictionCard;
