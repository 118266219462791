import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { brandColor } from 'themes/constants';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useWeb3React } from '@web3-react/core';
import alxicon from '../../../assets/alxlogo.svg';
import { green, grey } from '@mui/material/colors';
import BrandButton from 'ui-component/BrandButton';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { injected } from '../../../components/wallet/connectors';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { ALXbalanceOf, balanceOfAnyToken, fetchEthBalance } from 'components/wallet/sharesABI';

const DashboardMyInfoCard = () => {
    const theme = useTheme();
    const { active, account, activate } = useWeb3React();
    const connect = async () => {
        try {
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    };
    const [ALXBalance, setALXBalance] = useState();
    const [ethBalance, setEthBalance] = useState();
    const [bnbPrice, setBnbPrice] = useState('0.00');
    const [popText, setPopText] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const loadETHBalance = async () => {
            const balance = await fetchEthBalance(account);
            setEthBalance(balance);
        };

        const fetchALXBalance = async () => {
            const balance = await balanceOfAnyToken('0xd04CaE65F0Dc05051c7E114800759fb4eE82626C', account);
            setALXBalance(balance);
        };

        const fetchBNBPrice = async () => {
            const url = 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd';
            try {
                const response = await fetch(url);
                const data = await response.json();
                setBnbPrice(data.binancecoin.usd);
            } catch (error) {
                console.error('Error fetching BNB price:', error);
            }
        };

        if (active && account) {
            loadETHBalance();
            fetchALXBalance();
        }
        fetchBNBPrice();
    }, [account, active]);

    return (
        <>
            <Grid
                item
                xs={11.7}
                zIndex={999}
                sx={{
                    p: 4,
                    border: 1,
                    boxShadow: 8,
                    borderRadius: 2,
                    alignItems: 'start',
                    background: theme.palette.card.main,
                    borderColor: theme.palette.borderColor
                }}
            >
                <Grid container display="flex" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 20, fontWeight: 405 }}>My info</Typography>
                    <IconButton>
                        <SettingsOutlinedIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 25 }} />
                    </IconButton>
                </Grid>
                {account && active ? (
                    <Grid container display="flex" flexDirection="row" width="100%" mt={3}>
                        <Grid
                            container
                            width="auto"
                            sx={{
                                background: theme.palette.card.alternative,
                                borderRadius: 3,
                                width: '2.75rem',
                                height: '2.75rem',
                                p: 1,
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                boxShadow: 0
                            }}
                        >
                            <AccountBalanceWalletIcon sx={{ color: grey[300] }} />
                        </Grid>
                        <Grid container display="flex" width="auto" ml={2} alignItems="center">
                            <Typography color={theme.palette.text.invertedsecondary} letterSpacing="0.5px" fontSize={15}>
                                Wallet balance
                            </Typography>
                            <Grid container>
                                <Typography color={theme.palette.text.invertedprimary} fontWeight={505} fontSize={15}>
                                    {(ethBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}
                                </Typography>
                                <Typography color={theme.palette.text.invertedfourth} pl={0.5} fontWeight={505} fontSize={15}>
                                    ETH
                                </Typography>
                                <Typography color={theme.palette.text.invertedfourth} fontWeight={505} pl={0.5} fontSize={15}>
                                    (${((bnbPrice * ethBalance) / 1e18).toLocaleString('en-US', { maximumFractionDigits: 2 })})
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            pt={4}
                            mt={3.5}
                            width="100%"
                            borderTop={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            borderColor={theme.palette.borderColor}
                        >
                            <Grid container width="100%">
                                <Typography color={theme.palette.text.invertedsecondary} letterSpacing="0.5px" fontSize={14}>
                                    ALX balance
                                </Typography>
                            </Grid>
                            <Grid container width="70%" sx={{ alignItems: 'center', display: 'flex' }}>
                                <Typography mr={0.5} color={theme.palette.text.invertedprimary} fontWeight={505} fontSize={15}>
                                    {account ? (ALXBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 2 }) : '0.00'}
                                </Typography>
                                <img src={alxicon} alt="icon" width={15} height={15} />
                                <Grid item width="100%">
                                    <Typography color={theme.palette.text.invertedfourth} fontWeight={505} fontSize={13}>
                                        $
                                        {account
                                            ? ((ALXBalance / 1e18) * 0.0004).toLocaleString('en-US', { maximumFractionDigits: 2 })
                                            : '0.00'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item width="30%" justifyContent="right" display="flex">
                                <Link to="/dex">
                                    <BrandButton onClick={() => {}} sx={{ width: '100%' }} text="Buy" />
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container display="flex" width="100%" alignItems="center" justifyContent="space-between" pt={4}>
                            <Grid container width="70%" alignItems="center">
                                <Typography color={theme.palette.text.invertedsecondary} letterSpacing="0.5px" fontSize={14}>
                                    HPD eligibility
                                </Typography>
                                <IconButton
                                    sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                    onClick={() => {
                                        setPopText('The display indicates if you are eligible for a HappyPayDay.');
                                        setModalOpen(true);
                                    }}
                                >
                                    <InfoIcon sx={{ color: grey[300], fontSize: 15 }} />
                                </IconButton>
                            </Grid>
                            <Grid container width="30%" justifyContent="right" alignItems="center">
                                <CheckBoxIcon sx={{ color: green[400], fontSize: 30 }} />
                            </Grid>
                        </Grid>
                        <Paper
                            width="100%"
                            display="flex"
                            justifyContent="left"
                            alignItems="center"
                            elevation={0}
                            style={{ padding: 15, borderRadius: 5, boxShadow: 0, marginTop: 30 }}
                        >
                            <Typography color="#48C2E5" fontWeight={550} fontSize={13}>
                                Auction contribution and claiming ALX from the auctions is required in order to be eligible for a HPD.
                            </Typography>
                        </Paper>
                    </Grid>
                ) : (
                    <Grid container sx={{ justifyContent: 'center', p: 2, mt: { lg: 5, md: 5, sm: 5, xs: 5 } }}>
                        <Typography sx={{ textAlign: 'center', fontWeight: 700, color: theme.palette.text.invertedprimary, fontSize: 19 }}>
                            Please, connect your wallet
                        </Typography>
                        <Grid container width="100%" display="flex" justifyContent="center" mt={1}>
                            <Typography sx={{ fontSize: 14, textAlign: 'center', color: theme.palette.text.invertedthird }}>
                                We couldn’t detect a wallet. Connect a wallet to view your information.
                            </Typography>
                        </Grid>
                        <Grid container width="100%" display="flex" justifyContent="center" mt={2}>
                            <Button
                                onClick={() => {
                                    connect();
                                }}
                                sx={{
                                    background: '#0052ff',
                                    px: 2,
                                    color: grey[50],
                                    textTransform: 'none'
                                }}
                            >
                                Connect
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <PopUpInfo open={modalOpen} setOpen={setModalOpen} text={popText} />
        </>
    );
};

export default DashboardMyInfoCard;
