import * as React from 'react';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import { useTheme } from '@mui/system';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import leaseamountbanner from '../../../assets/leaseamount.svg';
import selectoffersbanner from '../../../assets/selectoffers.svg';
import connectwalletbanner from '../../../assets/connectwallet.svg';
import { Typography, StepLabel, StepConnector, stepConnectorClasses, Grid } from '@mui/material';
import { useState } from 'react';
import { grey } from '@mui/material/colors';

const steps = [
    ['Connect wallet', 'Select lease', 'Lease amount', 'Approve & lease', 'Completed'],
    ['Connect wallet', 'Click `Create`', 'Payment terms', 'Approve & create', 'Completed'],
    ['Connect wallet', 'Select offer', 'Lease amount', 'Approve & lease', 'Completed']
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#f9774b'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#f9774b'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#f9774b'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#f9774b',
        zIndex: 1,
        fontSize: 18
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

export default function HorizontalLinearStepper({ setOpen, activeContent, activeSteps }) {
    const banners = [connectwalletbanner, selectoffersbanner, leaseamountbanner, leaseamountbanner, leaseamountbanner];
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();

    const handleNext = () => {
        if (activeStep === steps[activeSteps.length]) {
            setOpen(false);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderStepContent = (step) => {
        const content = [
            [
                '1. Connect wallet to AlphaDAO website so you can start leasing cryptocurrencies in an instant.',
                '2. Explore the available lease offers, filter as per your requirements and lease the most suitable offer for you.',
                '3. Once you have selected a desirable lease offer, you can lease only a portion of the offer or the full offer.',
                '4. After selecting the lease offer, user must approve the smart contract to deduct an upfront payment.',
                '5. When user has successfully approved the smart contract, `Lease` button will appear which and must be clicked to finalise the lease.'
            ],
            [
                '1. Connect wallet to AlphaDAO website so you can start leasing cryptocurrencies in an instant.',
                '2. Explore the available lease offers, filter as per your requirements and lease the most suitable offer for you.',
                '3. Once you have selected a desirable lease offer, you can lease only a portion of the offer or the full offer.',
                '4. After selecting the lease offer, user must approve the smart contract to deduct an upfront payment.',
                '5. When user has successfully approved the smart contract, `Lease` button will appear which and must be clicked to finalise the lease.'
            ],
            [
                '1. Connect wallet to AlphaDAO website so you can start leasing cryptocurrencies in an instant.',
                '2. Explore the available lease offers, filter as per your requirements and lease the most suitable offer for you.',
                '3. Once you have selected a desirable lease offer, you can lease only a portion of the offer or the full offer.',
                '4. After selecting the lease offer, user must approve the smart contract to deduct an upfront payment.',
                '5. When user has successfully approved the smart contract, `Lease` button will appear which and must be clicked to finalise the lease.'
            ]
        ];

        return (
            <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 16, fontWeight: 700 }}>
                {content[activeContent[step]]}
            </Typography>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps[activeSteps].map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            <Typography color={theme.palette.text.invertedsecondary} fontSize={13} fontWeight={600}>
                                {label}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Grid container justifyContent="center" display="flex" my={2}>
                <img
                    src={banners[activeStep]}
                    alt="banner"
                    width="100%"
                    style={{ borderRadius: 10, border: '2px solid', borderColor: grey[700] }}
                />
            </Grid>
            {renderStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: activeStep === 0 ? 'right' : 'space-between', pt: 2 }}>
                {activeStep > 0 && (
                    <Button
                        sx={{
                            color: theme.palette.text.invertedsecondary,
                            bgcolor: theme.palette.card.light,
                            fontWeight: 600,
                            textTransform: 'none',
                            borderRadius: 2
                        }}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                )}
                <Button
                    sx={{
                        color: theme.palette.text.invertedsecondary,
                        bgcolor: theme.palette.card.light,
                        fontWeight: 600,
                        textTransform: 'none',
                        borderRadius: 2
                    }}
                    onClick={handleNext}
                >
                    {activeStep === steps[activeSteps.length - 1] ? 'Close' : 'Next'}
                </Button>
            </Box>
        </Box>
    );
}
