import { Outlet } from 'react-router-dom';

// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AppBar, CssBaseline, Toolbar, Slide, Grid } from '@mui/material';

// project imports
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

// assets
import Footer from './Footer';
import { grey } from '@mui/material/colors';
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    ...theme.typography.mainContent
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    return (
        <>
            <CssBaseline />
            <AppBar
                position="absolute"
                enableColorOnDark
                sx={{
                    top: 0,
                    bottom: 'auto',
                    boxShadow: 0,
                    borderColor: grey[600],
                    background: 'transparent'
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>
            <Main theme={theme}>
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
                <Footer />
            </Main>
        </>
    );
};

export default MainLayout;
