import { useState, useEffect } from 'react';
import { List, Grid, ListItem, ListItemAvatar, Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import cakelogo from '../../assets/cakelogo.svg';
import busdlogo from '../../assets/busdtransparent.svg';
import wethlogo from '../../assets/ethlogo.png';
import btclogo from '../../assets/bitcoinsvg.svg';

function TokenList({ tokenAddress, onTokenSelect }) {
    const [tokens, setTokens] = useState([]);
    const [tokens2] = useState([
        {
            address: '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551',
            name: 'PancakeSwap Token',
            symbol: 'CAKE',
            logoURI: cakelogo
        },
        {
            address: '0xd389253265dd6b85C47c410EC5fF0c6A383CE949',
            name: 'Binance USD - Stable coin',
            symbol: 'BUSD',
            logoURI: busdlogo
        },
        {
            address: '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
            name: 'Ethereum Coin',
            symbol: 'WETH',
            logoURI: wethlogo
        },
        {
            address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
            name: 'Bitocin',
            symbol: 'BTC',
            logoURI: btclogo
        }
    ]);
    const theme = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            const url = 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/tokenlist.json';
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (tokenAddress) {
                    // Filter to find a specific token if tokenAddress is provided
                    const specificToken = data.tokens.filter((token) => token.address.toLowerCase() === tokenAddress.toLowerCase());
                    setTokens(specificToken);
                } else {
                    // Load all tokens if no tokenAddress is provided
                    setTokens(data.tokens);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, [tokenAddress]); // Dependency array includes tokenAddress to refetch when it changes

    if (tokens.length === 0) {
        return (
            <Grid container justifyContent="center" display="flex">
                <Typography sx={{ color: theme.palette.text.invertedthird }}>No result found.</Typography>
            </Grid>
        );
    }

    return (
        <List sx={{ width: '100%' }}>
            {tokens2.map((token) => (
                <ListItem
                    key={token.address}
                    onClick={() => onTokenSelect(token)}
                    sx={{ background: 'transparent', ':hover': { background: theme.palette.card.light }, borderRadius: 2 }}
                >
                    <ListItemAvatar>
                        <Avatar sx={{ borderRadius: '50%' }} src={token.logoURI} alt={token.name} />
                    </ListItemAvatar>
                    <Grid item>
                        <Grid container>
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500 }}>{token.symbol}</Typography>
                        </Grid>
                        <Typography sx={{ color: theme.palette.text.invertedfourth }}>{token.name}</Typography>
                    </Grid>
                </ListItem>
            ))}
        </List>
    );
}

// Define prop types for TokenList
TokenList.propTypes = {
    tokenAddress: PropTypes.string,
    onTokenSelect: PropTypes.func.isRequired
};

export default TokenList;
