import { SiGitbook } from 'react-icons/si';
import { grey } from '@mui/material/colors';
import { FaXTwitter } from 'react-icons/fa6';
import { useTheme } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import baselogo from '../../assets/base-wordmark.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Grid, IconButton, Typography } from '@mui/material';

const Footer = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container width="100%" justifyContent="center" display="flex" mb={2}>
                <Grid container display="flex" lg={10} ml={2} alignItems="center" borderBottom={1.5} borderColor="#121628" pb={2}>
                    <Button
                        target="_blank"
                        href="https://medium.com/@alphadaofinance"
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ml: { lg: 1, md: 0, sm: 0, xs: 0 },
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Articles
                    </Button>
                    <Button
                        target="_blank"
                        href="https://t.me/alphadao_token"
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Support
                    </Button>
                    <IconButton
                        target="_blank"
                        href="https://x.com/alphadaotoken"
                        sx={{ background: '#121728', width: 30, height: 30, ml: 4 }}
                    >
                        <FaXTwitter style={{ color: '#fff', fontSize: 18 }} />
                    </IconButton>
                    <IconButton
                        target="_blank"
                        href="https://alphadaofinance.gitbook.io/alphadao"
                        sx={{ background: '#121728', width: 30, height: 30, ml: 1 }}
                    >
                        <SiGitbook style={{ color: '#fff' }} />
                    </IconButton>
                    <IconButton href="https://basescan.org/" target="_blank" sx={{ background: '#121728', width: 30, height: 30, mx: 1 }}>
                        <TelegramIcon sx={{ color: '#fff', fontSize: 18 }} />
                    </IconButton>
                    <IconButton href="https://basescan.org/" target="_blank" sx={{ background: '#121728', width: 30, height: 30 }}>
                        <OpenInNewIcon sx={{ color: '#fff', fontSize: 18 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" width="auto" display="flex" mb={7}>
                <Grid container display="flex" lg={10} alignItems="center">
                    <Button
                        target="_blank"
                        href="https://alphadaofinance.gitbook.io/alphadao"
                        sx={{
                            ml: 2,
                            fontSize: 17,
                            color: '#fff',
                            textTransform: 'none',
                            background: 'transparent',
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Documentation
                    </Button>
                    <Button
                        target="_blank"
                        href="https://alphadaofinance.gitbook.io/alphadao"
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Security
                    </Button>
                    <Button
                        sx={{
                            background: 'transparent',
                            textTransform: 'none',
                            color: '#fff',
                            fontSize: 17,
                            ':hover': { background: 'transparent', textDecoration: 'underline' }
                        }}
                    >
                        Integrated Tokens
                    </Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" display="fle" mb={7}>
                <Grid container display="flex" justifyContent="space-between" lg={10} alignItems="center" mx={2}>
                    <Typography sx={{ color: grey[600], mt: 3 }}>2024 @ AlphaDAO Finance, v1.0.0beta+97pe4</Typography>
                    <Typography sx={{ color: grey[600], mt: 3 }}>
                        A community asset for <img src={baselogo} alt="logo" width={60} style={{ marginBottom: -2.5, marginLeft: 5 }} />
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Footer;
