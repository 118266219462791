import { grey } from '@mui/material/colors';
import rewards from '../../../assets/rewards.svg';
import security from '../../../assets/security.svg';
import bg from '../../../assets/BackgroundImage.png';
import baselogo from '../../../assets/base-wordmark.svg';
import basexalpha from '../../../assets/basexalphadao.png';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { Box, Button, Grid, Typography } from '@mui/material';
import customlending from '../../../assets/custom-lending.svg';
import landingtraders from '../../../assets/landing-traders.svg';
import planes from '../../../assets/landing-aero-planes-dark.svg';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const Introduction = () => {
    const InfoBox = ({ text }) => (
        <Box
            sx={{
                p: 2,
                mb: 1,
                border: 1,
                display: 'flex',
                borderRadius: 2,
                flexDirection: 'row',
                borderColor: grey[500],
                width: { lg: '32%', md: '32%', sm: '50%', xs: '100%' }
            }}
        >
            <Grid container width="60%">
                <Typography sx={{ color: '#fff', fontWeight: 600 }}>{text}</Typography>
            </Grid>
            <Grid container width="40%" justifyContent="right" display="flex" alignItems="center">
                <IoMdCheckmarkCircleOutline style={{ color: grey[400] }} />
            </Grid>
        </Box>
    );
    const BannersBox = ({ title, text, image }) => (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                borderRadius: 2,
                flexDirection: 'column',
                background: 'rgba(42, 84, 243, 0.05)'
            }}
        >
            <Typography sx={{ color: '#fff', fontWeight: 600, px: 4, pt: 3 }}>{title}</Typography>
            <Typography sx={{ color: grey[300], mt: 2, mb: 6, px: 4 }}>{text}</Typography>
            <Box
                component="img"
                src={image}
                alt="banner"
                sx={{
                    width: '100%',
                    objectFit: 'cover',
                    mt: 'auto' // Ensures the image is at the bottom
                }}
            />
        </Box>
    );
    const infoTexts = [
        '100% of Protocol Incentives and Fees Go To Stakers',
        'Permissionless, secure and transparent protocol',
        'Permissionless, secure and transparent protocol',
        '100% of Protocol Incentives and Fees Go To Stakers',
        'Permissionless, secure and transparent protocol',
        'Permissionless, secure and transparent protocol'
    ];
    const BannerBoxesTexts = [
        {
            title: 'Lending Pools',
            text: 'Easily set up lending pools with personalized collateral options and desirable APR rates to align with your goals.',
            image: landingtraders
        },
        {
            title: 'Custom Lending',
            text: 'For the first time, unlock the ability to lend any token on the BASE blockchain, with customizable collateral and borrower-defined fees.',
            image: customlending
        },
        {
            title: 'Rewards',
            text: 'fdsafdsafdsalfj dsljak jdsfl kj fldksj fladsj lfkdsa fdsjk fdjsla fjdlksa flkds jflaksd',
            image: rewards
        },
        {
            title: 'Security',
            text: 'and this is the final test dsiojfajlkds fmksadfmlkd smlkf mlkas df',
            image: security
        }
    ];
    const StatisticTexts = [
        { title: 'TOTAL VALUE LEND', text: '$ 1.80M' },
        { title: 'TOKENS LEND', text: '48' },
        { title: 'TOTAL FEES', text: '$ 42.8K' },
        { title: 'TOTAL HOLDERS', text: '1.93K' }
    ];

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    overflow: 'hidden'
                }}
            >
                <Grid container justifyContent="center" display="flex">
                    <Grid container lg={10} md={10} sm={11} sx={{ justifyContent: 'center', zIndex: 998, display: 'flex' }}>
                        <Grid container justifyContent="center" display="flex" mt={25}>
                            <Typography color={grey[500]} fontSize={15} fontWeight={500}>
                                WELCOME TO ALPHADAO
                            </Typography>
                        </Grid>
                        <Grid item justifyContent="center" display="flex" lg={4.5} md={6} xs={8} mt={2}>
                            <Typography
                                textAlign="center"
                                sx={{ fontSize: { lg: 50, md: 54, sm: 35, xs: 30 }, fontWeight: 550, color: grey[50], lineHeight: 1.1 }}
                            >
                                Your decentralized hub for lending on{' '}
                                <Box
                                    component="img"
                                    src={baselogo}
                                    alt="logo"
                                    sx={{
                                        width: { lg: 150, md: 120, sm: 100, xs: 80 },
                                        marginBottom: { lg: -1, md: -0.5, sm: -0.5, xs: -0.5 }
                                    }}
                                />
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="center" display="flex" my={3}>
                            <img src={planes} alt="planes" />
                        </Grid>
                        <Grid item lg={5.5} xs={11} md={6} justifyContent="center" display="flex" mb={5}>
                            <Typography textAlign="center" color={grey[500]} fontSize={17} fontWeight={500}>
                                AlphaDAO is a decentralized P2P platform for lending, borrowing, and leasing any token, free from
                                traditional liquidation risks. Lenders and borrowers earn rewards from platform fees, while the trustless
                                system ensures secure, direct transactions.
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="space-between" display="flex" mt={5} xs={11}>
                            {infoTexts.map((text, index) => (
                                <InfoBox key={index} text={text} />
                            ))}
                        </Grid>
                        <Grid container justifyContent="left" lg={10} xs={10} display="flex" my={15}>
                            <Grid container justifyContent="left" lg={4}>
                                <Typography color="#fff" fontSize={13} fontWeight={550} mb={2}>
                                    THE APPROACH
                                </Typography>
                                <Typography color="#fff" fontSize={33} fontWeight={550} sx={{ lineHeight: 1 }}>
                                    Designed to eliminate traditional liquidation risks upon borrowing cryptocurrencies.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            display="flex"
                            mt={5}
                            mb={10}
                            alignItems="stretch"
                            sx={{ justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' } }}
                        >
                            {BannerBoxesTexts.map((item, index) => (
                                <Grid item xs={11} mb={2} sm={5.5} md={5.5} lg={2.8} mx={1} key={index} sx={{ display: 'flex' }}>
                                    <BannersBox title={item.title} text={item.text} image={item.image} />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container justifyContent="space-around" display="flex" mt={4} mb={10}>
                            {StatisticTexts.map((item, index) => (
                                <Grid
                                    container
                                    key={index}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    sx={{ width: { lg: 'auto', md: 'auto', sm: '50%', xs: '100%' }, mb: 5 }}
                                >
                                    <Typography sx={{ fontWeight: 700, fontSize: 40, color: '#fff' }}>{item.text}</Typography>
                                    <Typography sx={{ fontWeight: 500, fontSize: 13, color: grey[300] }}>{item.title}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container justifyContent="center" display="flex" mt={10}>
                <Grid
                    container
                    display="flex"
                    lg={8}
                    md={8}
                    xs={10}
                    sm={10}
                    py={1}
                    borderTop={1}
                    borderBottom={1}
                    borderColor="#121628"
                    justifyContent="center"
                >
                    <Typography sx={{ color: grey[400], fontWeight: 500 }}>Explore metrics and data openly on</Typography>
                    <Typography sx={{ color: grey[400], fontWeight: 500, textDecoration: 'underline', ml: 0.75 }}>Analytics</Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" display="flex" my={15}>
                <Grid item lg={3} sm={6} xs={10.5} md={4} display="flex" alignItems="center" flexDirection="column">
                    <img src={basexalpha} alt="banner" width={130} />
                    <Typography textAlign="center" fontSize={34} mt={4} color="#fff" lineHeight={1.1}>
                        Interested in getting started with AlphaDAO?
                    </Typography>
                    <Button
                        sx={{
                            mt: 3,
                            px: 3,
                            border: 1,
                            fontSize: 18,
                            color: '#fff',
                            borderRadius: 2,
                            textTransform: 'none',
                            borderColor: '#1e2332',
                            background: 'transparent'
                        }}
                    >
                        Onboarding Guide
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default Introduction;
