import { Grid, Typography, IconButton, Collapse } from '@mui/material';
import { useTheme } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import BrandButton from 'ui-component/BrandButton';
import busdlogo from '../../../assets/busdtransparent.svg';
import cakelogo from '../../../assets/cakelogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import ethlogo from '../../../assets/ethlogo.png';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { brandColor } from 'themes/constants';
import { useWeb3React } from '@web3-react/core';
import CloseLeaseModal from 'ui-component/CloseLeaseModal';

const LeaseOpenOfferCard = ({
    handleOpen,
    onClose,
    tokenA,
    tokenB,
    addressA,
    addressB,
    originalSize,
    amountA,
    offset,
    installments,
    reservedPrice,
    tokenAddress,
    uniqueId
}) => {
    const theme = useTheme();
    const [checked, setChecked] = useState(false);
    const { active, account } = useWeb3React();
    const [allowance, setAllowance] = useState(0);
    const [closeLeaseModal, setCloseLeaseModal] = useState(false);
    const tokenImages = {
        '0xd389253265dd6b85C47c410EC5fF0c6A383CE949': busdlogo,
        '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551': cakelogo,
        '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8': btclogo,
        '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378': ethlogo
    };
    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    const imageUrl = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: checked ? 'transparent' : theme.palette.card.light,
                    p: 1,
                    mt: 1,
                    borderRadius: 2,
                    height: 50
                }}
            >
                <Grid item xs="auto" alignItems="center" display="flex">
                    <Brightness1Icon sx={{ color: brandColor, fontSize: 10, mt: 0.4 }} />
                    <Typography sx={{ color: theme.palette.text.invertedprimary, ml: 1, fontWeight: 500 }}>
                        {parseFloat(amountA / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}{' '}
                        {tokenA}
                    </Typography>
                </Grid>
                <Grid item xs="auto" justifyContent="right">
                    <IconButton
                        onClick={() => {
                            handleChange();
                        }}
                        size="small"
                        sx={{ mr: -0.5 }}
                    >
                        {checked ? (
                            <ExpandLessIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                        ) : (
                            <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                        )}
                    </IconButton>
                </Grid>
            </Grid>
            <Collapse in={checked}>
                <Grid item sx={{ px: 1, bgcolor: theme.palette.card.main, borderRadius: 2 }}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
                        <Grid container width="auto" alignItems="center">
                            <Typography color={theme.palette.text.invertedthird} borderBottom="1px dotted" fontSize={14}>
                                Original amount
                            </Typography>
                            <IconButton size="small" sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}>
                                <InfoOutlinedIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }} />
                            </IconButton>
                        </Grid>
                        <Typography color={theme.palette.text.invertedprimary} fontWeight={600} fontSize={14} textAlign="right">
                            {parseFloat(originalSize / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 7,
                                minimumFractionDigits: 2
                            })}{' '}
                            {tokenA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
                        <Grid container width="auto" alignItems="center">
                            <Typography color={theme.palette.text.invertedthird} borderBottom="1px dotted" fontSize={14}>
                                Available amount
                            </Typography>
                            <IconButton size="small" sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}>
                                <InfoOutlinedIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }} />
                            </IconButton>
                        </Grid>
                        <Typography color={theme.palette.text.invertedprimary} fontWeight={600} fontSize={14} textAlign="right">
                            {parseFloat(amountA / 1e18).toLocaleString('en-US', { maximumFractionDigits: 7, minimumFractionDigits: 2 })}{' '}
                            {tokenA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
                        <Typography color={theme.palette.text.invertedthird} borderBottom="1px dotted" fontSize={14}>
                            Payment asset
                        </Typography>
                        <Grid item display="flex" alignItems="center">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={500} fontSize={14} textAlign="right">
                                {tokenB}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
                        <Typography color={theme.palette.text.invertedthird} borderBottom="1px dotted" fontSize={14}>
                            {tokenA} price
                        </Typography>
                        <Grid container width="auto" alignItems="center">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={600} fontSize={14} textAlign="right">
                                {parseFloat(reservedPrice / 1e18).toLocaleString('en-US', {
                                    maximumFractionDigits: 5,
                                    minimumFractionDigits: 2
                                })}
                            </Typography>
                            <img src={tokenImages[addressB]} alt="logo" width={13} height={13} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
                        <Typography color={theme.palette.text.invertedthird} borderBottom="1px dotted" fontSize={14}>
                            {tokenB} received
                        </Typography>
                        <Grid container width="auto" alignItems="center">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={600} fontSize={14} textAlign="right">
                                {parseFloat((originalSize / 1e18 - amountA / 1e18) * (reservedPrice / 1e18)).toLocaleString('en-US', {
                                    maximumFractionDigits: 5
                                })}{' '}
                            </Typography>
                            <img src={tokenImages[addressB]} alt="logo" width={13} height={13} />
                        </Grid>
                    </Grid>
                    <Grid container width="100%" display="flex" justifyContent="space-around" mt={2}>
                        <BrandButton
                            text="Close"
                            onClick={() => {
                                setCloseLeaseModal(true);
                            }}
                            _width="100%"
                        />
                    </Grid>
                </Grid>
            </Collapse>
            <CloseLeaseModal
                open={closeLeaseModal}
                setOpen={setCloseLeaseModal}
                originalSize={originalSize}
                availableAmount={amountA}
                tokenA={tokenA}
                addressA={addressA}
                uniqueId={uniqueId}
            />
        </>
    );
};

export default LeaseOpenOfferCard;
