// material-ui
import Web3 from 'web3';
import { lighten, useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import BrandButton from 'ui-component/BrandButton';
import usdtlogo from '../../../assets/UsdtLogo.png';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import SuccessfullTransactionModal from 'ui-component/SuccessfulTransactionModal';
import { Button, Grid, InputAdornment, TextField, Typography, styled } from '@mui/material';
import { USDTBalanceOf, allowanceUSDT, approveUSDT, currentDay, donateUSDT, donatorBalances } from 'components/wallet/sharesABI';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        textAlign: 'right', // Align text to the right
        color: grey[200],
        fontSize: 20,
        width: '100%',
        boxShadow: 6,
        fontWeight: 500,
        borderRadius: 8
    },
    '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
            textAlign: 'right', // Ensure input text aligns right
            '&::placeholder': {
                fontSize: '16px', // Custom font size for placeholder
                color: 'rgba(0, 0, 0, 0.6)' // Custom color for placeholder
            }
        },
        '& .MuiInputLabel-outlined': {
            textAlign: 'right' // Align label to the right
        }
    }
});

const ContributeUSDTCard = ({ onSuccess }) => {
    const theme = useTheme();
    const [day, setDay] = useState(1);
    const web3 = new Web3(window.ethereum);
    const [amount, setAmount] = useState('');
    const { BN, toWei, fromWei } = web3.utils;
    const [txnHash, setTxnHash] = useState('');
    const { active, account } = useWeb3React();
    const [modalOpen, setModalOpen] = useState(false);
    const [amountBN, setAmountBN] = useState(new BN(0));
    const [allowanceBN, setAllowanceBN] = useState(new BN(0));
    const [myUSDTBalance, setMyUSDTBalance] = useState('0');
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [myDailyContribution, setMyDailyContribution] = useState(0);
    let buttonText = '';

    if (myDailyContribution > 0) {
        buttonText = `Already contributed ${(myDailyContribution / 1e18).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        })} USDT`;
    } else if (amount < 50) {
        buttonText = 'Enter more than 50 USDT';
    } else if (amount > 10000) {
        buttonText = 'Enter less than 10,000 USDT';
    } else {
        buttonText = 'Contribute USDT';
    }

    const fetchData = async () => {
        if (account) {
            const tempDay = await currentDay();
            setDay(tempDay);
            const tempUSDTBalance = await USDTBalanceOf(account);
            setMyUSDTBalance(toWei(tempUSDTBalance));
            const tempAllowance = await allowanceUSDT(account);
            setAllowanceBN(new BN(tempAllowance.toString()));
            const tempDonatorBalances = await donatorBalances(account, tempDay);
            setMyDailyContribution(tempDonatorBalances);
        }
    };

    const handleContributeUSDT = async () => {
        try {
            await donateUSDT(amountBN.toString(), (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
            fetchData();
            onSuccess();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    const handleApproveUSDT = async () => {
        try {
            await approveUSDT(amountBN.toString(), (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
            await fetchData();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    const handleAmountChange = (event) => {
        let value = event.target.value;

        // Remove any non-digit and non-decimal characters except the first dot
        value = value.replace(/[^\d.]/g, '');

        // Ensure only one decimal point is present
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        if (value > myUSDTBalance / 10 ** 18) {
            setAmount(myUSDTBalance / 10 ** 18);
            const tempBalance = fromWei(myUSDTBalance, 'ether');
            value = tempBalance;
        } else {
            setAmount(value);
        }

        if (value) {
            const amountInWei = toWei(value, 'ether');
            const tempAmountBN = new BN(amountInWei); // Create BN object from sanitized value
            setAmountBN(tempAmountBN); // Update amountBN state
        } else {
            setAmountBN(new BN(0)); // If input value is empty, set amountBN to 0
        }
    };

    useEffect(() => {
        if (account) {
            fetchData();
        }
    }, [account, active]);

    return (
        <>
            <Grid
                container
                sx={{
                    py: 4,
                    boxShadow: 6,
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: { lg: 8, md: 4, xs: 2, sm: 2 },
                    background: theme.palette.card.main,
                    width: { lg: '49.166%', md: '49.166%', sm: '97.5%', xs: '97.5%' }
                }}
            >
                <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 26, fontWeight: 600 }}>Contribute USDT</Typography>
                <Grid container display="flex" justifyContent="center" mt={2}>
                    <CustomTextField
                        onChange={handleAmountChange}
                        fullWidth
                        size="small"
                        value={amount}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img src={usdtlogo} alt="logo" width={20} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid container display="flex" justifyContent="right" pr={2}>
                    <Button
                        onClick={() => {
                            setAmount(fromWei(myUSDTBalance, 'ether'));
                            handleAmountChange({ target: { value: fromWei(myUSDTBalance, 'ether') } });
                        }}
                        sx={{ color: theme.palette.text.invertedprimary, bgcolor: 'transparent', textTransform: 'none' }}
                    >
                        Balance:{' '}
                        {parseFloat(fromWei(myUSDTBalance, 'ether')).toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}{' '}
                        USDT
                    </Button>
                </Grid>
                <Grid container display="flex" sx={{ background: lighten(theme.palette.card.main, 0.1), borderRadius: 2 }} my={2} p={3}>
                    <Typography sx={{ color: grey[50], fontWeight: 500 }}>
                        Each address can send between 50 USDT and 10,000 USDT per day, limited to one transaction daily.
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between">
                    {allowanceBN.gte(amountBN) ? (
                        <BrandButton
                            text={buttonText}
                            disabled={amount < 50 || amount > 10000 || myDailyContribution > 0}
                            onClick={handleContributeUSDT}
                            _width="100%"
                            sx={{ fontSize: 18, height: 50, fontWeight: 600 }}
                        />
                    ) : (
                        <BrandButton
                            text="Approve USDT"
                            onClick={handleApproveUSDT}
                            _width="100%"
                            sx={{ fontSize: 18, height: 50, fontWeight: 600 }}
                        />
                    )}
                </Grid>
            </Grid>
            <SuccessfullTransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default ContributeUSDTCard;
