import { Grid, Typography, Box } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTheme } from '@mui/system';
import { lightColorUI } from 'themes/constants';
import CircleIcon from '@mui/icons-material/Circle';
import { useState, useEffect } from 'react';
import LeaseCard from './LeaseCard';
import BrandButton from 'ui-component/BrandButton';
import { anyTokenSymbol } from 'components/wallet/sharesABI';
import busdlogo from '../../../assets/busdtransparent.svg';
import alxlogo from '../../../assets/alxlogo.svg';
import cakelogo from '../../../assets/cakelogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import ethlogo from '../../../assets/ethlogo.png';

const LeaseSimpleCard = ({
    tokenA,
    tokenB,
    originalSize,
    amountA,
    reservedPrice,
    offset,
    installments,
    lessor,
    onPayment,
    tokenAddress,
    id
}) => {
    const theme = useTheme();
    const [symbolA, setSymbolA] = useState('');
    const [symbolB, setSymbolB] = useState('');
    const [payer, setPayer] = useState('');
    const [openDetailedCard, setOpenDetailedCard] = useState(false);
    const tokenImages = {
        '0xd389253265dd6b85C47c410EC5fF0c6A383CE949': busdlogo,
        '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551': cakelogo,
        '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8': btclogo,
        '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378': ethlogo,
        default: alxlogo
    };

    // const imageUrl = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
    const handleFetchSymbol = async () => {
        if (tokenB) {
            const fetchedSymbolA = await anyTokenSymbol(tokenA);
            const fetchedSymbolB = await anyTokenSymbol(tokenB);
            const fetchedPayer = await lessor;
            setSymbolA(fetchedSymbolA || 'Symbol not found');
            setSymbolB(fetchedSymbolB || 'Symbol not found');
            setPayer(fetchedPayer || 'Lessor not found');
        }
    };
    const openDetailedOfferCard = () => {
        setOpenDetailedCard(true);
    };

    const closeDetailedOfferCard = () => {
        setOpenDetailedCard(false);
    };

    useEffect(() => {
        handleFetchSymbol();
    }, [tokenB]);

    return (
        <>
            <Grid
                container
                width="95.8%"
                sx={{
                    mt: 2,
                    mx: { lg: 1, md: 1, sm: 0, xs: 0 },
                    background: theme.palette.card.main,
                    borderRadius: 2,
                    boxShadow: 6,
                    border: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2
                }}
            >
                <Grid item xs={6} md={7.1} display="flex" alignItems="center">
                    <img src={tokenImages[tokenA]} alt="logo" width={40} height={40} style={{ borderRadius: '50%' }} />
                    <Typography fontWeight={505} color={theme.palette.text.invertedsecondary} fontSize={20} ml={1}>
                        ${symbolA.toUpperCase()}
                    </Typography>
                </Grid>
                <Grid item xs={5} md={4.8} justifyContent="right" display="flex" alignItems="center">
                    <a
                        href={`https://testnet.bscscan.com/token/${tokenAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography sx={{ textDecoration: 'underline' }} color={theme.palette.text.invertedthird} fontSize={15}>
                            Bscscan
                        </Typography>
                    </a>
                    <LaunchIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15, ml: 0.5 }} />
                </Grid>
                <Grid container width="100%" justifyContent="left">
                    <Box
                        sx={{
                            width: 'auto',
                            border: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            borderColor: lightColorUI,
                            borderRadius: 1,
                            px: 1,
                            mt: 1.5,
                            alignItems: 'center'
                        }}
                    >
                        <CircleIcon sx={{ color: lightColorUI, fontSize: 8 }} />
                        <Typography color={lightColorUI} fontSize={14} px={1}>
                            Lease
                        </Typography>
                    </Box>
                    <Typography color={theme.palette.text.invertedsecondary} fontSize={14} pl={1} mt={1.5}>
                        Duration: {offset * installments - offset} days
                    </Typography>
                </Grid>
                <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography color={theme.palette.text.invertedsecondary} mt={1.5}>
                        Amount:
                    </Typography>
                    <Typography color={theme.palette.text.invertedsecondary} textAlign="right" mt={1.5}>
                        {parseFloat(amountA / 1e18).toLocaleString('en-US', { maximumFractionDigits: 6, minimumFractionDigits: 2 })}{' '}
                        {symbolA.toUpperCase()}
                    </Typography>
                </Grid>
                <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography color={theme.palette.text.invertedsecondary} mt={1.5}>
                        Pay with:
                    </Typography>
                    <Typography color={theme.palette.text.invertedsecondary} textAlign="right" mt={1.5}>
                        ${symbolB.toUpperCase()}
                    </Typography>
                </Grid>
                <Grid container sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography color={theme.palette.text.invertedsecondary} mt={1.5}>
                        Price:
                    </Typography>
                    <Typography color={theme.palette.text.invertedsecondary} textAlign="right" mt={1.5}>
                        1 {symbolA.toUpperCase()} /{' '}
                        {parseFloat(reservedPrice / 1e18).toLocaleString('en-US', { maximumFractionDigits: 5, minimumFractionDigits: 2 })}{' '}
                        {symbolB.toUpperCase()}
                    </Typography>
                </Grid>
                <BrandButton onClick={openDetailedOfferCard} text="Lease" _width="100%" />
            </Grid>
            <LeaseCard
                onClose={closeDetailedOfferCard}
                handleOpen={openDetailedCard}
                amountA={amountA}
                tokenA={tokenA}
                originalSize={originalSize}
                tokenB={tokenB}
                reservedPrice={reservedPrice}
                tokenAddress={tokenAddress}
                offset={offset}
                lessor={lessor}
                installments={installments}
                id={id}
                onPayment={onPayment}
            />
        </>
    );
};

export default LeaseSimpleCard;
