// material-ui
import { useTheme } from '@emotion/react';
import { Typography, Modal, Box, Grid, IconButton, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { revokeOffer } from 'components/wallet/sharesABI';
import PopUpInfo from './PopUpInfoModal';
import busdlogo from '../assets/busdtransparent.svg';
import cakelogo from '../assets/cakelogo.svg';
import btclogo from '../assets/bitcoinsvg.svg';
import ethlogo from '../assets/ethlogo.png';
import Web3 from 'web3';
import TransactionModal from './SuccessfulTransactionModal';
import RejectedTransactionModal from './RejectedTransactionModal';
import SubmittedTransactionModal from './SubmittedTransactionModal';
import BrandButton from './BrandButton';

// ==============================|| TransactionModal ||============================== //

const CloseLeaseModal = ({ open, setOpen, originalSize, availableAmount, tokenA, addressA, uniqueId }) => {
    const theme = useTheme();
    const tokenImages = {
        '0xd389253265dd6b85C47c410EC5fF0c6A383CE949': busdlogo,
        '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551': cakelogo,
        '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8': btclogo,
        '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378': ethlogo
    };
    const [amountToClose, setAmountToClose] = useState(0);
    const [inputValue, setInputValue] = useState(0);
    const [setModalOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [successfullModal, setSuccessfullModal] = useState(false);
    const [popText] = useState('');
    const [txnHash, setTxnHash] = useState('');
    const [modalPopUp, setModalPopUp] = useState(false);
    const web3 = new Web3(window.ethereum);
    const { BN, toWei, fromWei } = web3.utils;
    const handleClose = () => setOpen(false);

    const handleAmountChange = (event) => {
        let value = event.target.value;

        // Remove any non-digit and non-decimal characters except the first dot
        value = value.replace(/[^\d.]/g, '');
        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18) {
                parts[1] = parts[1].slice(0, 18);
                value = parts.join('.');
            }
        }
        // Ensure only one decimal point is present
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        if (value > availableAmount / 10 ** 18) {
            setInputValue(availableAmount / 1e18);
            const tempBalance = fromWei(availableAmount, 'ether');
            value = tempBalance;
        } else {
            setInputValue(value);
        }

        if (value) {
            const amountInWei = toWei(value, 'ether');
            const tempAmountBN = new BN(amountInWei); // Create BN object from sanitized value
            setAmountToClose(tempAmountBN); // Update amountBN state
        } else {
            setAmountToClose(new BN(0)); // If input value is empty, set amountBN to 0
        }
    };

    const handleRevokeOffer = async () => {
        try {
            await revokeOffer(uniqueId, amountToClose, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true); // Open the success modal
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                        background: theme.palette.card.main,
                        borderRadius: 2,
                        boxShadow: 24
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            bgcolor: theme.palette.card.light,
                            p: 2,
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5
                        }}
                    >
                        <Typography sx={{ textAlign: 'center', fontSize: 20, fontWeight: 600, color: theme.palette.text.invertedprimary }}>
                            Close offer
                        </Typography>
                        <IconButton
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon sx={{ color: theme.palette.text.invertedprimary }} />
                        </IconButton>
                    </Grid>
                    <Grid container sx={{ display: 'flex', px: 2.5, mt: 2, justifyContent: 'space-between' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: 16, color: theme.palette.text.invertedprimary }}>
                            Original size
                        </Typography>
                        <Typography sx={{ textAlign: 'center', fontSize: 16, fontWeight: 600, color: theme.palette.text.invertedprimary }}>
                            {parseFloat(originalSize / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 6,
                                minimumFractionDigits: 2
                            })}
                            {` `} {tokenA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', px: 2.5, mt: 2, justifyContent: 'space-between' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: 16, color: theme.palette.text.invertedprimary }}>
                            Available amount
                        </Typography>
                        <Typography sx={{ textAlign: 'center', fontSize: 16, fontWeight: 600, color: theme.palette.text.invertedprimary }}>
                            {parseFloat(availableAmount / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 6,
                                minimumFractionDigits: 2
                            })}
                            {` `} {tokenA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', px: 2.5, mt: 2, justifyContent: 'center' }}>
                        <TextField
                            variant="standard"
                            value={inputValue}
                            onChange={(event) => {
                                handleAmountChange(event);
                            }}
                            fullWidth
                            size="small"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img
                                            src={tokenImages[addressA]}
                                            alt="Icon"
                                            style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 10 }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.invertedthird,
                                    fontSize: 18,
                                    textAlign: 'right',
                                    height: 30
                                },
                                bgcolor: theme.palette.background.default,
                                borderRadius: 2
                            }}
                        />
                    </Grid>
                    <Grid container display="flex" justifyContent="center">
                        <Grid item width="90%" sx={{ bgcolor: theme.palette.card.light, p: 1, mt: 2, borderRadius: 2 }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: theme.palette.text.invertedprimary }}>
                                {(amountToClose / 1e18).toLocaleString('en-us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}{' '}
                                {tokenA} will be transfered to your wallet.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', my: 2, px: 2 }}>
                        <BrandButton
                            text="Revoke"
                            sx={{ mx: 0.5 }}
                            _width="100%"
                            onClick={() => {
                                handleRevokeOffer();
                            }}
                        />
                    </Grid>
                </Box>
            </Modal>
            <PopUpInfo open={modalPopUp} setOpen={setModalPopUp} text={popText} />
            <TransactionModal open={successfullModal} setOpen={setSuccessfullModal} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

// Define prop types for TransactionModal
CloseLeaseModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    tokenA: PropTypes.string.isRequired,
    uniqueId: PropTypes.string.isRequired,
    addressA: PropTypes.string.isRequired,
    originalSize: PropTypes.number.isRequired,
    availableAmount: PropTypes.number.isRequired
};

export default CloseLeaseModal;
