// material-ui
import { useTheme } from '@emotion/react';
import { Typography, Modal, Box, Button, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { brandColor } from 'themes/constants';
import { useWeb3React } from '@web3-react/core';
import bsclogo from '../assets/bsclogo.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useState } from 'react';
import { balanceOfAnyToken, fetchEthBalance } from 'components/wallet/sharesABI';
import alxlogo from '../assets/alxlogo.svg';

// ==============================|| TransactionModal ||============================== //

const WalletModal = ({ open, setOpen }) => {
    const theme = useTheme();
    const { account, active, deactivate } = useWeb3React();
    const [ethBalance, setEthBalance] = useState('0.00');
    const [alxBalance, setAlxBalance] = useState(0);
    const handleClose = () => setOpen(false);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(account)
            .then(() => {
                console.log('Text successfully copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    useEffect(() => {
        const loadETHBalance = async () => {
            const balance = await fetchEthBalance(account);
            setEthBalance(balance);
        };

        const fetchALXBalance = async () => {
            const balance = await balanceOfAnyToken('0x5ca2822629D66f443db806E2F480b0310e525bBB', account);
            setAlxBalance(balance);
        };

        if (active && account) {
            loadETHBalance();
            fetchALXBalance();
        }
    }, [account, active]);
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                    background: theme.palette.card.main,
                    borderRadius: 2,
                    boxShadow: 24
                }}
            >
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        bgcolor: theme.palette.card.light,
                        p: 2,
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5
                    }}
                >
                    <Typography sx={{ textAlign: 'center', fontSize: 24, fontWeight: 600, color: theme.palette.text.invertedprimary }}>
                        My wallet
                    </Typography>
                    <IconButton
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon sx={{ color: theme.palette.text.invertedprimary }} />
                    </IconButton>
                </Grid>
                <Grid container sx={{ display: 'flex', p: 2, mt: 2 }}>
                    <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600, color: theme.palette.text.invertedprimary }}>
                        WALLET ADDRESS
                    </Typography>
                </Grid>
                <Grid container sx={{ dispaly: 'flex', justifyContent: 'center' }}>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: 2,
                            width: '90%',
                            borderRadius: 2,
                            bgcolor: theme.palette.background.default,
                            py: 2,
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}
                    >
                        <Grid item lg={10.9} xs={10.9}>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: 18,
                                    fontWeight: 600,
                                    color: theme.palette.text.invertedprimary,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {account}
                            </Typography>
                        </Grid>
                        <Grid item lg={1} xs={1} display="flex" justifyContent="center">
                            <IconButton
                                onClick={() => {
                                    handleCopy();
                                }}
                            >
                                <ContentCopyIcon sx={{ color: brandColor }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2.5 }}>
                    <Grid
                        item
                        display="flex"
                        alignItems="center"
                        ml={2.5}
                        sx={{ bgcolor: theme.palette.card.light, p: 0.5, borderRadius: 2 }}
                    >
                        <img
                            src={bsclogo}
                            alt="logo"
                            width={28}
                            height={28}
                            style={{ backgroundColor: theme.palette.background.default, borderRadius: '50%', padding: 4 }}
                        />
                        <Typography
                            sx={{
                                textAlign: 'center',
                                mx: 1,
                                fontSize: 16,
                                fontWeight: 600,
                                color: theme.palette.text.invertedprimary,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            BSC Testnet
                        </Typography>
                    </Grid>
                    <Grid item mr={2.5}>
                        <a
                            href={`https://testnet.bscscan.com/address/${account}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                sx={{ bgcolor: 'transparent', textTransform: 'none', color: brandColor }}
                                endIcon={<OpenInNewIcon sx={{ color: brandColor }} />}
                            >
                                BscScan
                            </Button>
                        </a>
                    </Grid>
                </Grid>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2.5, px: 2.5 }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: theme.palette.text.invertedfourth }}>tBNB balance</Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.text.invertedprimary }}>
                        {(ethBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}
                    </Typography>
                </Grid>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2.5, px: 2.5 }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: theme.palette.text.invertedfourth }}>ALX balance</Typography>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.text.invertedprimary,
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        {(alxBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        <img src={alxlogo} alt="logo" width={18} height={18} style={{ marginLeft: 5 }} />
                    </Typography>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4, px: 2 }}>
                    <Button
                        fullWidth
                        onClick={() => {
                            handleClose();
                            deactivate();
                        }}
                        sx={{
                            background: 'linear-gradient(to right, #f9774b, #CD3201)',
                            textTransform: 'none',
                            borderRadius: 2,
                            border: 1,
                            borderColor: brandColor,
                            color: grey[50],
                            fontWeight: 700,
                            fontSize: 16,
                            py: 1,
                            ':hover': {
                                background: '#EB4308',
                                borderColor: '#EB4308',
                                boxShadow: 5
                            }
                        }}
                    >
                        Disconnect
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

// Define prop types for TransactionModal
WalletModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default WalletModal;
