import { Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/system';
import { blue } from '@mui/material/colors';

const TopLayout = ({ title, text }) => {
    const theme = useTheme();

    return (
        <Grid
            container
            pb={8}
            pt={15}
            display="flex"
            borderBottom={1}
            borderColor={theme.palette.borderColor}
            bgcolor={theme.palette.mode === 'dark' ? 'transparent' : 'transparent'}
            sx={{ justifyContent: { lg: 'space-evenly', md: 'space-evenly', sm: 'center', xs: 'center' } }}
        >
            <Grid item lg={4} md={7} sm={9} xs={11.5}>
                <Grid container display="flex" alignItems="center" my={2}>
                    <Typography
                        color={theme.palette.text.invertedprimary}
                        sx={{ fontSize: { lg: 45, md: 45, sm: 35, xs: 30 }, letterSpacing: '-1px', fontWeight: 800, lineHeight: 1.2 }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid container display="flex" alignItems="center">
                    <Typography
                        color={theme.palette.text.invertedfourth}
                        sx={{ fontSize: { lg: 18, md: 18, sm: 16, xs: 16 }, fontWeight: 600 }}
                    >
                        {text}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                lg={3.3}
                md={4}
                sm={8}
                flexDirection="column"
                sx={{ justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }, mt: 4 }}
            >
                <Typography
                    sx={{
                        color: theme.palette.text.invertedprimary,
                        fontSize: 14,
                        fontWeight: 500,
                        textAlign: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                    }}
                >
                    Looking for a partnership?
                </Typography>
                <Typography
                    sx={{
                        color: blue[500],
                        fontSize: 14,
                        fontWeight: 500,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                    }}
                >
                    <Link
                        href="https://www.t.me/alphadao_token"
                        color={blue[500]}
                        underline="none"
                        fontWeight={500}
                        target="_blank"
                        rel="noopener"
                    >
                        Apply here
                    </Link>
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.text.invertedprimary,
                        fontSize: 14,
                        mt: 2,
                        fontWeight: 500,
                        textAlign: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                    }}
                >
                    Need help?
                </Typography>
                <Typography
                    sx={{
                        color: blue[500],
                        fontSize: 14,
                        fontWeight: 500,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                    }}
                >
                    <Link
                        href="https://www.t.me/alphadao_token"
                        color={blue[500]}
                        underline="none"
                        fontWeight={500}
                        target="_blank"
                        rel="noopener"
                    >
                        Join our Telegram
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

// Define prop types for TopLayout
TopLayout.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string
};

export default TopLayout;
