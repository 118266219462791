import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Grid, Box, Typography, Button, Card, LinearProgress } from '@mui/material';
import { brandColor } from 'themes/constants';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useState, useEffect } from 'react';

const UpcomingPredictionCard = () => {
    const theme = useTheme();
    const [progress, setProgress] = useState(0);
    const TOTAL_DURATION = 300000; // 5 minutes in milliseconds

    useEffect(() => {
        const startTime = Date.now();
        const timer = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const newProgress = Math.min((elapsedTime / TOTAL_DURATION) * 100, 100);
            setProgress(newProgress);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <>
            <Grid container display="flex" justifyContent="center" width="95.83%" minHeight={400}>
                <Card sx={{ borderRadius: 3, border: 1, background: theme.palette.card.main, borderColor: brandColor, width: '100%' }}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, mt: 1 }}>
                        <Grid container width="auto" display="flex" alignItems="center">
                            <PlayCircleIcon sx={{ color: brandColor }} />
                            <Typography sx={{ color: brandColor, fontSize: 14, fontWeight: 700, ml: 0.5 }}>Next</Typography>
                        </Grid>
                        <Typography sx={{ color: brandColor, fontSize: 13, fontWeight: 500 }}>#1</Typography>
                    </Grid>
                    <Box sx={{ width: '100%', my: 1 }}>
                        <LinearProgress
                            color="info"
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: 10,
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: brandColor // Bar color
                                }
                            }}
                        />
                    </Box>
                    <Grid container display="flex" flexDirection="column" justifyContent="center" mb={2}>
                        <Grid container display="flex" justifyContent="center" mt={2}>
                            <Grid
                                container
                                width="60%"
                                bgcolor={grey[800]}
                                display="flex"
                                flexDirection="column"
                                py={1}
                                sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                            >
                                <Typography sx={{ color: '#33d0aa', fontSize: 20, fontWeight: 700, textAlign: 'center' }}>UP</Typography>
                                <Typography
                                    sx={{ color: theme.palette.text.invertedthird, fontSize: 14, fontWeight: 700, textAlign: 'center' }}
                                >
                                    2.31x Payout
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" display="flex">
                            <Card
                                sx={{
                                    p: 2,
                                    width: '80%',
                                    border: 2,
                                    borderRadius: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: 'transparent'
                                }}
                            >
                                <Grid container width="100%" display="flex" justifyContent="space-between">
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.invertedprimary,
                                            fontWeight: 700,
                                            fontSize: { lg: 14, md: 14, sm: 13, xs: 12 }
                                        }}
                                    >
                                        Prize Pool:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.invertedprimary,
                                            fontWeight: 700,
                                            fontSize: { lg: 14, md: 14, sm: 13, xs: 12 }
                                        }}
                                    >
                                        0.092 BNB
                                    </Typography>
                                </Grid>
                                <Grid container width="100%" mt={1}>
                                    <Button
                                        fullWidth
                                        sx={{
                                            bgcolor: '#33d0aa',
                                            color: grey[50],
                                            fontWeight: 700,
                                            py: 1,
                                            textTransform: 'none',
                                            borderRadius: 3
                                        }}
                                    >
                                        Enter UP
                                    </Button>
                                    <Button
                                        fullWidth
                                        sx={{
                                            mt: 1,
                                            bgcolor: '#ed4b9e',
                                            color: grey[50],
                                            py: 1,
                                            fontWeight: 700,
                                            textTransform: 'none',
                                            borderRadius: 3
                                        }}
                                    >
                                        Enter DOWN
                                    </Button>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid container display="flex" justifyContent="center" mb={2}>
                            <Grid
                                container
                                width="60%"
                                bgcolor={grey[800]}
                                display="flex"
                                flexDirection="column"
                                py={1}
                                sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                            >
                                <Typography
                                    sx={{ color: theme.palette.text.invertedthird, fontSize: 14, fontWeight: 700, textAlign: 'center' }}
                                >
                                    1.83x Payout
                                </Typography>
                                <Typography sx={{ color: '#ed4b9e', fontSize: 20, fontWeight: 700, textAlign: 'center' }}>DOWN</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
};

export default UpcomingPredictionCard;
