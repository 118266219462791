import { useEffect } from 'react';
import { Grid } from '@mui/material';
import TopLayout from '../TopLayout';
import LeaseMyCard from '../Lease/LeaseMyCard';
import { useWeb3React } from '@web3-react/core';
import DashboardMyInfoCard from './DashboardMyInfoCard';

const DashboardMain = () => {
    const { active, account } = useWeb3React();
    useEffect(() => {
        document.title = 'Dashboard | AlphaDAO';
    });
    return (
        <>
            <TopLayout
                title="Dashboard"
                text="Your central hub for essential information. View your balances, check HPD eligibility, and get an overview of your activities on the lease, lend, and borrow platforms."
            />
            <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 10 }}>
                {active && account ? (
                    <Grid item xs={11.7} lg={4} md={5.5} sm={9} zIndex={999}>
                        <Grid container display="flex" justifyContent="center" minHeight={462}>
                            <LeaseMyCard />
                        </Grid>
                    </Grid>
                ) : null}
                <Grid
                    item
                    lg={4}
                    md={6}
                    sm={9.24}
                    display="flex"
                    height="100%"
                    justifyContent="center"
                    sx={{ mt: { lg: 0, md: 0, sm: 2, xs: 2 } }}
                >
                    <DashboardMyInfoCard />
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardMain;
