import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import { Grid, Button, Box, Typography } from '@mui/material';
import { injected } from '../../components/wallet/connectors';

const ConnectWalletCard = ({ text }) => {
    const { activate } = useWeb3React();
    const theme = useTheme();
    const connect = async () => {
        try {
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    };

    return (
        <>
            <Grid
                item
                xs={11.7}
                lg={9.7}
                md={9.7}
                border={1}
                boxShadow={6}
                display="flex"
                borderRadius={2}
                flexDirection="row"
                justifyContent="center"
                borderColor={theme.palette.borderColor}
            >
                <Box sx={{ background: theme.palette.card.main, width: '100%', justifyContent: 'center', px: 3, py: 5, borderRadius: 2 }}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 700, color: theme.palette.text.invertedprimary, fontSize: 19 }}>
                        Please, connect your wallet
                    </Typography>
                    <Grid container width="100%" display="flex" justifyContent="center" mt={1}>
                        <Typography sx={{ fontSize: 14, textAlign: 'center', color: theme.palette.text.invertedthird }}>
                            We couldn’t detect a wallet. Connect a wallet to {text}.
                        </Typography>
                    </Grid>
                    <Grid container width="100%" display="flex" justifyContent="center" mt={2}>
                        <Button
                            onClick={() => {
                                connect();
                            }}
                            sx={{
                                px: 2,
                                color: grey[50],
                                background: '#0052FF',
                                textTransform: 'none'
                            }}
                        >
                            Connect wallet
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};

export default ConnectWalletCard;
