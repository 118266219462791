// material-ui
import { Grid, Typography } from '@mui/material';
import { lighten, useTheme } from '@mui/system';
import alxlogo from '../../../assets/alxlogo.svg';
import { grey } from '@mui/material/colors';
import BrandButton from 'ui-component/BrandButton';
import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { claimALXFromDonations, myClaimableALXFromDonations } from 'components/wallet/sharesABI';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import SuccessfullTransactionModal from 'ui-component/SuccessfulTransactionModal';

const ClaimableALXCard = ({ onSuccess }) => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [claimableALX, setClaimableALX] = useState(0);
    const [txnHash, setTxnHash] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);

    const fetchClaimableALX = async () => {
        if (account) {
            const balance = await myClaimableALXFromDonations(account);
            setClaimableALX(balance);
        }
    };

    const handleClaimALXFromDonations = async () => {
        try {
            await claimALXFromDonations((hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
            await fetchClaimableALX();
            onSuccess();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    useEffect(() => {
        fetchClaimableALX();
    }, [account, active, claimableALX]);

    return (
        <>
            <Grid
                container
                sx={{
                    py: 4,
                    mt: 2,
                    boxShadow: 6,
                    display: 'flex',
                    borderRadius: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: { lg: 8, md: 4, xs: 2, sm: 2 },
                    background: theme.palette.card.main,
                    width: { lg: '49.166%', md: '49.166%', sm: '97.5%', xs: '97.5%' }
                }}
            >
                <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 26, fontWeight: 600 }}>Claimable ALX</Typography>
                <Grid container display="flex" justifyContent="center" mt={2} alignItems="center">
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedsecondary,
                            fontSize: 22,
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {claimableALX.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Typography>
                    <img src={alxlogo} alt="logo" width={23} height={23} style={{ marginLeft: 5 }} />
                </Grid>
                <Grid container display="flex" sx={{ background: lighten(theme.palette.card.main, 0.1), borderRadius: 2 }} my={2} p={3}>
                    <Typography sx={{ color: grey[100], fontWeight: 500 }}>
                        ALX tokens must be claimed at least once in order to be eligible for a HappyPayDay.
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between">
                    <BrandButton
                        disabled={claimableALX === 0}
                        text={claimableALX === 0 ? 'Nothing to claim' : 'Claim ALX'}
                        onClick={() => {
                            handleClaimALXFromDonations();
                        }}
                        _width="100%"
                        sx={{ fontSize: 16, height: 50, fontWeight: 600 }}
                    />
                </Grid>
            </Grid>
            <SuccessfullTransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default ClaimableALXCard;
