import { Box, Modal, Grid, Typography, Avatar, Chip, IconButton } from '@mui/material';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { useState, useEffect } from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import { useWeb3React } from '@web3-react/core';
import { createLeaseOffer } from 'components/wallet/sharesABI';
import BrandButton from 'ui-component/BrandButton';
import GreyButton from 'ui-component/GreyButton';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import TransactionModal from 'ui-component/SuccessfulTransactionModal';
import cakelogo from '../../../assets/cakelogo.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CircleIcon from '@mui/icons-material/Circle';
import btclogo from '../../../assets/bitcoinsvg.svg';

const PreviewCreateOffer = ({ handleOpen, onClose, onOfferCreated, tokenA, tokenB, amountA, amountB, paymentsQunatity, offset }) => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [txnHash, setTxnHash] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInfoOpen, setModalInfoOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [popText, setPopText] = useState('');
    const [buttonText, setButtonText] = useState('Create offer');
    const [selectedSupplyToken, setSelectedSupplyToken] = useState({
        symbol: 'CAKE',
        address: '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551',
        logoURI: cakelogo
    });
    const [selectedCollateralToken, setSelectedCollateralToken] = useState({
        symbol: 'WBTC',
        address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
        logoURI: btclogo
    });

    const handleCreateOffer = async () => {
        try {
            await createLeaseOffer(tokenA, tokenB, amountA, amountB, paymentsQunatity, offset, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true); // Open the success modal
            onClose();
            onOfferCreated();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    useEffect(() => {}, [account, active, selectedSupplyToken.address]);

    return (
        <>
            <Modal open={handleOpen} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: '60%', md: '40%', lg: '30%' },
                        background: theme.palette.card.main,
                        border: 0,
                        borderRadius: 2,
                        boxShadow: 24,
                        pb: 4,
                        pt: 2,
                        px: 2
                    }}
                >
                    <Grid container sx={{ width: '100%', p: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography color={theme.palette.text.invertedprimary} fontSize={24} fontWeight={600}>
                            Preview Offer
                        </Typography>
                        <IconButton size="small" onClick={onClose}>
                            <CloseRoundedIcon sx={{ color: theme.palette.text.invertedprimary, fontSize: 30 }} />
                        </IconButton>
                    </Grid>
                    <Grid container justifyContent="space-between" display="flex" width="100%" alignItems="center" my={1} px={2}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    background: `linear-gradient(100deg, #433E49, #433E49, transparent)`,
                                    borderRadius: '50%',
                                    height: 35,
                                    width: 35,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Avatar src={selectedSupplyToken.logoURI} sx={{ width: 20, height: 20 }} />
                            </Box>
                            <Box
                                sx={{
                                    background: `linear-gradient(135deg, #433E49, #433E49, transparent)`,
                                    borderRadius: '50%',
                                    height: 35,
                                    ml: -1,
                                    width: 35,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Avatar src={selectedCollateralToken.logoURI} sx={{ width: 20, height: 20 }} />
                            </Box>
                            <Typography color={theme.palette.text.invertedprimary} fontSize={17} fontWeight={700} mx={1}>
                                {selectedSupplyToken.symbol}/{selectedCollateralToken.symbol}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                borderRadius: 2,
                                border: 1,
                                display: 'flex',
                                p: 2,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: 2
                            }}
                        >
                            <Grid item width="100%">
                                <Typography color={theme.palette.text.invertedthird} fontWeight={500} fontSize={18}>
                                    My Offer
                                </Typography>
                            </Grid>
                            <Grid container width="40%" mt={1} display="flex" ml={1} alignItems="center">
                                <Box
                                    sx={{
                                        background: `linear-gradient(100deg, transparent, #28242b, transparent)`,
                                        borderRadius: '50%',
                                        height: 30,
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar src={selectedSupplyToken.logoURI} sx={{ width: 18, height: 18 }} />
                                </Box>
                                <Typography color={theme.palette.text.invertedthird} fontWeight={600} ml={0.5} fontSize={16}>
                                    {selectedSupplyToken.symbol}
                                </Typography>
                            </Grid>
                            <Grid container width="55%" justifyContent="right" mt={1}>
                                <Typography color={theme.palette.text.invertedprimary} fontWeight={700} ml={1.8} fontSize={16}>
                                    {(amountA / 1e18).toLocaleString('en-US', { maximumFractionDigits: 5, minimumFractionDigits: 2 })}{' '}
                                    {selectedSupplyToken.symbol}
                                </Typography>
                            </Grid>
                            <Grid container width="40%" mt={1} display="flex" ml={1} alignItems="center">
                                <Box
                                    sx={{
                                        background: `linear-gradient(100deg, transparent, #28242b, transparent)`,
                                        borderRadius: '50%',
                                        height: 30,
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar src={selectedCollateralToken.logoURI} sx={{ width: 18, height: 18 }} />
                                </Box>
                                <Typography color={theme.palette.text.invertedthird} fontWeight={600} ml={0.5} fontSize={16}>
                                    {selectedCollateralToken.symbol}
                                </Typography>
                            </Grid>
                            <Grid container width="55%" justifyContent="right" mt={1}>
                                <Typography color={theme.palette.text.invertedprimary} fontWeight={700} ml={1.8} fontSize={16}>
                                    {(amountB / 1e18).toLocaleString('en-US', { maximumFractionDigits: 5, minimumFractionDigits: 2 })}{' '}
                                    {selectedCollateralToken.symbol}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: 2, px: 2 }}>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                p: 2,
                                justifyContent: 'space-between',
                                border: 1,
                                borderRadius: 2
                            }}
                        >
                            <Grid container mb={1}>
                                <Typography color={theme.palette.text.invertedthird} fontWeight={700} fontSize={16}>
                                    Payment Terms
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                width="48%"
                                sx={{ bgcolor: theme.palette.card.alternative, borderRadius: 2, py: 0.5, px: 2 }}
                            >
                                <Grid container display="flex" justifyContent="center">
                                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 700, fontSize: 25 }}>
                                        {paymentsQunatity}
                                    </Typography>
                                </Grid>
                                <Typography textAlign="center" sx={{ color: theme.palette.text.invertedsecondary, mt: -0.75 }}>
                                    Payments
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                width="48%"
                                sx={{ bgcolor: theme.palette.card.alternative, borderRadius: 2, py: 0.5, px: 2 }}
                            >
                                <Grid container display="flex" justifyContent="center">
                                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 700, fontSize: 25 }}>
                                        {offset}
                                    </Typography>
                                </Grid>
                                <Typography textAlign="center" sx={{ color: theme.palette.text.invertedsecondary, mt: -0.75 }}>
                                    Offset
                                </Typography>
                            </Grid>
                            <Grid container display="flex" justifyContent="space-between" px={0.8} mt={2}>
                                <Typography color={theme.palette.text.invertedthird} fontWeight={700} fontSize={14}>
                                    Price
                                </Typography>
                                <Typography color={theme.palette.text.invertedprimary} fontWeight={700} fontSize={14}>
                                    {(amountB / amountA).toLocaleString('en-US', {
                                        maximumFractionDigits: 5,
                                        minimumFractionDigits: 2
                                    })}{' '}
                                    {selectedCollateralToken.symbol} per {selectedSupplyToken.symbol}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: 2, px: 2 }}>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                p: 2,
                                justifyContent: 'space-between',
                                border: 1,
                                borderRadius: 2
                            }}
                        >
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: theme.palette.text.invertedsecondary }}>
                                Payment Amount
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: theme.palette.text.invertedsecondary }}>
                                {(amountB / paymentsQunatity / 1e18).toLocaleString('en-US', {
                                    maximumFractionDigits: 5,
                                    minimumFractionDigits: 2
                                })}{' '}
                                {selectedCollateralToken.symbol}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container width="100%" p={2}>
                        <BrandButton
                            text={buttonText}
                            onClick={() => {
                                handleCreateOffer();
                            }}
                            _width="49%"
                            startIcon={buttonText === 'Create offer' ? null : <FeedIcon />}
                            sx={{ fontSize: 16 }}
                        />
                        <GreyButton text="Close" _width="49%" onClick={onClose} sx={{ ml: 0.5 }} />
                    </Grid>
                </Box>
            </Modal>
            <PopUpInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={popText} />
            <TransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default PreviewCreateOffer;
