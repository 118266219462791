import TopLayout from '../TopLayout';
import { darken, useTheme } from '@mui/system';
import { useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { FaHandshake } from 'react-icons/fa';
import AddIcon from '@mui/icons-material/Add';
import { useWeb3React } from '@web3-react/core';
import FilterERC20Token from '../FilterERC20Token';
import CreateLeaseDialog from './CreateLeaseDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterERC20Amount from '../FilterERC20Amount';
import ArticleIcon from '@mui/icons-material/Article';
import LeaseSimpleListCard from './LeaseSimpleListCard';
import FilterERC20Duration from '../FilterERC20Duration';
import LearnHowItWorksLease from './LearnHowItWorksLease';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getOfferInRange } from 'components/wallet/sharesABI';
import FilterERC20PaymentToken from '../FilterERC20PaymentToken';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Grid, Button, Typography, Popover, Menu, MenuItem, Chip } from '@mui/material';

const LeaseMain = () => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [offers, setOffers] = useState([]);
    const [howitworksModal, setHowitworksModal] = useState(false);

    const [amountFilter, setAmountFilter] = useState([0, 0]);
    const handleAmountFilter = (num1, num2) => {
        setAmountFilter([num1, num2]);
    };
    const [durationFilter, setDurationFilter] = useState([0, 0]);
    const handleDurationFilter = (num1, num2) => {
        setDurationFilter([num1, num2]);
    };
    const [paymentTokenFilter, setPaymentTokenFilter] = useState('');
    const handlePaymentTokenFilter = (token) => {
        setPaymentTokenFilter(token);
    };

    const refetchOffers = async () => {
        const tmpoffers = await getOfferInRange(0, 50);
        setOffers(tmpoffers);
        console.log(tmpoffers);
    };

    /* Cards booleans */
    const [offerCard, setOfferCard] = useState(false);
    const [tokenCard, setTokenCard] = useState(false);
    const [amountCard, setAmountCard] = useState(false);
    const [paymentTokenModal, setPaymentTokenModal] = useState(false);
    const [durationCard, setDurationCard] = useState(false);

    // HandleCards
    const openOfferCard = () => {
        setOfferCard(true);
    };

    const closeOfferCard = () => {
        setOfferCard(false);
    };

    const openTokenCard = () => {
        setTokenCard(true);
    };

    const openAmountCard = () => {
        setAmountCard(true);
    };

    const closeAmnountCard = () => {
        setAmountCard(false);
    };

    const openDurationCard = () => {
        setDurationCard(true);
    };

    const closeDurationCard = () => {
        setDurationCard(false);
    };

    const openPaymentTokensModal = () => {
        setPaymentTokenModal(true);
    };

    const closePaymentTokensModal = () => {
        setPaymentTokenModal(false);
    };

    useEffect(() => {
        const fetchOffers = async () => {
            const tmpoffers = await getOfferInRange(0, 10);
            setOffers(tmpoffers);
            console.log(tmpoffers);
        };
        fetchOffers();
    }, [account, active]);
    useEffect(() => {
        document.title = 'Lease | AlphaDAO';
    }, []);
    const filteredOffers = offers
        .map((item, index) => ({ ...item, originalIndex: index })) // Step 1: Map to add original index
        .filter((item) => parseFloat(item[4]) !== 0) // Step 2: Filter out items where amountA is zero
        .filter((item) =>
            amountFilter[0] === 0 && amountFilter[1] === 0 ? true : item[4] >= amountFilter[0] && item[4] <= amountFilter[1]
        ) // Filter by amount
        .filter((item) =>
            durationFilter[0] === 0 && durationFilter[1] === 0
                ? true
                : item[7] * item[6] - item[7] >= durationFilter[0] && item[7] * item[6] - item[7] <= durationFilter[1]
        ) // Filter by duration
        .filter((item) => (paymentTokenFilter.address ? item[2] === paymentTokenFilter.address : true)); // Filter by payment token

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id2 = open ? 'simple-popover' : undefined;
    const [activeContent, setActiveContent] = useState();
    const [activeSteps, setActiveSteps] = useState();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <TopLayout
                title="Put your funds to work by leasing your tokens."
                text="When you create an offer to lease cryptocurrencies, you set up custom amount, price, payment terms and payment token allowing you to generate profit during the lease deal."
            />
            <Grid
                container
                sx={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 5,
                    backgroundColor: theme.palette.background.default
                }}
            >
                <Grid item xs={11.4} lg={8.9} md={9.6} sm={11.4}>
                    <Grid
                        item
                        display="flex"
                        mb={5}
                        sx={{
                            justifyContent: { lg: 'left', md: 'left', sm: 'space-between', xs: 'center' },
                            flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' }
                        }}
                    >
                        <Button
                            startIcon={<AddIcon sx={{ color: grey[200] }} />}
                            onClick={openOfferCard}
                            sx={{
                                pr: 2,
                                height: 40,
                                borderRadius: 2,
                                color: grey[100],
                                textTransform: 'none',
                                bgcolor: theme.palette.primary.main,
                                width: { lg: 'auto', md: 'auto', sm: '48%', xs: '100%' }
                            }}
                        >
                            I want to create a lease
                        </Button>
                        <Button
                            startIcon={<ArticleIcon sx={{ color: grey[200] }} />}
                            onClick={handleClick}
                            sx={{
                                px: 2,
                                height: 40,
                                borderRadius: 2,
                                color: grey[100],
                                textTransform: 'none',
                                bgcolor: theme.palette.card.light,
                                ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                                mt: { lg: 0, md: 0, sm: 0, xs: 2 },
                                width: { lg: 'auto', md: 'auto', sm: '48%', xs: '100%' }
                            }}
                        >
                            Learn how it works
                        </Button>
                    </Grid>
                    <Grid item width="100%">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 24 }}>Filters</Typography>
                    </Grid>
                    <Grid
                        item
                        display="flex"
                        justifyContent="space-between"
                        lg={5.5}
                        md={7}
                        mt={1}
                        boxShadow={6}
                        px={1}
                        border={1}
                        borderRadius={2}
                        sx={{ background: theme.palette.card.main }}
                    >
                        <Button
                            onClick={openTokenCard}
                            variant="text"
                            endIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />}
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                Any token
                            </Typography>
                        </Button>
                        <Button
                            onClick={() => {
                                if (amountFilter[1] !== 0) {
                                    setAmountFilter([0, 0]);
                                } else {
                                    openAmountCard();
                                }
                            }}
                            variant="text"
                            endIcon={
                                amountFilter[1] === 0 ? (
                                    <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                                )
                            }
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                {amountFilter[1] === 0
                                    ? `Amount`
                                    : `${(amountFilter[0] / 1e18).toLocaleString('en-US', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                      })} - ${(amountFilter[1] / 1e18).toLocaleString('en-US', {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                      })}`}
                            </Typography>
                        </Button>
                        <Button
                            onClick={() => {
                                if (paymentTokenFilter.address) {
                                    setPaymentTokenFilter('');
                                } else {
                                    openPaymentTokensModal();
                                }
                            }}
                            variant="text"
                            endIcon={
                                paymentTokenFilter === '' ? (
                                    <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                                )
                            }
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                {paymentTokenFilter === '' ? 'Payment token' : paymentTokenFilter.symbol}
                            </Typography>
                        </Button>
                        <Button
                            variant="text"
                            onClick={() => {
                                if (durationFilter[1] !== 0) {
                                    setDurationFilter([0, 0]);
                                } else {
                                    openDurationCard();
                                }
                            }}
                            endIcon={
                                durationFilter[1] === 0 ? (
                                    <ExpandMoreIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13 }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                                )
                            }
                            sx={{ textTransform: 'none' }}
                        >
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 14, fontWeight: 600 }}>
                                {durationFilter[1] === 0 ? `Duration` : `${durationFilter[0]} - ${durationFilter[1]} days`}
                            </Typography>
                        </Button>
                    </Grid>
                    <FilterERC20Token onClose={closeOfferCard} isOpen={tokenCard} />
                    <FilterERC20Amount onClose={closeAmnountCard} isOpen={amountCard} onAmountChange={handleAmountFilter} />
                    <FilterERC20PaymentToken
                        onClose={closePaymentTokensModal}
                        isOpen={paymentTokenModal}
                        onTokenSelect={handlePaymentTokenFilter}
                    />
                    <FilterERC20Duration onClose={closeDurationCard} isOpen={durationCard} onDurationChange={handleDurationFilter} />
                </Grid>
                <Grid
                    container
                    sx={{
                        borderRadius: 2,
                        overflow: 'hidden',
                        background: 'transparent',
                        width: { lg: '74%', md: '82%' }
                    }}
                    mb={8}
                    mt={2}
                >
                    <Grid
                        container
                        sx={{
                            mb: 0.5,
                            py: 1,
                            px: 2,
                            width: '100%',
                            display: 'flex',
                            borderRadius: 2,
                            justifyContent: 'space-between',
                            background: theme.palette.card.main,
                            ':hover': {
                                background: darken(theme.palette.card.main, 0.1)
                            }
                        }}
                    >
                        <Grid item width={185}>
                            <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 600 }}>Pool</Typography>
                        </Grid>
                        <Grid item width={130}>
                            <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 600 }}>Available</Typography>
                        </Grid>
                        <Grid item width={150}>
                            <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 600 }}>Price</Typography>
                        </Grid>
                        <Grid item width={100}>
                            <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 600 }}>Payments</Typography>
                        </Grid>
                        <Grid item width={100}>
                            <Typography sx={{ color: grey[400], fontSize: 12, fontWeight: 600 }}>Offset</Typography>
                        </Grid>
                        <Grid item width={120} />
                    </Grid>
                    {filteredOffers.map((item) => (
                        <LeaseSimpleListCard
                            key={item.originalIndex}
                            lessor={item[0]}
                            tokenA={item[1]}
                            tokenB={item[2]}
                            originalSize={item[3]}
                            amountA={item[4]}
                            reservedPrice={item[5]}
                            installments={item[6]}
                            offset={item[7]}
                            id={item.originalIndex} // Use original index as id
                            onPayment={refetchOffers}
                        />
                    ))}
                </Grid>
                <LearnHowItWorksLease
                    open={howitworksModal}
                    setOpen={setHowitworksModal}
                    activeContent={activeContent}
                    activeSteps={activeSteps}
                />
                <CreateLeaseDialog handleOpen={offerCard} onClose={closeOfferCard} onOfferCreated={refetchOffers} />
            </Grid>
            <Popover
                id={id2}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    sx: {
                        width: 'auto',
                        bgcolor: 'transparent',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 3,
                        p: 0.5
                    }
                }}
            >
                <Menu
                    open={open}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    sx={{
                        '.MuiPaper-root': {
                            backgroundColor: 'transparent',
                            backdropFilter: 'blur(10px)',
                            borderRadius: 2
                        }
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setActiveSteps(0);
                            setActiveContent(0);
                            setHowitworksModal(true);
                        }}
                    >
                        <Grid container width="100%" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container width="100%" sx={{ display: 'flex' }}>
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 500, mr: 1 }}>
                                    Take lease offer
                                </Typography>
                                <Chip
                                    size="small"
                                    label={<FaHandshake style={{ color: theme.palette.text.invertedsecondary, marginTop: 5 }} />}
                                />
                            </Grid>
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 13 }}>
                                Quick guide on how to take a lease in an instant.
                            </Typography>
                        </Grid>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setActiveSteps(1);
                            setActiveContent(1);
                            setHowitworksModal(true);
                        }}
                    >
                        <Grid container width="100%" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container width="100%" sx={{ display: 'flex' }}>
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 500, mr: 1 }}>
                                    Create lease offer
                                </Typography>
                                <Chip
                                    size="small"
                                    label={<AddIcon sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13, mt: 0.75 }} />}
                                />
                            </Grid>
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 13 }}>
                                Learn how to create a lease offer.
                            </Typography>
                        </Grid>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setActiveSteps(2);
                            setActiveContent(2);
                            setHowitworksModal(true);
                        }}
                    >
                        <Grid container width="100%" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container width="100%" sx={{ display: 'flex' }}>
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 500, mr: 1 }}>
                                    Make a payment
                                </Typography>
                                <Chip
                                    size="small"
                                    label={
                                        <AccountBalanceWalletIcon
                                            sx={{ color: theme.palette.text.invertedsecondary, fontSize: 13, mt: 0.75 }}
                                        />
                                    }
                                />
                            </Grid>
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 13 }}>
                                Quick guide on how to pay lease installment.
                            </Typography>
                        </Grid>
                    </MenuItem>
                </Menu>
            </Popover>
        </>
    );
};

export default LeaseMain;
