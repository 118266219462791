import { grey } from '@mui/material/colors';
import { Grid, Typography, Box, LinearProgress, IconButton, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import { Progress } from 'antd';
import InfoIcon from '@mui/icons-material/Info';
import { brandColor } from 'themes/constants';
import alxlogo from '../../../assets/alxlogo.svg';
import usdtlogo from '../../../assets/UsdtLogo.png';
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { currentDay, dailyTotalDonation, getHPDCount, totalALXburnt } from 'components/wallet/sharesABI';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import AuctionsGraph from './AuctionsGraph';

const formatValue = (value) => {
    // Convert the input to a number
    const num = Number(value);
    // Format the number based on its size
    if (num >= 1000 && num < 1000000) {
        return `${(num / 1000).toFixed(2)}K`; // Thousands
    }
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(2)}M`; // Millions
    }
    return value; // Return the original value for numbers less than 1000
};

const AuctionsStats = () => {
    const theme = useTheme();
    const { account } = useWeb3React();
    const [popText, setPopText] = useState('');
    const [hpdcount, setHpdcount] = useState(0);
    const [auctionDay, setAuctionDay] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [dailyDonation, setDailyDonation] = useState(0);
    const [ALXBurnt, setALXBurnt] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const day = await currentDay();
            setAuctionDay(day);
            const hpdaddresses = await getHPDCount();
            setHpdcount(hpdaddresses);
            const tempDailyTotalDonation = await dailyTotalDonation(day);
            setDailyDonation(tempDailyTotalDonation);
            const tempALXBurnt = await totalALXburnt();
            setALXBurnt(tempALXBurnt);
        };

        fetchData();
    }, [account]);
    return (
        <>
            <Grid container lg={6} md={6} sx={{ p: { lg: 5, md: 5, sm: 0, xs: 0 } }}>
                <Grid
                    container
                    width="100%"
                    mt={4}
                    sx={{ borderTop: { lg: 0, md: 0, xs: 1, sm: 1 } }}
                    borderColor={theme.palette.text.invertedfourth}
                >
                    <Typography sx={{ color: theme.palette.text.invertedsecondary, mt: 3, fontWeight: 600, fontSize: 18 }}>
                        Auctions
                    </Typography>
                </Grid>
                <Grid container width="100%" mt={4} alignItems="center" display="flex">
                    <Grid container lg="auto" xs="auto" mt={1}>
                        <Progress
                            trailColor={theme.palette.mode === 'dark' ? grey[700] : grey[400]}
                            strokeColor={brandColor}
                            strokeWidth={7}
                            size={100}
                            type="circle"
                            percent={auctionDay}
                            format={() => (
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 18, fontWeight: 505 }}>
                                    {auctionDay}/100
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid container lg={3.2} md={3.38} ml={2} xs={3} alignItems="center">
                        <Grid item>
                            <Typography sx={{ color: theme.palette.text.invertedfourth }}>
                                HPD addresses
                                <IconButton
                                    size="small"
                                    sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                    onClick={() => {
                                        setPopText(
                                            'The number displays the total amount of addresses that are eligible to receive a HappyPayDay.'
                                        );
                                        setModalOpen(true);
                                    }}
                                >
                                    <InfoIcon sx={{ color: brandColor, fontSize: 15 }} />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 505 }}>{hpdcount}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container lg={4.7} xs={3.5} md={3.9} ml={2} alignItems="center">
                        <Grid item>
                            <Typography sx={{ color: theme.palette.text.invertedfourth }}>
                                USDT contributed
                                <IconButton
                                    size="small"
                                    sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                    onClick={() => {
                                        setPopText(
                                            'The display shows the current daily emission rate, which gets halved until all tokens are minted.'
                                        );
                                        setModalOpen(true);
                                    }}
                                >
                                    <InfoIcon sx={{ color: brandColor, fontSize: 15 }} />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid container display="flex" alignItems="center" width="100%">
                            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 505, mr: 0.5 }}>
                                {dailyDonation.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            </Typography>
                            <img src={usdtlogo} width={15} height={15} alt="logo" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="100%" py={3} display="flex" justifyContent="center">
                    <AuctionsGraph />
                </Grid>
                <Grid container width="100%" display="flex" justifyContent="left" alignItems="center" mt={4}>
                    <Typography color={theme.palette.text.invertedprimary} fontWeight={405} pb={1} fontSize={15}>
                        Emission rate
                    </Typography>
                    <Grid
                        container
                        sx={{
                            width: '100%',
                            bgcolor: theme.palette.borderColor,
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: '#FFFDC5',
                            p: 2,
                            borderRadius: 1,
                            alignItems: 'center'
                        }}
                    >
                        <Grid container width="10%">
                            <InfoIcon sx={{ color: brandColor, fontSize: 25 }} />
                        </Grid>
                        <Grid container width="90%" pl={0.5}>
                            <Typography sx={{ color: '#FEE3D9', fontWeight: 500, fontSize: 15 }}>
                                AlphaDAO Auctions will distribute in total of 20,000,000 ALX in the span of 100 days.
                            </Typography>
                            <Typography sx={{ color: '#FEE3D9', fontWeight: 500, fontSize: 13, mt: 2 }}>
                                Over the course of 100 consecutive days, AlphaDAO Auctions will distribute 200,000 ALX tokens daily,
                                culminating in a total distribution of 20M ALX.
                            </Typography>
                            <Button
                                href="https://alphadao.finance/whitepaper"
                                sx={{
                                    color: '#FEE3D9',
                                    bgcolor: 'transparent',
                                    textDecoration: 'underline',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    fontSize: 13
                                }}
                            >
                                Learn more
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="100%" display="flex" justifyContent="left" alignItems="center" mt={4}>
                    <Grid
                        item
                        width="50%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedfourth}>To be distributed</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText('The display indicates the quantity of ALX tokens yet to be minted.');
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405} mr={0.5}>
                                {formatValue(20000000 - auctionDay * 200000)}
                            </Typography>
                            <img src={alxlogo} alt="logo" width={15} />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        width="40%"
                        border={1}
                        ml={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedfourth}>Distributed</Typography>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405} mr={0.5}>
                                {formatValue(auctionDay * 200000)}
                            </Typography>
                            <img src={alxlogo} alt="logo" width={15} />
                        </Grid>
                    </Grid>
                    <Box
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        width="100%"
                        justifyContent="left"
                        mt={1}
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedfourth}>ALX burnt</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The figure indicates the transfer fee for ALX tokens which is subject to governance every six days, with potential rates of 1%, 2%, or 5%.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405}>
                                {formatValue(ALXBurnt)}
                            </Typography>
                        </Grid>
                        <LinearProgress variant="determinate" color="success" value={100} sx={{ borderRadius: 1 }} />
                    </Box>
                </Grid>
            </Grid>
            <PopUpInfo open={modalOpen} setOpen={setModalOpen} text={popText} />
        </>
    );
};

export default AuctionsStats;
