import { Grid, Typography, Box, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import { lightColorUI } from 'themes/constants';
import CircleIcon from '@mui/icons-material/Circle';
import '../BlinkingText.css';
import { useState, useEffect } from 'react';
import MyRadioGroup from './RadioGroupFIP';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import PropTypes from 'prop-types';

const GovernanceActiveCard = ({ currentRound, title, currentFee }) => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [currentVotes] = useState([0, 0, 0]);
    const [availableToVote, setAvailableToVote] = useState('0.00');
    const [vote1Fee, vote2Fee, vote5Fee] = currentVotes;
    const [setCurrentOption] = useState(1);
    const handleValueChange = (newValue) => {
        setCurrentOption(newValue);
    };

    return (
        <>
            <Grid
                container
                lg={4}
                xs={11.7}
                sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    bgcolor: theme.palette.card.main,
                    p: 3,
                    borderRadius: 2,
                    mb: 10
                }}
            >
                <Typography sx={{ color: '#f9774b', fontWeight: 505, letterSpacing: '1px', fontSize: 18 }}>
                    #{currentRound} AlphaDAO Governance
                </Typography>
                <Typography sx={{ color: theme.palette.text.invertedthird, letterSpacing: '1px', fontSize: 18 }}>{title}</Typography>
                <Grid container display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Box
                        sx={{
                            border: 1,
                            borderColor: lightColorUI,
                            borderRadius: 2,
                            px: 1.5,
                            py: 0.5,
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <CircleIcon className="blinking-text" sx={{ color: lightColorUI, fontSize: 13 }} />
                        <Typography className="blinking-text" sx={{ color: lightColorUI, fontSize: 13, mx: 0.8 }}>
                            Live
                        </Typography>
                    </Box>
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 15 }}>
                        Voting power: {account ? availableToVote : '0.00'}
                    </Typography>
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 15 }}>Curr. fee: {currentFee}%</Typography>
                </Grid>
                <Grid container mx={1} my={1}>
                    <MyRadioGroup vote1={vote1Fee} vote2={vote2Fee} vote3={vote5Fee} onValueChange={handleValueChange} />
                </Grid>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    bgcolor={theme.palette.card.light}
                    borderRadius={2}
                    px={2}
                    py={1.5}
                    mb={3}
                    mt={1}
                >
                    <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 16, fontWeight: 500 }}>
                        Each wallet address may submit a single voting entry per round.
                    </Typography>
                </Grid>
                <Button
                    disabled={(!account && !active) || availableToVote !== 0}
                    fullWidth
                    sx={{
                        background: `linear-gradient(45deg, #EB4308, #f9774b)`,
                        textTransform: 'none',
                        color: grey[50],
                        ':disabled': { background: grey[600], color: grey[50] }
                    }}
                >
                    {active ? 'Vote' : 'Connect wallet'}
                </Button>
            </Grid>
        </>
    );
};

// Define prop types for GovernanceActiveCard
GovernanceActiveCard.propTypes = {
    currentRound: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    currentFee: PropTypes.number.isRequired
};

export default GovernanceActiveCard;
