import { grey } from '@mui/material/colors';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import CircleIcon from '@mui/icons-material/Circle';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { brandColor } from 'themes/constants';

const data = [
    {
        name: 'Page A',
        pv: 5,
        amt: 2400
    },
    {
        name: 'Page B',
        pv: 8,
        amt: 2210
    },
    {
        name: 'Page C',
        pv: 12,
        amt: 2290
    },
    {
        name: 'Page D',
        pv: 24,
        amt: 2000
    },
    {
        name: 'Page E',
        pv: 33,
        amt: 2181
    },
    {
        name: 'Page F',
        pv: 39,
        amt: 2500
    },
    {
        name: 'Page G',
        pv: 82,
        amt: 2100
    },
    {
        name: 'Page B',
        pv: 330,
        amt: 2210
    },
    {
        name: 'Page C',
        pv: 550,
        amt: 2290
    },
    {
        name: 'Page D',
        pv: 660,
        amt: 2000
    },
    {
        name: 'Page E',
        pv: 680,
        amt: 2181
    },
    {
        name: 'Page F',
        pv: 950,
        amt: 2500
    },
    {
        name: 'Page G',
        pv: 2300,
        amt: 2100
    }
];

const LendBorrowGraph = () => {
    const theme = useTheme();
    return (
        <>
            <ResponsiveContainer width="100%" height={280}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        bottom: 5,
                        left: 25
                    }}
                >
                    <Legend iconSize={0} align="left" verticalAlign="top" />
                    <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} stroke={grey[500]} vertical={false} fill="transparent" />
                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        dataKey="name"
                        interval={-1}
                    />
                    <YAxis axisLine={false} tickLine={false} tick={{ fill: theme.palette.text.invertedfourth }} domain={[10000]} />
                    <Tooltip />
                    <Area
                        type="monotone"
                        name={
                            <Grid container alignItems="center" ml={-4} mr={5} mb={5}>
                                <CircleIcon sx={{ color: brandColor, fontSize: 7 }} />
                                <Typography sx={{ color: theme.palette.text.invertedfourth, pl: 1 }}>All-time offers</Typography>
                            </Grid>
                        }
                        dataKey="pv"
                        stroke={brandColor}
                        strokeWidth={2}
                        fill="url(#colorPrice)"
                        dot={false}
                        activeDot={{ r: 8 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default LendBorrowGraph;
