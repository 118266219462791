import { useState } from 'react';
import { Grid } from '@mui/material';
import Introduction from './Introduction';

// ==============================|| HOMEPAGE ||============================== //

const Homepage = () => {
    const [isLoading, setLoading] = useState(true);

    return (
        <>
            <Grid container>
                <Introduction isLoading={isLoading} />
            </Grid>
        </>
    );
};

export default Homepage;
