// material-ui
import { useTheme } from '@emotion/react';
import {
    Typography,
    Modal,
    Box,
    Grid,
    IconButton,
    LinearProgress,
    linearProgressClasses,
    TextField,
    InputAdornment,
    Button
} from '@mui/material';
import { grey, lightGreen } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { approveAnyToken, balanceOfAnyToken, getAllowanceAnyToken, payInstallment } from 'components/wallet/sharesABI';
import CountdownTimer from 'views/utilities/CountdownTimer';
import styled from '@emotion/styled';
import busdlogo from '../assets/busdtransparent.svg';
import cakelogo from '../assets/cakelogo.svg';
import btclogo from '../assets/bitcoinsvg.svg';
import ethlogo from '../assets/ethlogo.png';
import Web3 from 'web3';
import RejectedTransactionModal from './RejectedTransactionModal';
import SubmittedTransactionModal from './SubmittedTransactionModal';
import BrandButton from './BrandButton';
import SuccessfullTransactionModal from './SuccessfulTransactionModal';

// ==============================|| TransactionModal ||============================== //

// Custom styling for the LinearProgress
const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15, // You can change the height
    borderRadius: 1, // Set the border radius
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.card.light,
        borderRadius: 4
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 4,
        backgroundColor: lightGreen[400]
    }
}));

const PaymentModal = ({
    open,
    setOpen,
    leasesize,
    reservedprice,
    totalpaid,
    installments,
    tokenB,
    tokenA,
    addressB,
    installmentspaid,
    id,
    onPayment
}) => {
    const theme = useTheme();
    const web3 = new Web3(window.ethereum);
    const { BN, toWei, fromWei } = web3.utils;
    const { account, active } = useWeb3React();
    const [anyBalance, setAnyBalance] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [allowance, setAllowance] = useState(0);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [txnHash, setTxnHash] = useState('');
    const [inputValue, setInputValue] = useState(0);
    const tokenImages = {
        '0xd389253265dd6b85C47c410EC5fF0c6A383CE949': busdlogo,
        '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551': cakelogo,
        '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8': btclogo,
        '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378': ethlogo
    };
    const handleClose = () => setOpen(false);

    const handleApprove = async () => {
        try {
            await approveAnyToken(tokenB, account, paymentAmount, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            const newAllowance = await getAllowanceAnyToken(tokenB, account);
            setAllowance(new BN(newAllowance.toString()));
            setModalSubmitted(false);
            setModalOpen(true); // Open the success modal
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };
    const handleChangeAmount = (event) => {
        let value = event.target.value;

        // Remove any non-digit and non-decimal characters except the first dot
        value = value.replace(/[^\d.]/g, '');

        // Ensure only one decimal point is present
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        setInputValue(value); // Update the amount state with sanitized value

        if (value) {
            const amountInWei = toWei(value.toString(), 'ether');
            setPaymentAmount(new BN(amountInWei)); // Update amountBN state
        } else {
            setPaymentAmount(new BN(0)); // If input value is empty, set amountBN to 0
        }
    };
    const handlePayment = async () => {
        try {
            await payInstallment(id, paymentAmount, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true); // Open the success modal
            onPayment();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    useEffect(() => {
        const fetchAnyBalance = async () => {
            const balance = await balanceOfAnyToken(addressB, account);
            setAnyBalance(balance);
        };

        const getAllowance = async () => {
            const tmpallowance = await getAllowanceAnyToken(addressB, account);
            setAllowance(new BN(tmpallowance.toString()));
        };

        if (active && account) {
            fetchAnyBalance();
            getAllowance();
        }
    }, [BN, account, active, addressB]);

    const handleChangeAmountButton = (isMax) => {
        try {
            // Calculate leaseTotal and totalPaidBN as BigNumber instances
            const leaseTotal = new BN(leasesize).mul(new BN(reservedprice));
            const totalPaidBN = new BN(totalpaid);

            const leaseTotalFromWei = fromWei(leaseTotal, 'ether');
            const leaseTotalBN = new BN(leaseTotalFromWei);

            // Calculate maxAmountBN as BigNumber instance and keep it as BN
            const maxAmountBN = leaseTotalBN.sub(totalPaidBN);

            // Calculate installmentAmountBN as BigNumber instance and keep it as BN
            const installmentAmountBN = leaseTotalBN.div(new BN(installments));

            // Determine the amount to set as BN
            let selectedAmountBN;
            if (isMax || installmentAmountBN.gt(maxAmountBN)) {
                selectedAmountBN = maxAmountBN;
            } else {
                selectedAmountBN = installmentAmountBN;
            }

            // Convert selected amount to a readable string for setting input value
            const selectedAmount = fromWei(selectedAmountBN, 'ether');

            // Set input value as a JavaScript number
            setInputValue(selectedAmount);

            setPaymentAmount(selectedAmountBN);
        } catch (error) {
            console.error('Error in handleChangeAmountButton:', error);
        }
    };

    return (
        <>
            <Modal open={open} onClose={handleClose} onPayment={onPayment}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                        background: theme.palette.card.main,
                        borderRadius: 2,
                        boxShadow: 24
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            bgcolor: theme.palette.card.light,
                            p: 2,
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5
                        }}
                    >
                        <Typography sx={{ textAlign: 'center', fontSize: 20, fontWeight: 600, color: theme.palette.text.invertedprimary }}>
                            Make a payment
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon sx={{ color: theme.palette.text.invertedprimary }} />
                        </IconButton>
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2.5, px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.invertedfourth, borderBottom: '1px dotted' }}>
                            Lease size
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 700, color: theme.palette.text.invertedprimary }}>
                            {parseFloat(leasesize / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}{' '}
                            {tokenA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.invertedfourth, borderBottom: '1px dotted' }}>
                            Reserved price
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 700, color: theme.palette.text.invertedprimary }}>
                            1 {tokenA} /{' '}
                            {parseFloat(reservedprice / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 6,
                                minimumFractionDigits: 2
                            })}{' '}
                            {tokenB}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.invertedfourth, borderBottom: '1px dotted' }}>
                            Total paid
                        </Typography>
                        <Grid item xs={8} alignItems="center" justifyContent="center" display="flex">
                            <Box width="100%">
                                <CustomLinearProgress
                                    variant="determinate"
                                    value={(totalpaid / 1e18 / ((reservedprice / 1e18) * (leasesize / 1e18))) * 100}
                                />
                            </Box>
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 700, color: theme.palette.text.invertedprimary, position: 'absolute' }}
                            >
                                {parseFloat(totalpaid / 1e18).toLocaleString('en-US', {
                                    maximumFractionDigits: 5,
                                    minimumFractionDigits: 2
                                })}{' '}
                                /{' '}
                                {parseFloat((reservedprice * leasesize) / 1e18 / 1e18).toLocaleString('en-US', {
                                    maximumFractionDigits: 5,
                                    minimumFractionDigits: 2
                                })}{' '}
                                {tokenB}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.invertedfourth, borderBottom: '1px dotted' }}>
                            Payment size
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 700, color: theme.palette.text.invertedprimary }}>
                            {parseFloat(((leasesize / 1e18) * reservedprice) / 1e18 / installments).toLocaleString('en-US', {
                                maximumFractionDigits: 5,
                                minimumFractionDigits: 2
                            })}{' '}
                            {tokenB}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.invertedfourth, borderBottom: '1px dotted' }}>
                            Installments paid
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 700, color: theme.palette.text.invertedprimary }}>
                            {installmentspaid}/{installments}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, color: theme.palette.text.invertedfourth, borderBottom: '1px dotted' }}>
                            Next payment due
                        </Typography>
                        <CountdownTimer targetTimestamp={1715565461000} />
                    </Grid>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2.5 }}>
                        <Typography sx={{ fontSize: 14, borderBottom: '1px dotted', color: theme.palette.text.invertedfourth }}>
                            Payment amount
                        </Typography>
                        <Grid item xs={4}>
                            <TextField
                                variant="standard"
                                fullWidth
                                value={inputValue}
                                onChange={handleChangeAmount}
                                size="small"
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img
                                                src={tokenImages[addressB]}
                                                alt="Icon"
                                                style={{ width: 20, height: 20, borderRadius: '50%', marginRight: 5 }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        color: theme.palette.text.invertedthird,
                                        fontSize: 18,
                                        textAlign: 'right',
                                        fontWeight: 600
                                    },
                                    mt: 0.5,
                                    bgcolor: theme.palette.card.light,
                                    borderRadius: 2
                                }}
                            />
                        </Grid>
                        <Grid item width="100%" justifyContent="right" display="flex">
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 14, fontWeight: 600 }}>
                                Balance:{' '}
                                {(anyBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}
                                {} {tokenB}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container display="flex" justifyContent="right" px={2.5} mt={1}>
                        <Button
                            onClick={() => {
                                handleChangeAmountButton();
                            }}
                            sx={{
                                mr: 1,
                                height: 30,
                                fontSize: 13,
                                fontWeight: 600,
                                borderRadius: 2,
                                background: 'transparent',
                                color: theme.palette.text.invertedprimary,
                                textTransform: 'none',
                                border: '1px dotted',
                                borderColor: grey[500]
                            }}
                        >
                            1 payment
                        </Button>
                        <Button
                            onClick={() => {
                                handleChangeAmountButton(true);
                            }}
                            sx={{
                                borderRadius: 2,
                                height: 30,
                                fontSize: 13,
                                fontWeight: 600,
                                background: 'transparent',
                                color: theme.palette.text.invertedprimary,
                                textTransform: 'none',
                                border: '1px dotted',
                                borderColor: grey[500]
                            }}
                        >
                            Full payment
                        </Button>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 2.5, mb: 4, px: 2 }}>
                        {parseFloat(paymentAmount) > parseFloat(allowance) ? (
                            <BrandButton
                                text="Approve"
                                onClick={() => {
                                    handleApprove();
                                }}
                                sx={{ fontWeight: 600, fontSize: 16 }}
                                _width="100%"
                            />
                        ) : (
                            <BrandButton
                                text="Make a payment"
                                onClick={() => {
                                    handlePayment();
                                }}
                                sx={{ fontWeight: 600, fontSize: 16 }}
                                _width="100%"
                            />
                        )}
                    </Grid>
                </Box>
            </Modal>
            <SuccessfullTransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

// Define prop types for TransactionModal
PaymentModal.propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    leasesize: PropTypes.string.isRequired,
    reservedprice: PropTypes.string.isRequired,
    totalpaid: PropTypes.string.isRequired,
    installments: PropTypes.string.isRequired,
    tokenA: PropTypes.string.isRequired,
    tokenB: PropTypes.string.isRequired,
    addressB: PropTypes.string.isRequired,
    installmentspaid: PropTypes.string.isRequired,
    onPayment: PropTypes.func.isRequired
};

export default PaymentModal;
