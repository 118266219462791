import TopLayout from '../TopLayout';
import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import HappyPayDayCard from './HappyPayDayCard';
import GlobalStatsCard from './GlobalStatsCard';
import ClaimableALXCard from './ClaimableALXCard';
import MyStatisticsCard from './MyStatisticsCard';
import BrandButton from 'ui-component/BrandButton';
import ConnectWalletCard from '../ConnectWalletCard';
import ContributeUSDTCard from './ContributeUSDTCard';
import { incrementDay } from 'components/wallet/sharesABI';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import SuccessfullTransactionModal from 'ui-component/SuccessfulTransactionModal';

const AuctionsMain = () => {
    const { active, account } = useWeb3React();
    const [txnHash, setTxnHash] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isSuccessful2, setIsSuccessful2] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const handleSuccess2 = () => {
        setIsSuccessful2(true);
    };

    const handleIncrementDay = async () => {
        try {
            await incrementDay((hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    useEffect(() => {
        document.title = 'Auctions | AlphaDAO';
    }, []);

    return (
        <>
            <TopLayout
                title="Reserve ALX tokens by contributing USDT."
                text="Users are able to contribute USDT to the AlphaDAO Auctions and reserve ALX tokens. All participants who claimed their ALX are eligible for daily pay backs called HappyPayDay."
            />
            <Grid container display="flex" justifyContent="center" mt={7} mb={3}>
                <Grid item lg={9} md={11.7} sm={11.7} xs={11.7}>
                    <BrandButton
                        text="Increment day"
                        onClick={() => {
                            handleIncrementDay();
                        }}
                        _width={200}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 10, width: '100vw', display: 'flex', justifyContent: 'center' }}>
                <GlobalStatsCard success={isSuccessful} />
                {account && active ? (
                    <Grid
                        container
                        display="flex"
                        sx={{
                            justifyContent: { lg: 'space-between', md: 'space-between', xs: 'center', sm: 'center' },
                            width: { lg: '75%', md: '97.5%' }
                        }}
                    >
                        <ContributeUSDTCard />
                        <MyStatisticsCard success={isSuccessful || isSuccessful2} />
                        <ClaimableALXCard onSuccess={handleSuccess2} />
                        <HappyPayDayCard />
                    </Grid>
                ) : (
                    <Grid item display="flex" lg={11.15} justifyContent="center">
                        <ConnectWalletCard text="explore the auction features" />
                    </Grid>
                )}
            </Grid>
            <SuccessfullTransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default AuctionsMain;
