import { grey } from '@mui/material/colors';
import { Grid, Typography, Box, IconButton, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import { Progress } from 'antd';
import InfoOutlinedIcon from '@mui/icons-material/Info';
import { brandColor } from 'themes/constants';
import alxlogo from '../../../assets/alxlogo.svg';
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { totalALXburnt } from 'components/wallet/sharesABI';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import LendBorrowGraph from './LendBorrowGraph';

const LendBorrowStats = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [popText, setPopText] = useState('');
    const theme = useTheme();
    const { account } = useWeb3React();
    const [alphaBurnt, setAlphaBurnt] = useState(0);

    useEffect(() => {
        const fetchTotalBurnt = async () => {
            const balance = await totalALXburnt();
            setAlphaBurnt(balance);
        };

        fetchTotalBurnt();
    }, [account]);
    return (
        <>
            <Grid container lg={6} md={6} sx={{ p: { lg: 5, md: 5, sm: 0, xs: 0 } }}>
                <Grid
                    container
                    width="100%"
                    mt={4}
                    sx={{ borderTop: { lg: 0, md: 0, xs: 1, sm: 1 } }}
                    borderColor={theme.palette.text.invertedfourth}
                >
                    <Typography sx={{ color: theme.palette.text.invertedsecondary, mt: 3, fontWeight: 600, fontSize: 18 }}>
                        P2P Lend and borrow
                    </Typography>
                </Grid>
                <Grid container width="100%" mt={4} alignItems="center" display="flex">
                    <Grid container lg={3} mt={1} xs={4}>
                        <Progress
                            trailColor={theme.palette.mode === 'dark' ? grey[700] : grey[400]}
                            strokeColor={brandColor}
                            strokeWidth={7}
                            size={100}
                            type="circle"
                            percent={100}
                            format={() => (
                                <>
                                    <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 18, fontWeight: 405 }}>
                                        20+
                                    </Typography>
                                    <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 14 }}>Tokens</Typography>
                                </>
                            )}
                        />
                    </Grid>
                    <Grid container lg={6} xs={7.5} alignItems="center" display="flex" flexDirection="column">
                        <Grid container alignItems="center">
                            <Typography sx={{ color: theme.palette.text.invertedfourth }}>Total supplied</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The display shows the total amount of ERC20 & ERC721 tokens that have been supplied and available to borrow up to date.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: brandColor, fontSize: 15 }} />
                            </IconButton>
                        </Grid>
                        <Grid container alignItems="center">
                            <Typography sx={{ color: theme.palette.text.invertedprimary, mt: 0.5 }}>
                                20+ tokens have been supplied
                            </Typography>
                        </Grid>
                        <Grid container alignItems="center">
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 13 }}>$100.00K+ supplied</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="100%" py={3} display="flex" justifyContent="center">
                    <LendBorrowGraph />
                </Grid>
                <Grid container width="100%" display="flex" justifyContent="left" alignItems="center" mt={4}>
                    <Typography color={theme.palette.text.invertedprimary} fontWeight={405} pb={1} fontSize={15}>
                        Lend and borrow platform fees
                    </Typography>
                    <Grid
                        container
                        sx={{
                            width: '100%',
                            bgcolor: theme.palette.borderColor,
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: '#FFFDC5',
                            p: 2,
                            borderRadius: 1,
                            alignItems: 'center'
                        }}
                    >
                        <Grid container width="10%">
                            <InfoOutlinedIcon sx={{ color: brandColor, fontSize: 25 }} />
                        </Grid>
                        <Grid container width="90%" pl={0.5}>
                            <Typography sx={{ color: '#FEE3D9', fontWeight: 500, fontSize: 15 }}>
                                AlphaDAO Lease platform has a fee of 1% which is being paid taxed upon a pamyment.
                            </Typography>
                            <Typography sx={{ color: '#FEE3D9', fontWeight: 500, fontSize: 13, mt: 2 }}>
                                Upon each payment made by the lessee, 1% of the payment value is allocated as follows: 50% for ALX buyback
                                and burn & 50% for strategic developments
                            </Typography>
                            <Button
                                href="https://alphadao.finance/whitepaper"
                                sx={{
                                    color: '#FEE3D9',
                                    bgcolor: 'transparent',
                                    textDecoration: 'underline',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    fontSize: 13
                                }}
                            >
                                Learn more
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="100%" display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                    <Grid
                        item
                        width="32%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedfourth}>Supplied</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The display shows the total amount of ERC20 & ERC721 tokens that have been supplied and available to borrow up to date.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405}>
                                10+
                            </Typography>
                            <Typography color={theme.palette.text.invertedfourth} pl={0.25} fontWeight={405}>
                                Assets
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        width="32%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedfourth}>Open offers</Typography>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405}>
                                0.00
                            </Typography>
                            <Typography color={theme.palette.text.invertedfourth} pl={0.25} fontWeight={405}>
                                K
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        width="32%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedfourth}>Active loans</Typography>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405}>
                                0.00
                            </Typography>
                            <Typography color={theme.palette.text.invertedfourth} pl={0.25} fontWeight={405}>
                                K
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box width="100%" justifyContent="left" mt={1} color={theme.palette.text.invertedthird}>
                        <Grid
                            container
                            width="100%"
                            border={1}
                            p={1}
                            borderRadius={1}
                            alignItems="center"
                            borderColor={theme.palette.text.invertedfourth}
                        >
                            <Typography fontWeight={405} color={theme.palette.text.invertedprimary}>
                                ALX
                            </Typography>
                            <Typography color={theme.palette.text.invertedfourth} ml={0.5}>
                                burnt from borrow fees
                            </Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The display shows the total amount of ALX tokens burnt from the `Lend and Borrow` platform.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405} pr={0.5}>
                                0.00K
                            </Typography>
                            <img src={alxlogo} alt="logo" width={15} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <PopUpInfo open={modalOpen} setOpen={setModalOpen} text={popText} />
        </>
    );
};

export default LendBorrowStats;
