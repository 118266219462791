// material-ui
import { Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import alxlogo from '../../../assets/alxlogo.svg';
import usdtlogo from '../../../assets/UsdtLogo.png';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { brandColor } from 'themes/constants';
import { useState, useEffect } from 'react';
import TimelineIcon from '@mui/icons-material/Timeline';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import { currentDay, dailyRatio, dailyTotalDonation, launchTime } from 'components/wallet/sharesABI';
import CountdownTimerAuction from '../CountdownTimerAuction';
import { grey } from '@mui/material/colors';

const GlobalStatsCard = ({ success }) => {
    const theme = useTheme();
    const [popText, setPopText] = useState('');
    const [modalInfoOpen, setModalInfoOpen] = useState(false);
    const [day, setDay] = useState(1);
    const [ALXPrice, setALXPrice] = useState(0);
    const [dailyDonation, setDailyDonation] = useState(0);
    const [launchTimestamp, setLaunchTimestamp] = useState('');

    const fetchGlobalStats = async () => {
        const tempDay = await currentDay();
        setDay(tempDay);
        const tempDailyRatio = await dailyRatio(tempDay);
        setALXPrice(tempDailyRatio);
        const tempDailyTotalDonation = await dailyTotalDonation(tempDay);
        setDailyDonation(tempDailyTotalDonation);
        const tempLaunchTimestamp = await launchTime();
        setLaunchTimestamp(tempLaunchTimestamp);
    };
    useEffect(() => {
        fetchGlobalStats();
        if (success) {
            fetchGlobalStats();
        }
    }, [success]);

    return (
        <>
            <Grid
                container
                sx={{
                    p: 2,
                    mb: 2,
                    mt: 4,
                    border: 1,
                    boxShadow: 6,
                    display: 'flex',
                    borderRadius: 2,
                    justifyContent: 'space-between',
                    background: theme.palette.card.main,
                    borderColor: theme.palette.borderColor,
                    width: { lg: '75%', md: '97.5%', sm: '97.5%', xs: '97.5%' }
                }}
            >
                <Grid item lg={3} md={3} sm={5} xs={5}>
                    <Grid container width="auto" display="flex" alignItems="center">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 16 }}>Offered ALX</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText(
                                    'The amount of ALX tokens that will be distributed for the current day. The number is a constant of 200,000 ALX tokens per day.'
                                );
                                setModalInfoOpen(true);
                            }}
                        >
                            <ReceiptIcon sx={{ color: grey[300], fontSize: 16 }} />
                        </IconButton>
                    </Grid>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 18,
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 600
                        }}
                    >
                        200,000 <img src={alxlogo} alt="logo" width={20} style={{ marginLeft: 5 }} />
                    </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={5} xs={5}>
                    <Grid container width="auto" display="flex" alignItems="center">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 16 }}>
                            Next day ({parseFloat(day) + 1})
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText('Time left until the next AlphaDAO Auctions day.');
                                setModalInfoOpen(true);
                            }}
                        >
                            <HourglassTopIcon sx={{ color: grey[300], fontSize: 16 }} />
                        </IconButton>
                    </Grid>
                    <CountdownTimerAuction startingTimestamp={launchTimestamp * 1000} />
                </Grid>
                <Grid item lg={3} md={3} sm={5.5} xs={5.9} sx={{ mt: { lg: 0, md: 0, sm: 2, xs: 2 } }}>
                    <Grid container width="auto" display="flex" alignItems="center">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 16 }}>Current ALX price</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText(
                                    'Displays the current ALX price in USDT for the day. The price can only rise with more USDT getting contributed.'
                                );
                                setModalInfoOpen(true);
                            }}
                        >
                            <TimelineIcon sx={{ color: grey[300], fontSize: 18 }} />
                        </IconButton>
                    </Grid>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 18,
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 600
                        }}
                    >
                        {(dailyDonation / 200000).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}{' '}
                        <img src={usdtlogo} alt="logo" width={16} style={{ marginLeft: 5 }} />
                    </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={5.5} xs={5.9} sx={{ mt: { lg: 0, md: 0, sm: 2, xs: 2 } }}>
                    <Grid container width="auto" display="flex" alignItems="center">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 16 }}>Contributed USDT</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText('Displays the total contribution of USDT to the AlphaDAO auctions for the current day.');
                                setModalInfoOpen(true);
                            }}
                        >
                            <DataSaverOnIcon sx={{ color: grey[300], fontSize: 18 }} />
                        </IconButton>
                    </Grid>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 18,
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 600
                        }}
                    >
                        {dailyDonation.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}{' '}
                        <img src={usdtlogo} alt="logo" width={16} style={{ marginLeft: 5 }} />
                    </Typography>
                </Grid>
            </Grid>
            <PopUpInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={popText} />
        </>
    );
};

export default GlobalStatsCard;
