import Web3 from 'web3';
import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { blue, grey, red } from '@mui/material/colors';
import { useState, useEffect } from 'react';
import { brandColor } from 'themes/constants';
import { AiOutlineSwap } from 'react-icons/ai';
import { useWeb3React } from '@web3-react/core';
import { FcCandleSticks } from 'react-icons/fc';
import alxlogo from '../../../assets/alxlogo.svg';
import usdtlogo from '../../../assets/UsdtLogo.png';
import SlippageModal from 'ui-component/SlippageModal';
import { injected } from 'components/wallet/connectors';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    ALXbalanceOf,
    USDTBalanceOf,
    allowanceALXPCS,
    allowanceUSDTPCS,
    approveALXPCS,
    approveUSDTPCS,
    getReservesALX,
    swapExactTokensForTokens
} from 'components/wallet/sharesABI';
import { Grid, Typography, Button, styled, TextField, Box, IconButton, Link } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SuccessfullTransactionModal from 'ui-component/SuccessfulTransactionModal';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'right', // Align text to the right
        color: theme.palette.text.invertedsecondary,
        fontSize: 28,
        height: 45,
        width: 'auto',
        fontWeight: 700
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent'
        },
        '&:hover fieldset': {
            borderColor: 'transparent'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'right', // Ensure input text aligns right
            '&::placeholder': {
                fontSize: '16px', // Custom font size for placeholder
                color: 'rgba(0, 0, 0, 0.6)' // Custom color for placeholder
            }
        },
        '& .MuiInputLabel-outlined': {
            textAlign: 'right' // Align label to the right
        }
    }
}));

const DEXSwapCard = () => {
    const web3 = new Web3(window.ethereum);
    const { BN, toWei, fromWei } = web3.utils;
    const [USDTAllowanceBN, setUSDTAllowanceBN] = useState(new BN(0));
    const usdtaddress = '0xd389253265dd6b85C47c410EC5fF0c6A383CE949';
    const [ALXAllowanceBN, setALXAllowanceBN] = useState(new BN(0));
    const alxaddress = '0x5ca2822629D66f443db806E2F480b0310e525bBB';
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [slippageModal, setSlippageModal] = useState(false);
    const [myUSDTBalance, setMyUSDTBalance] = useState(0);
    const { active, account, activate } = useWeb3React();
    const [myALXBalance, setMyALXBalance] = useState(0);
    const [amountBN, setAmountBN] = useState(new BN(0));
    const [isHovered, setIsHovered] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [priceImpact, setPriceImpact] = useState(0);
    const [lpReserves, setLpReserves] = useState([]);
    const [isBuying, setIsBuying] = useState(false);
    const [slippage, setSlippage] = useState(0.5);
    const [amount2, setAmount2] = useState(0);
    const [amount, setAmount] = useState(0);
    const connect = async () => {
        try {
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    };

    const theme = useTheme();

    const handleSlippageChange = (newSlippage) => {
        setSlippage(newSlippage);
        console.log(`My slippage: ${newSlippage}`);
    };

    const toggleIsBuying = () => {
        setIsBuying((prevState) => !prevState);
    };

    const fetchData = async () => {
        if (account) {
            const tempUSDTBalance = await USDTBalanceOf(account);
            setMyUSDTBalance(tempUSDTBalance.toString());
            const tempUSDTAllowance = await allowanceUSDTPCS(account);
            setUSDTAllowanceBN(new BN(tempUSDTAllowance.toString()));
            const tempALXAllowance = await allowanceALXPCS(account);
            setALXAllowanceBN(new BN(tempALXAllowance));
            const tempALXBalance = await ALXbalanceOf(account);
            setMyALXBalance(tempALXBalance);
            const reserves = await getReservesALX();
            setLpReserves(reserves);
        }
    };

    const handleSwap = async () => {
        const tokenA = isBuying ? usdtaddress : alxaddress;
        const tokenB = isBuying ? alxaddress : usdtaddress;
        try {
            await swapExactTokensForTokens(amountBN.toString(), tokenA, tokenB, account, (hash) => {
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
            fetchData();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    function calculatePriceImpactChild(inputAmount, reserveIn, reserveOut) {
        const inputAmountWithFee = inputAmount * 1e18 * 0.9975; // considering 0.25% fee
        const newReserveIn = reserveIn + inputAmountWithFee;
        const newReserveOut = (reserveIn * reserveOut) / newReserveIn;
        const priceImpact = (reserveOut - newReserveOut) / reserveOut;
        return priceImpact * 100; // price impact as a percentage
    }

    const calculatePriceImpact = async (value, isBuying, lpReserves) => {
        if (isBuying) {
            const impact = calculatePriceImpactChild(parseFloat(value), parseFloat(lpReserves[1]), parseFloat(lpReserves[0]));
            return impact.toFixed(2);
        }
        const impact = calculatePriceImpactChild(parseFloat(value), parseFloat(lpReserves[0]), parseFloat(lpReserves[1]));
        return impact.toFixed(2);
    };

    const handleAmountChange = async (event) => {
        let value = event.target.value;

        // Remove any non-digit and non-decimal characters except the first dot
        value = value.replace(/[^\d.]/g, '');

        // Ensure only one decimal point is present
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }

        const alxprice = lpReserves[1] / lpReserves[0];
        const usdtprice = lpReserves[0] / lpReserves[1];

        setAmount(value); // Update the amount state with sanitized value

        if (value) {
            const amountInWei = toWei(value, 'ether');
            setAmountBN(new BN(amountInWei)); // Update amountBN state
        } else {
            setAmountBN(new BN(0)); // If input value is empty, set amountBN to 0
        }

        const impact = await calculatePriceImpact(value, isBuying, lpReserves);
        setPriceImpact(impact);

        const priceImpactValue = parseFloat(impact);
        console.log('Price Impact:', priceImpactValue);

        if (isBuying) {
            const tempValue = parseFloat(value) * usdtprice;
            const adjustedTempValue = tempValue * ((100 - priceImpactValue) / 100) - tempValue * 0.0025;
            console.log('Temp Value (Buying):', tempValue, 'Adjusted Temp Value (Buying):', adjustedTempValue);
            setAmount2(adjustedTempValue);
        } else {
            const tempValue = parseFloat(value) * alxprice;
            const adjustedTempValue = tempValue * ((100 - priceImpactValue) / 100) - tempValue * 0.0025;
            console.log('Temp Value (Selling):', tempValue, 'Adjusted Temp Value (Selling):', adjustedTempValue);
            setAmount2(adjustedTempValue);
        }
    };

    const handleAmountChange2 = (event) => {
        let value = event.target.value;

        // Remove any non-digit and non-decimal characters except the first dot
        value = value.replace(/[^\d.]/g, '');

        // Ensure only one decimal point is present
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }
        const alxprice = lpReserves[1] / lpReserves[0];
        const usdtprice = lpReserves[0] / lpReserves[1];

        setAmount2(value); // Update the amount state with sanitized value
        if (isBuying) {
            const impact = calculatePriceImpactChild(parseFloat(amount2), parseFloat(lpReserves[0]), parseFloat(lpReserves[1]));
            const tempValue = value * alxprice;
            setAmount(tempValue * ((100 + impact) / 100) + tempValue * 0.0025);
        } else {
            const impact = calculatePriceImpactChild(parseFloat(amount2), parseFloat(lpReserves[1]), parseFloat(lpReserves[0]));
            const tempValue = value * usdtprice;
            setAmount(tempValue * ((100 + impact) / 100) + tempValue * 0.0025);
        }
        calculatePriceImpact();
    };

    const handleApproveUSDT = async () => {
        try {
            await approveUSDTPCS(amountBN.toString(), (hash) => {
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
            await fetchData();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    const handleApproveALX = async () => {
        try {
            await approveALXPCS(amountBN.toString(), (hash) => {
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true);
            await fetchData();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    useEffect(() => {
        if (active && account) {
            fetchData();
        }
    }, [account, active]);

    const allowanceBN = isBuying ? USDTAllowanceBN : ALXAllowanceBN;

    return (
        <>
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item lg={4} md={5.5} xs={11.7} sm={11.7} ml={2}>
                    <Typography
                        sx={{ fontSize: { lg: 44, md: 44, sm: 44, xs: 30 }, fontWeight: 700, color: theme.palette.text.invertedprimary }}
                    >
                        Trade
                    </Typography>
                    <Typography
                        sx={{
                            color: blue[500],
                            fontSize: 14,
                            fontWeight: 500,
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <TrendingUpIcon sx={{ mr: 0.5 }} />
                        <Link
                            href="https://www.dexscreener.com"
                            color={blue[500]}
                            underline="none"
                            fontWeight={500}
                            target="_blank"
                            rel="noopener"
                        >
                            1 ALX $(
                            {(lpReserves[1] / lpReserves[0]).toLocaleString('en-US', {
                                maximumFractionDigits: 4,
                                minimumFractionDigits: 4
                            })}
                            )
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    p: 3,
                    mt: 3,
                    border: 1,
                    boxShadow: 6,
                    display: 'flex',
                    borderRadius: 2,
                    background: theme.palette.card.main,
                    width: { lg: '33.33%', md: '45.83%', sm: '97.5%', xs: '97.5%' }
                }}
            >
                <Grid container display="flex" justifyContent="space-between">
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500, fontSize: 22 }}>Swap</Typography>
                    <Grid container width="auto">
                        <Button
                            onClick={() => {
                                setSlippageModal(true);
                            }}
                            size="small"
                            startIcon={<TuneRoundedIcon sx={{ color: grey[300], ml: 1 }} />}
                            sx={{
                                borderRadius: 50,
                                fontSize: 14,
                                bgcolor: theme.palette.card.light,
                                color: grey[200],
                                pr: 1.5
                            }}
                        >
                            0.5%
                        </Button>
                        <IconButton sx={{ background: theme.palette.card.light, ml: 1 }}>
                            <FcCandleSticks sx={{ color: theme.palette.text.invertedthird }} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item width="100%" my={1}>
                    <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 500, fontSize: 16 }}>
                        Trade ALX tokens in an instant
                    </Typography>
                </Grid>
                <Grid
                    container
                    sx={{
                        px: 2,
                        pb: 3,
                        pt: 1,
                        mt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        justifyContent: 'space-between',
                        bgcolor: theme.palette.card.light
                    }}
                >
                    <Grid container width="auto">
                        <Typography sx={{ color: grey[200], fontWeight: 600, fontSize: 14 }}>From</Typography>
                    </Grid>
                    <Grid container width="auto" display="flex" alignItems="center">
                        <AccountBalanceWalletOutlinedIcon sx={{ color: brandColor, fontSize: 17 }} />
                        <Typography
                            sx={{
                                mx: 1,
                                fontSize: 16,
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color: grey[200]
                            }}
                        >
                            {isBuying
                                ? parseFloat(myUSDTBalance, 'ether').toLocaleString('en-US', {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                  })
                                : (myALXBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </Typography>
                        <Button
                            onClick={() => {
                                setAmount(isBuying ? myUSDTBalance : myALXBalance / 1e18);
                                calculatePriceImpact();
                            }}
                            sx={{
                                mx: 0.5,
                                height: 30,
                                fontSize: 14,
                                fontWeight: 600,
                                borderRadius: 2,
                                textTransform: 'none',
                                background: theme.palette.mode === 'dark' ? '#28242b' : grey[50],
                                color: theme.palette.text.invertedthird
                            }}
                        >
                            Max
                        </Button>
                        <Button
                            onClick={() => {
                                setAmount(isBuying ? myUSDTBalance / 2 : myALXBalance / 2 / 1e18);
                                calculatePriceImpact();
                            }}
                            sx={{
                                height: 30,
                                fontSize: 14,
                                fontWeight: 600,
                                borderRadius: 2,
                                textTransform: 'none',
                                background: theme.palette.mode === 'dark' ? '#28242b' : grey[50],
                                color: theme.palette.text.invertedthird
                            }}
                        >
                            50%
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor={theme.palette.background.paper}
                    p={2}
                    mt={-2}
                    borderRadius={2}
                >
                    <Grid item xs={5} lg="auto" display="flex" alignItems="center">
                        <Button
                            endIcon={<ArrowDropDownIcon sx={{ color: theme.palette.invertedthird, mr: 2 }} />}
                            sx={{
                                background: theme.palette.card.main,
                                borderRadius: 2,
                                height: 60,
                                fontWeight: 600,
                                fontSize: 20,
                                color: theme.palette.text.invertedprimary
                            }}
                        >
                            <Box
                                sx={{
                                    background: `linear-gradient(100deg, ${theme.palette.card.light}, ${theme.palette.card.alternative}, transparent)`,
                                    borderRadius: '50%',
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mx: 1
                                }}
                            >
                                <img src={isBuying ? usdtlogo : alxlogo} alt="logo" width={18} height={18} />
                            </Box>
                            {isBuying ? 'USDT' : 'ALX'}
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={7.1} mt={-3} mr={-1.5}>
                        <CustomTextField onChange={handleAmountChange} value={amount} />
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="right" alignItems="start" mt={-4} zIndex={999} mr={2.2}>
                    <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 600, fontSize: 14 }}>
                        ~$
                        {isBuying
                            ? amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                            : ((lpReserves[1] / lpReserves[0]) * amount).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                              })}
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="center" mt={-2} zIndex={999}>
                    <IconButton
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={toggleIsBuying}
                        sx={{
                            background: 'linear-gradient(to right, #f9774b, #CD3201)',
                            boxShadow: 0,
                            ':hover': {
                                boxShadow: '0px 0px 15px 5px rgba(249, 119, 75, 1)'
                            }
                        }}
                    >
                        {isHovered ? <SwapCallsIcon /> : <ArrowDownwardIcon />}
                    </IconButton>
                </Grid>
                <Grid
                    container
                    sx={{
                        px: 2,
                        pb: 3,
                        pt: 1,
                        mt: -1,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        justifyContent: 'space-between',
                        bgcolor: theme.palette.card.light
                    }}
                >
                    <Grid container width="auto">
                        <Typography sx={{ color: grey[200], fontWeight: 600, fontSize: 14 }}>To</Typography>
                    </Grid>
                    <Grid container width="auto" display="flex" alignItems="center">
                        <AccountBalanceWalletOutlinedIcon sx={{ color: brandColor, fontSize: 17 }} />
                        <Typography
                            sx={{
                                mx: 1,
                                fontSize: 16,
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color: grey[200]
                            }}
                        >
                            {isBuying
                                ? (myALXBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                                : parseFloat(myUSDTBalance, 'ether').toLocaleString('en-US', {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2
                                  })}
                        </Typography>
                        <Button
                            onClick={() => {
                                setAmount2(isBuying ? myALXBalance / 1e18 : myUSDTBalance);
                            }}
                            sx={{
                                mx: 0.5,
                                height: 30,
                                fontSize: 14,
                                fontWeight: 600,
                                borderRadius: 2,
                                textTransform: 'none',
                                color: theme.palette.text.invertedthird,
                                background: theme.palette.mode === 'dark' ? '#28242b' : grey[50]
                            }}
                        >
                            Max
                        </Button>
                        <Button
                            onClick={() => {
                                setAmount2(isBuying ? myALXBalance / 2 / 1e18 : myUSDTBalance / 2);
                            }}
                            sx={{
                                height: 30,
                                fontSize: 14,
                                fontWeight: 600,
                                borderRadius: 2,
                                textTransform: 'none',
                                color: theme.palette.text.invertedthird,
                                background: theme.palette.mode === 'dark' ? '#28242b' : grey[50]
                            }}
                        >
                            50%
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor={theme.palette.background.paper}
                    p={2}
                    mt={-2}
                    borderRadius={2}
                >
                    <Grid item xs={5} lg="auto" display="flex" alignItems="center">
                        <Button
                            endIcon={<ArrowDropDownIcon sx={{ color: theme.palette.invertedthird, mr: 2 }} />}
                            sx={{
                                background: theme.palette.card.main,
                                borderRadius: 2,
                                height: 60,
                                fontWeight: 600,
                                fontSize: 20,
                                color: theme.palette.text.invertedprimary
                            }}
                        >
                            <Box
                                sx={{
                                    background: `linear-gradient(100deg, ${theme.palette.card.light}, ${theme.palette.card.alternative}, transparent)`,
                                    borderRadius: '50%',
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mx: 1
                                }}
                            >
                                <img src={isBuying ? alxlogo : usdtlogo} alt="logo" width={18} height={18} />
                            </Box>
                            {isBuying ? 'ALX' : 'USDT'}
                        </Button>
                    </Grid>
                    <Grid item xs={6} lg={7.1} mt={-3} mr={-1.5}>
                        <CustomTextField onChange={handleAmountChange2} value={amount2} />
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="right" alignItems="start" mt={-4} zIndex={999} mr={2.2}>
                    <Typography sx={{ color: theme.palette.text.invertedfourth, fontWeight: 600, fontSize: 14 }}>
                        ~$
                        {isBuying
                            ? ((lpReserves[1] / lpReserves[0]) * amount2).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                              })
                            : amount2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" alignItems="center" mt={2} px={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>Price</Typography>
                    <Grid container width="auto" display="flex" justifyContent="right" alignItems="center">
                        <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>1 ALX</Typography>
                        <AiOutlineSwap style={{ color: theme.palette.text.invertedfourth, marginLeft: 5, marginRight: 5 }} />
                        <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>
                            {(lpReserves[1] / lpReserves[0]).toLocaleString('en-US', {
                                maximumFractionDigits: 6,
                                minimumFractionDigits: 4
                            })}{' '}
                            USDT
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" alignItems="center" mt={2} px={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>
                        Slippage Tolerance
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: 18, color: brandColor }}>{slippage}%</Typography>
                </Grid>
                {account && active ? (
                    <Grid container mt={2}>
                        {allowanceBN.gte(amountBN) ? (
                            <Button
                                onClick={() => {
                                    handleSwap();
                                }}
                                fullWidth
                                sx={{
                                    background: 'linear-gradient(to right, #f9774b, #CD3201)',
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    color: grey[200],
                                    py: 1,
                                    fontSize: 18,
                                    ':disabled': {
                                        background: grey[600],
                                        color: grey[100]
                                    }
                                }}
                            >
                                Swap
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    if (isBuying) {
                                        handleApproveUSDT();
                                    } else {
                                        handleApproveALX();
                                    }
                                }}
                                fullWidth
                                sx={{
                                    background: 'linear-gradient(to right, #f9774b, #CD3201)',
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    color: grey[200],
                                    py: 1,
                                    fontSize: 18,
                                    ':disabled': {
                                        background: grey[600],
                                        color: grey[100]
                                    }
                                }}
                            >
                                Approve
                            </Button>
                        )}
                    </Grid>
                ) : (
                    <Grid container mt={2}>
                        <Button
                            onClick={() => {
                                connect();
                            }}
                            fullWidth
                            sx={{
                                background: 'linear-gradient(to right, #f9774b, #CD3201)',
                                textTransform: 'none',
                                borderRadius: 2,
                                color: grey[200],
                                py: 1,
                                fontSize: 18,
                                ':disabled': {
                                    background: grey[600],
                                    color: grey[100]
                                }
                            }}
                        >
                            Connect wallet
                        </Button>
                    </Grid>
                )}
                {amount > 0 ? (
                    <>
                        <Grid container display="flex" justifyContent="space-between" alignItems="center" mt={2} px={2}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>
                                Minimum received
                            </Typography>
                            <Typography sx={{ fontWeight: 700, fontSize: 16, color: theme.palette.text.invertedprimary }}>
                                ~
                                {(amount2 * (100 - slippage)).toLocaleString('en-US', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}{' '}
                                {isBuying ? 'ALX' : 'USDT'}
                            </Typography>
                        </Grid>
                        <Grid container display="flex" justifyContent="space-between" alignItems="center" px={2}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>
                                Price impact
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 16,
                                    color: priceImpact > 5 ? red[600] : theme.palette.text.invertedprimary
                                }}
                            >
                                {priceImpact}%
                            </Typography>
                        </Grid>
                        <Grid container display="flex" justifyContent="space-between" alignItems="center" px={2}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.text.invertedprimary }}>
                                Trading fee
                            </Typography>
                            <Typography sx={{ fontWeight: 700, fontSize: 16, color: theme.palette.text.invertedprimary }}>
                                {(amount * 0.0025).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })}
                                {isBuying ? ' USDT' : ' ALX'}
                            </Typography>
                        </Grid>
                    </>
                ) : null}
            </Grid>
            <SlippageModal open={slippageModal} setOpen={setSlippageModal} onSlippageChange={handleSlippageChange} />
            <SuccessfullTransactionModal open={modalOpen} setOpen={setModalOpen} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default DEXSwapCard;
