import React, { useRef } from 'react';
import TopLayout from '../TopLayout';
import { useTheme } from '@mui/system';
import bnblogo from '../../../assets/bnblogo.svg';
import { blue } from '@mui/material/colors';
import { Grid, Link, Typography, Card, Box, useMediaQuery } from '@mui/material';
import { brandColor } from 'themes/constants';
import clock from '../../../assets/clock.svg';
import UpcomingPredictionCard from './UpcomingPredictionCard';
import FinishedPredictionCard from './FinishedPredictionCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import LaterPredictionCard from './LaterPredictionCard.';

const PredictionsMain = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isXLg = useMediaQuery(theme.breakpoints.down('xl'));

    let slides = 1.2;
    if (isXs) {
        slides = 1;
    } else if (isSm) {
        slides = 1;
    } else if (isMd) {
        slides = 2;
    } else if (isLg) {
        slides = 3;
    } else if (isXLg) {
        slides = 4;
    } else {
        slides = 5;
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        swipeToSlide: true,
        slidesToShow: slides,
        swipe: true,
        arrows: false,
        touchMove: true,
        initialSlide: 2,
        centerMode: true
    };
    return (
        <>
            <Grid
                container
                pt={15}
                bgcolor={theme.palette.mode === 'dark' ? '#191818' : 'transparent'}
                borderBottom={1}
                pb={8}
                display="flex"
                sx={{ justifyContent: { lg: 'space-evenly', md: 'space-evenly', sm: 'center', xs: 'center' } }}
            >
                <Grid item lg={4} md={7} sm={9} xs={11.5}>
                    <TopLayout
                        title="Predictions"
                        text="Predict whether the price of BNB will rise or fall and earn rewards based on your accuracy. The ROI for rewards depends on the distribution of user bets."
                    />
                </Grid>
                <Grid
                    item
                    lg={3.3}
                    md={4}
                    sm={8}
                    flexDirection="column"
                    sx={{ justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }, mt: 4 }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 14,
                            fontWeight: 500,
                            textAlign: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        Looking for a partnership?
                    </Typography>
                    <Typography
                        sx={{
                            color: blue[500],
                            fontSize: 14,
                            fontWeight: 500,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        <Link
                            href="https://www.t.me/alphadao_token"
                            color={blue[500]}
                            underline="none"
                            fontWeight={500}
                            target="_blank"
                            rel="noopener"
                        >
                            Apply here
                        </Link>
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedprimary,
                            fontSize: 14,
                            mt: 2,
                            fontWeight: 500,
                            textAlign: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        Need help?
                    </Typography>
                    <Typography
                        sx={{
                            color: blue[500],
                            fontSize: 14,
                            fontWeight: 500,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: { lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                        }}
                    >
                        <Link
                            href="https://www.t.me/alphadao_token"
                            color={blue[500]}
                            underline="none"
                            fontWeight={500}
                            target="_blank"
                            rel="noopener"
                        >
                            Join our Telegram
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container display="flex" justifyContent="center" alignItems="center" my={5}>
                <Grid item display="flex" justifyContent="space-between" flexDirection="row" md={11.5} sm={9} xs={11.7} lg={8.9}>
                    <Grid item display="flex" flexDirection="row" alignItems="center">
                        <Grid item mr={-2} display="flex" alignItems="center" zIndex={999}>
                            <img src={bnblogo} alt="logo" width={55} />
                        </Grid>
                        <Card
                            sx={{
                                background: theme.palette.card.main,
                                border: 1,
                                px: 2,
                                py: 1,
                                alignItems: 'center',
                                borderTopRightRadius: 10,
                                borderBottomRightRadius: 10,
                                display: 'flex'
                            }}
                        >
                            <Typography
                                sx={{ color: brandColor, fontWeight: 700, mr: 3, ml: 0.5, fontSize: { lg: 16, md: 16, sm: 14, xs: 12 } }}
                            >
                                BNBUSDT
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.text.invertedthird,
                                    fontSize: { lg: 14, md: 14, sm: 12, xs: 12 },
                                    fontWeight: 700
                                }}
                            >
                                $579.8402
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item display="flex" flexDirection="row" alignItems="center">
                        <Card
                            sx={{
                                background: theme.palette.card.main,
                                border: 1,
                                py: 1,
                                pl: 1,
                                pr: 3,
                                alignItems: 'center',
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                display: 'flex'
                            }}
                        >
                            <Typography sx={{ color: brandColor, fontWeight: 700, mr: 0.75, fontSize: { lg: 16, md: 16, sm: 14, xs: 12 } }}>
                                03:28
                            </Typography>
                            <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 12, fontWeight: 700 }}>5m</Typography>
                        </Card>
                        <Grid item ml={-2.5} display="flex" alignItems="center" zIndex={999}>
                            <img src={clock} alt="logo" width={55} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%' }}>
                <Slider {...settings}>
                    <Grid item>
                        <FinishedPredictionCard />
                    </Grid>
                    <Grid item>
                        <FinishedPredictionCard />
                    </Grid>
                    <Grid item>
                        <UpcomingPredictionCard />
                    </Grid>
                    <Grid item>
                        <LaterPredictionCard />
                    </Grid>
                    <Grid item>
                        <LaterPredictionCard />
                    </Grid>
                </Slider>
            </Box>
        </>
    );
};

export default PredictionsMain;
