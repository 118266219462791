// material-ui
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typography, Modal, Box, Grid, IconButton, TextField } from '@mui/material';
import BrandButton from './BrandButton';
import styled from '@emotion/styled';
import { useState } from 'react';
import { brandColor } from 'themes/constants';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: '#433E49',
        borderRadius: 50,
        border: 'none',
        textAlign: 'right', // Align text to the right
        color: grey[200],
        fontSize: 15,
        height: 35,
        width: 70,
        fontWeight: 500
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent'
        },
        '&:hover fieldset': {
            borderColor: 'transparent'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'right', // Ensure input text aligns right
            '&::placeholder': {
                fontSize: '16px', // Custom font size for placeholder
                color: 'rgba(0, 0, 0, 0.6)' // Custom color for placeholder
            }
        },
        '& .MuiInputLabel-outlined': {
            textAlign: 'right' // Align label to the right
        }
    }
});

const SlippageModal = ({ open, setOpen, onSlippageChange }) => {
    const theme = useTheme();
    const [selectedButton, setSelectedButton] = useState(1);
    const [slippage, setSlippage] = useState(0.5);
    const handleClose = () => setOpen(false);
    const handleSlippageSelect = (value, buttonIndex) => {
        setSlippage(value);
        setSelectedButton(buttonIndex);
        onSlippageChange(value); // Pass the new slippage value to the parent
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: 450, md: 450, lg: 450 },
                    backdropFilter: 'blur(10px)',
                    background: theme.palette.mode === 'dark' ? '#28242b' : grey[50],
                    border: 0,
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: 20, fontWeight: 600, color: theme.palette.text.invertedsecondary }}>
                        Slippage Tolerance
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseRoundedIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                    </IconButton>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 5 }}>
                    <Grid item lg={5.4} md={5.4} xs={6} sm={5.4} display="flex" justifyContent="space-between">
                        <IconButton
                            onClick={() => {
                                handleSlippageSelect(0.1, 0);
                            }}
                            size="small"
                            sx={{
                                py: 1,
                                px: 1.5,
                                fontSize: 14,
                                borderRadius: 50,
                                bgcolor: theme.palette.card.light,
                                border: selectedButton === 0 ? 1 : 0,
                                borderColor: brandColor,
                                color: grey[300]
                            }}
                        >
                            0.1%
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                handleSlippageSelect(0.5, 1);
                            }}
                            size="small"
                            sx={{
                                py: 1,
                                px: 1.5,
                                fontSize: 14,
                                borderRadius: 50,
                                bgcolor: theme.palette.card.light,
                                border: selectedButton === 1 ? 1 : 0,
                                borderColor: brandColor,
                                color: grey[300]
                            }}
                        >
                            0.5%
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                handleSlippageSelect(1, 2);
                            }}
                            size="small"
                            sx={{
                                py: 1,
                                px: 1.5,
                                fontSize: 14,
                                borderRadius: 50,
                                bgcolor: theme.palette.card.light,
                                border: selectedButton === 2 ? 1 : 0,
                                borderColor: brandColor,
                                color: grey[300]
                            }}
                        >
                            1%
                        </IconButton>
                    </Grid>
                    <Grid item width="50%" display="flex" justifyContent="right" alignItems="center">
                        <Typography sx={{ fontSize: 13, fontWeight: 500, color: theme.palette.text.invertedthird, mr: 1 }}>
                            Custom
                        </Typography>
                        <CustomTextField value={slippage} />
                        <Typography sx={{ fontSize: 14, fontWeight: 500, color: theme.palette.text.invertedthird, ml: 1 }}>%</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <BrandButton
                        _width="100%"
                        text="Save"
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{ fontSize: 16 }}
                    />
                </Grid>
            </Box>
        </Modal>
    );
};

SlippageModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default SlippageModal;
