// material-ui
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import TopLayout from '../TopLayout';
import GovernanceActiveCard from './GovernanceActiveCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Governance = () => {
    const theme = useTheme();
    return (
        <>
            <TopLayout
                title="Governance"
                text="AlphaDAO is a fully decentralized, community governed protocol by the ALX token-stakers. Stakers collectively
                    discuss and vote on upgrades to the protocol. To learn more check out the governance documentation."
            />
            <Grid
                container
                sx={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    mt: -3,
                    backgroundColor: theme.palette.background.default
                }}
            >
                <Grid
                    item
                    lg={4}
                    xs={11.7}
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.025)', borderRadius: 2 }}
                >
                    <Accordion sx={{ bgcolor: 'transparent', width: '100%', zIndex: 999, p: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 18 }}>
                                How does AlphaDAO governance work?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: theme.palette.text.invertedthird, pb: 4, px: 4, fontSize: 17 }}>
                            AlphaDAO Governance enables users to modify platform fees and ALX token transfer fees, as well as vote on
                            strategic buybacks, burns and liquidity.
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid container display="flex" justifyContent="center">
                    <GovernanceActiveCard
                        currentRound="4"
                        title="Adjust the fees of AlphaDAO Loans platform"
                        deadline="13.04.2024"
                        currentFee="2"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Governance;
