import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import PropTypes from 'prop-types';
import usdtlogo from '../../assets/UsdtLogo.png';
import sollogo from '../../assets/sollogo.svg';
import usdclogo from '../../assets/usdcsvg.svg';
import wethlogo from '../../assets/ethlogo.png';
import busdlogo from '../../assets/busdtransparent.svg';
import btclogo from '../../assets/bitcoinsvg.svg';
import CloseIcon from '@mui/icons-material/Close';

export default function FilterERC20PaymentToken({ isOpen, onClose, onTokenSelect }) {
    const theme = useTheme();
    const handlePaymentTokenChange = (token) => {
        if (onTokenSelect) {
            onTokenSelect(token); // Pass the isChecked value back to the parent component
        }
    };
    const [paymentTokens] = useState([
        {
            address: '0xd389253265dd6b85C47c410EC5fF0c6A383CE949',
            name: 'Tether (USDT)',
            symbol: 'USDT',
            logoURI: usdtlogo
        },
        {
            address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B0',
            name: 'Solana',
            symbol: 'SOL',
            logoURI: sollogo
        },
        {
            address: '0xd389253265dd6b85C47c410EC5fF0c6A383CE94B',
            name: 'Circle USD - Stable coin',
            symbol: 'USDC',
            logoURI: usdclogo
        },
        {
            address: '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
            name: 'Ethereum',
            symbol: 'WETH',
            logoURI: wethlogo
        },
        {
            address: '0xd389253265dd6b85C47c410EC5fF0c6A383CE94A',
            name: 'Binance USD - Stable coin',
            symbol: 'BUSD',
            logoURI: busdlogo
        },
        {
            address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
            name: 'Bitcoin',
            symbol: 'BTCB',
            logoURI: btclogo
        }
    ]);

    const list = () => (
        <List>
            {paymentTokens.map((token) => (
                <ListItem
                    key={token.address}
                    sx={{ bgcolor: 'transparent', ':hover': { bgcolor: theme.palette.card.alternative } }}
                    onClick={() => {
                        handlePaymentTokenChange(token);
                        onClose();
                    }}
                >
                    <ListItemAvatar>
                        <Avatar sx={{ borderRadius: '50%' }} src={token.logoURI} alt={token.name} />
                    </ListItemAvatar>
                    <Grid item>
                        <Grid container>
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500 }}>{token.symbol}</Typography>
                        </Grid>
                        <Typography sx={{ color: theme.palette.text.invertedfourth }}>{token.name}</Typography>
                    </Grid>
                </ListItem>
            ))}
        </List>
    );

    return (
        <div>
            <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
                <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    p={2}
                    mb={2}
                    borderBottom={1}
                    borderColor={theme.palette.text.invertedfourth}
                >
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 24, fontWeight: 600 }}>
                        Select a payment token
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: theme.palette.text.invertedprimary }} />
                    </IconButton>
                </Grid>
                <Box
                    sx={{
                        bgcolor: theme.palette.card.main
                    }}
                >
                    {list()}
                </Box>
            </Drawer>
        </div>
    );
}

FilterERC20PaymentToken.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onTokenSelect: PropTypes.func.isRequired
};
