import { grey } from '@mui/material/colors';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import CircleIcon from '@mui/icons-material/Circle';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { brandColor } from 'themes/constants';

// Generate data for each day from Day 0 to Day 100
const data = Array.from({ length: 101 }, (v, i) => ({
    name: `Day ${i}`,
    pv: 15 + i * i * 2 // Quadratic growth
}));

const formatYAxisTick = (value) => {
    // Convert values to 'K' for thousands, 'M' for millions
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}K`;
    }
    return value;
};

const LeaseGraph = () => {
    const theme = useTheme();

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const formattedValue = new Intl.NumberFormat('en-US').format(payload[0].value);
            return (
                <Box sx={{ p: 1, background: theme.palette.mode === 'dark' ? '#28242b' : grey[50] }}>
                    <Typography variant="subtitle2">Total amount of lease offers:</Typography>
                    <Typography variant="body2">{formattedValue}</Typography>
                </Box>
            );
        }

        return null;
    };
    return (
        <>
            <ResponsiveContainer width="100%" height={280}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        bottom: 5
                    }}
                >
                    <defs>
                        <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={brandColor} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={brandColor} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <Legend iconSize={0} align="left" verticalAlign="top" />
                    <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} stroke={grey[500]} vertical={false} fill="transparent" />
                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        dataKey="name"
                        interval={-1}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        domain={[0, 10000]}
                        width={65}
                        interval={0}
                        tickFormatter={formatYAxisTick}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        name={
                            <Grid container alignItems="center" mb={5}>
                                <CircleIcon sx={{ color: brandColor, fontSize: 7 }} />
                                <Typography sx={{ color: theme.palette.text.invertedfourth, pl: 1 }}>All-time offers</Typography>
                            </Grid>
                        }
                        dataKey="pv"
                        stroke={brandColor}
                        fill="url(#colorPrice)"
                        fillOpacity={1}
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 8 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default LeaseGraph;
