import { useTheme } from '@emotion/react';
import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { brandColor } from 'themes/constants';

const formatValue = (value) => {
    // Convert the input to a number
    const num = Number(value);
    // Format the number based on its size
    if (num >= 1000 && num < 1000000) {
        return `${(num / 1000).toFixed(2)}K`; // Thousands
    }
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(2)}M`; // Millions
    }
    return value; // Return the original value for numbers less than 1000
};

const renderActiveShape = (props, theme) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 7;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={-6} fontWeight={700} textAnchor="middle" fill={theme.palette.text.invertedsecondary}>
                {payload.name}
            </text>
            <text x={cx} y={cy} dy={18} fontSize={16} fontWeight={700} textAnchor="middle" fill={theme.palette.text.invertedthird}>
                {formatValue(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ALX
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} fontSize={16} textAnchor={textAnchor} fill={theme.palette.text.invertedsecondary}>
                {formatValue(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ALX
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={18} fontSize={12} textAnchor={textAnchor} fill="#999">
                {`(${payload.name} ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const Example = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const theme = useTheme();

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width="100%" height="100%">
                <Pie
                    activeIndex={activeIndex}
                    activeShape={(props) => renderActiveShape(props, theme)}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill={brandColor}
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default Example;
