// material-ui
import { Grid, IconButton, Typography } from '@mui/material';
import { lighten, useTheme } from '@mui/system';
import usdtlogo from '../../../assets/UsdtLogo.png';
import { useWeb3React } from '@web3-react/core';
import alxlogo from '../../../assets/alxlogo.svg';
import { green, grey, red } from '@mui/material/colors';
import BrandButton from 'ui-component/BrandButton';
import { brandColor } from 'themes/constants';
import InfoIcon from '@mui/icons-material/Info';
import { useState, useEffect } from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import { donatorTotalALXReceived, isHPDeligibleAddr, originalDonation } from 'components/wallet/sharesABI';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';

const MyStatisticsCard = ({ success }) => {
    const theme = useTheme();
    const [popText, setPopText] = useState('');
    const { active, account } = useWeb3React();
    const [modalInfoOpen, setModalInfoOpen] = useState(false);
    const [donation, setDonation] = useState(0);
    const [ALXReceived, setALXReceived] = useState(0);
    const [isHPDEligible, setIsHPDEligible] = useState(false);

    const fetchData = async () => {
        if (account) {
            const tempDonation = await originalDonation(account);
            setDonation(tempDonation);
            const tempALXReceived = await donatorTotalALXReceived(account);
            setALXReceived(tempALXReceived);
            const bool = await isHPDeligibleAddr(account);
            setIsHPDEligible(bool);
        }
    };

    useEffect(() => {
        fetchData();
        if (success) {
            fetchData();
        }
    }, [account, active, donation, ALXReceived, isHPDEligible, success]);

    return (
        <>
            <Grid
                container
                sx={{
                    py: 4,
                    boxShadow: 6,
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: { lg: 8, md: 4, xs: 2, sm: 2 },
                    mt: { lg: 0, md: 0, xs: 2, sm: 2 },
                    background: theme.palette.card.main,
                    width: { lg: '49.166%', md: '49.166%', sm: '97.5%', xs: '97.5%' }
                }}
            >
                <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 26, fontWeight: 600 }}>My statistics</Typography>
                <Grid container display="flex" justifyContent="space-between" mt={2}>
                    <Grid container width="auto">
                        <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 16 }}>USDT contributed</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText('The total amount of USDT that you have contributed to the AlphaDAO Auctions.');
                                setModalInfoOpen(true);
                            }}
                        >
                            <InfoIcon sx={{ fontSize: 15, color: grey[400] }} />
                        </IconButton>
                    </Grid>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedsecondary,
                            fontSize: 16,
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {donation.toLocaleString('en-us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        <img src={usdtlogo} alt="logo" width={16} height={16} style={{ marginLeft: 5 }} />
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" mt={2}>
                    <Grid container width="auto">
                        <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 16 }}>ALX received</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText('The total amount of ALX tokens that you have received from the AlphaDAO Auctions since day 0.');
                                setModalInfoOpen(true);
                            }}
                        >
                            <InfoIcon sx={{ fontSize: 15, color: grey[400] }} />
                        </IconButton>
                    </Grid>
                    <Typography
                        sx={{
                            color: theme.palette.text.invertedsecondary,
                            fontSize: 16,
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {ALXReceived.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        <img src={alxlogo} alt="logo" width={16} height={16} style={{ marginLeft: 5 }} />
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" mt={2}>
                    <Grid container width="auto">
                        <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 16 }}>HPD eligibility</Typography>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setPopText(
                                    'It shows if you are eligible for a HappyPayDay. At least one auction participation is required in order to be eligible.'
                                );
                                setModalInfoOpen(true);
                            }}
                        >
                            <InfoIcon sx={{ fontSize: 15, color: grey[400] }} />
                        </IconButton>
                    </Grid>
                    {isHPDEligible ? (
                        <CheckBoxIcon sx={{ color: green[400] }} />
                    ) : (
                        <DisabledByDefaultRoundedIcon sx={{ color: red[500] }} />
                    )}
                </Grid>
                <Grid container display="flex" sx={{ background: lighten(theme.palette.card.main, 0.1), borderRadius: 2 }} my={2} p={3}>
                    <Typography sx={{ color: grey[100], fontWeight: 500 }}>
                        A wallet address can receive up to 100% of their total contributed USDT as a HappyPayDay.
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between">
                    <BrandButton
                        text="Learn more"
                        _width="100%"
                        startIcon={<FeedIcon />}
                        sx={{ fontSize: 16, height: 50, fontWeight: 600 }}
                    />
                </Grid>
            </Grid>
            <PopUpInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={popText} />
        </>
    );
};

export default MyStatisticsCard;
