import { useEffect } from 'react';
import TopLayout from '../TopLayout';
import LeaseStats from './LeaseStats';
import { useTheme } from '@mui/system';
import SupplyStats from './SupplyStats';
import { Grid, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import AuctionsStats from './AuctionsStats';
import LendBorrowStats from './LendBorrowStats';

const AnalyticsMain = () => {
    const theme = useTheme();
    useEffect(() => {
        document.title = 'Analytics | AlphaDAO';
    });
    return (
        <>
            <TopLayout
                title="On-chain analytics and data for AlphaDAO."
                text="AlphaDAO offers essential on-chain analytics, including token supply, liquidity data, and key metrics for leasing, lending, and borrowing platforms."
            />
            <Box
                sx={{
                    width: '100vw',
                    zIndex: 999,
                    bgcolor: theme.palette.background.default,
                    color: grey[50],
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        p: 2,
                        borderRadius: 2,
                        justifyContent: 'space-between',
                        mb: 20,
                        mt: 10,
                        background: theme.palette.card.main,
                        boxShadow: 6,
                        width: { lg: '81.25%', xs: '97.5%' }
                    }}
                >
                    <SupplyStats />
                    <AuctionsStats />
                    <LeaseStats />
                    <LendBorrowStats />
                </Grid>
            </Box>
        </>
    );
};

export default AnalyticsMain;
