import { lighten, useTheme } from '@mui/system';
import LeasesMyInfo from './LeasesMyInfo';
import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import BrandButton from 'ui-component/BrandButton';
import LeaseOpenOfferCard from './LeaseOpenOfferCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Collapse, Grid, IconButton, Typography } from '@mui/material';
import { anyTokenSymbol, getLeaseById, getLeaseByLessee, getOfferById, getOfferByLessor } from 'components/wallet/sharesABI';

const LeaseMyCard = () => {
    const theme = useTheme();
    const [showActive, setShowActive] = useState(true);
    const { active, account } = useWeb3React();
    const [leaseDetails, setLeaseDetails] = useState([]);
    const [offerDetails, setOfferDetails] = useState([]);
    const [checked, setChecked] = useState(true);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);
    const [amountOfOffersByUser, setAmountOfOffersByUser] = useState([]);
    const handleChecked = () => {
        setChecked((prev) => !prev);
    };
    const handleChecked2 = () => {
        setChecked2((prev) => !prev);
    };
    const handleChecked3 = () => {
        setChecked3((prev) => !prev);
    };

    const leasesAsLessor = leaseDetails.filter(
        (detail) => detail.lessor === account && detail.offer.installments !== detail.installmentsPaid
    );
    const leasesAsLessee = leaseDetails.filter(
        (detail) => detail.lessee === account && detail.offer.installments !== detail.installmentsPaid
    );
    const finishedLeases = leaseDetails.filter((detail) => detail.offer.installments === detail.installmentsPaid);
    const fetchOffersIds = async () => {
        if (account && active) {
            const fetchoffers = await getOfferByLessor(account);
            setAmountOfOffersByUser(fetchoffers);
            console.log(fetchoffers);
        }
    };

    const fetchLeaseDetails = async () => {
        if (account && active) {
            const leaseIds = await getLeaseByLessee(account);
            const fetchedLeases = await Promise.all(
                leaseIds.map(async (id) => {
                    const lease = await getLeaseById(id);
                    const offer = await getOfferById(lease.offerId);
                    const tokenASymbol = (await anyTokenSymbol(offer.tokenA)).toUpperCase();
                    const tokenBSymbol = (await anyTokenSymbol(offer.tokenB)).toUpperCase();
                    const addressA = offer.tokenA;
                    const addressB = offer.tokenB;
                    return {
                        ...lease,
                        leaseId: id, // Add the leaseId here
                        offer,
                        tokenASymbol,
                        tokenBSymbol,
                        installments: offer.installments,
                        offset: offer.offset,
                        reservedPrice: offer.reservedPrice,
                        lessor: offer.lessor,
                        lessee: lease.lessee,
                        tokenA: addressA,
                        tokenB: addressB,
                        installmentsPaid: lease.installmentsPaid
                    };
                })
            );
            setLeaseDetails(fetchedLeases);
        }
    };

    const fetchOfferDetails = async () => {
        if (account && active && amountOfOffersByUser !== null) {
            const fetchedOffers = await Promise.all(
                amountOfOffersByUser.map(async (id) => {
                    const offer = await getOfferById(id);
                    const tokenASymbol = (await anyTokenSymbol(offer.tokenA)).toUpperCase();
                    const tokenBSymbol = (await anyTokenSymbol(offer.tokenB)).toUpperCase();
                    return { offer, tokenASymbol, tokenBSymbol, uniqueId: id };
                })
            );
            setOfferDetails(fetchedOffers);
        }
    };
    useEffect(() => {
        fetchOffersIds();
    }, [account, active]);

    useEffect(() => {
        fetchOfferDetails();
        fetchLeaseDetails();
    }, [amountOfOffersByUser]);
    return (
        <>
            <Grid
                item
                width="100%"
                sx={{
                    p: 2,
                    border: 1,
                    borderRadius: 2,
                    background: theme.palette.card.main,
                    borderColor: theme.palette.borderColor,
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)'
                }}
            >
                <Grid item justifyContent="left">
                    <Typography color={theme.palette.text.invertedthird} fontSize={17} fontWeight={400} letterSpacing="0.5px">
                        My {showActive ? 'leases' : 'offers'}
                    </Typography>
                    <Grid container mt={1} mb={1}>
                        <BrandButton
                            text={`My leases(${leaseDetails.length})`}
                            onClick={() => {
                                setShowActive(true);
                            }}
                        />
                        <BrandButton
                            onClick={() => {
                                setShowActive(false);
                            }}
                            text={`My lease offers(${amountOfOffersByUser.length})`}
                            sx={{ ml: 1 }}
                        />
                    </Grid>
                </Grid>
                {showActive ? (
                    <>
                        {account && active && leasesAsLessor.length > 0 && (
                            <>
                                <Grid
                                    container
                                    mt={2}
                                    py={1}
                                    px={2}
                                    display="flex"
                                    borderRadius={2}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        background: checked ? 'transparent' : theme.palette.borderColor,
                                        ':hover': { cursor: 'pointer' }
                                    }}
                                    onClick={() => {
                                        handleChecked();
                                    }}
                                >
                                    <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 17, fontWeight: 600 }}>
                                        I am lending ({leasesAsLessor.length})
                                    </Typography>
                                    <IconButton>{checked ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                                </Grid>
                                <Collapse in={checked}>
                                    <Grid
                                        container
                                        sx={{
                                            border: 1,
                                            display: 'flex',
                                            overflow: 'auto',
                                            borderTopLeftRadius: 5,
                                            borderTopRightRadius: 5,
                                            bgcolor: theme.palette.card.main,
                                            borderColor: theme.palette.borderColor
                                        }}
                                    >
                                        <Grid container py={2} sx={{ minWidth: 1350 }}>
                                            <Grid container width="11%" alignItems="center" display="flex" pl={2}>
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 700, fontSize: 13 }}>
                                                    Size
                                                </Typography>
                                            </Grid>
                                            <Grid container width="19%" alignItems="center" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 700, fontSize: 13 }}>
                                                    Payments
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Duration
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Next payment
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Payment size
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Reserved price
                                                </Typography>
                                            </Grid>
                                            <Grid container width="5%" alignItems="center" display="flex" />
                                        </Grid>
                                        {leasesAsLessor.map((detail, index) => (
                                            <Grid item lg={12} key={index}>
                                                <LeasesMyInfo
                                                    id={detail.leaseId}
                                                    tokenA={detail.tokenASymbol}
                                                    tokenB={detail.tokenBSymbol}
                                                    addressA={detail.tokenA}
                                                    addressB={detail.tokenB}
                                                    lessor={detail.lessor}
                                                    lessee={detail.lessee}
                                                    amountA={detail.offer.amountA}
                                                    amountTaken={detail.amountTaken}
                                                    offset={detail.offset}
                                                    reservedPrice={detail.reservedPrice}
                                                    installments={detail.installments}
                                                    installmentsPaid={detail.installmentsPaid}
                                                    createdLeaseTime={detail.createdLeaseTime}
                                                    totalPaidInTokenB={detail.totalPaidInTokenB}
                                                    onPayment={() => {
                                                        fetchOffersIds();
                                                        fetchLeaseDetails();
                                                        fetchOfferDetails();
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Collapse>
                            </>
                        )}
                        {account && active && leasesAsLessee.length > 0 && (
                            <>
                                <Grid
                                    container
                                    mt={2}
                                    py={1}
                                    px={2}
                                    border={1}
                                    display="flex"
                                    borderRadius={2}
                                    alignItems="center"
                                    borderColor="#353951"
                                    justifyContent="space-between"
                                    sx={{
                                        background: 'transparent',
                                        ':hover': { cursor: 'pointer', borderColor: lighten('#353951', 0.3) }
                                    }}
                                    onClick={() => {
                                        handleChecked2();
                                    }}
                                >
                                    <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 17, fontWeight: 600 }}>
                                        I am leasing ({leasesAsLessee.length})
                                    </Typography>
                                    <IconButton>{checked2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                                </Grid>
                                <Collapse in={checked2}>
                                    <Grid
                                        container
                                        sx={{
                                            border: 1,
                                            display: 'flex',
                                            overflow: 'auto',
                                            borderTopLeftRadius: 5,
                                            borderTopRightRadius: 5,
                                            justifyContent: 'space-between',
                                            bgcolor: theme.palette.card.main,
                                            borderColor: theme.palette.borderColor
                                        }}
                                    >
                                        <Grid container py={2} sx={{ minWidth: 1350 }}>
                                            <Grid container width="11%" alignItems="center" display="flex" pl={2}>
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 700, fontSize: 13 }}>
                                                    Size
                                                </Typography>
                                            </Grid>
                                            <Grid container width="19%" alignItems="center" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 700, fontSize: 13 }}>
                                                    Payments
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Duration
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Next payment
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Payment size
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Reserved price
                                                </Typography>
                                            </Grid>
                                            <Grid container width="5%" alignItems="center" display="flex" />
                                        </Grid>
                                        {leasesAsLessee.map((detail, index) => (
                                            <Grid item lg={12} key={index}>
                                                <LeasesMyInfo
                                                    id={detail.leaseId}
                                                    tokenA={detail.tokenASymbol}
                                                    tokenB={detail.tokenBSymbol}
                                                    addressA={detail.tokenA}
                                                    addressB={detail.tokenB}
                                                    lessor={detail.lessor}
                                                    lessee={detail.lessee}
                                                    amountA={detail.offer.amountA}
                                                    amountTaken={detail.amountTaken}
                                                    offset={detail.offset}
                                                    reservedPrice={detail.reservedPrice}
                                                    installments={detail.installments}
                                                    installmentsPaid={detail.installmentsPaid}
                                                    createdLeaseTime={detail.createdLeaseTime}
                                                    totalPaidInTokenB={detail.totalPaidInTokenB}
                                                    onPayment={() => {
                                                        fetchOffersIds();
                                                        fetchLeaseDetails();
                                                        fetchOfferDetails();
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Collapse>
                            </>
                        )}
                        {account && active && finishedLeases.length > 0 && (
                            <>
                                <Grid
                                    container
                                    mt={2}
                                    py={1}
                                    px={2}
                                    border={1}
                                    display="flex"
                                    borderRadius={2}
                                    alignItems="center"
                                    borderColor="#353951"
                                    justifyContent="space-between"
                                    sx={{
                                        background: 'transparent',
                                        ':hover': { cursor: 'pointer', borderColor: lighten('#353951', 0.3) }
                                    }}
                                    onClick={() => {
                                        handleChecked3();
                                    }}
                                >
                                    <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 17, fontWeight: 600 }}>
                                        My terminated leases ({finishedLeases.length})
                                    </Typography>
                                    <IconButton>{checked3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                                </Grid>
                                <Collapse in={checked3}>
                                    <Grid
                                        container
                                        sx={{
                                            border: 1,
                                            display: 'flex',
                                            overflow: 'auto',
                                            borderTopLeftRadius: 5,
                                            borderTopRightRadius: 5,
                                            justifyContent: 'space-between',
                                            bgcolor: theme.palette.card.main,
                                            borderColor: theme.palette.borderColor
                                        }}
                                    >
                                        <Grid container py={2} sx={{ minWidth: 1350 }}>
                                            <Grid container width="11%" alignItems="center" display="flex" pl={2}>
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 700, fontSize: 13 }}>
                                                    Size
                                                </Typography>
                                            </Grid>
                                            <Grid container width="19%" alignItems="center" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 700, fontSize: 13 }}>
                                                    Payments
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Duration
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex" justifyContent="left">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Next payment
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Payment size
                                                </Typography>
                                            </Grid>
                                            <Grid container width="15%" alignItems="center" display="flex">
                                                <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 13, fontWeight: 700 }}>
                                                    Reserved price
                                                </Typography>
                                            </Grid>
                                            <Grid container width="5%" alignItems="center" display="flex" />
                                        </Grid>
                                        {finishedLeases.map((detail, index) => (
                                            <Grid item lg={12} key={index}>
                                                <LeasesMyInfo
                                                    id={detail.leaseId}
                                                    tokenA={detail.tokenASymbol}
                                                    tokenB={detail.tokenBSymbol}
                                                    addressA={detail.tokenA}
                                                    addressB={detail.tokenB}
                                                    lessor={detail.lessor}
                                                    lessee={detail.lessee}
                                                    amountA={detail.offer.amountA}
                                                    amountTaken={detail.amountTaken}
                                                    offset={detail.offset}
                                                    reservedPrice={detail.reservedPrice}
                                                    installments={detail.installments}
                                                    installmentsPaid={detail.installmentsPaid}
                                                    createdLeaseTime={detail.createdLeaseTime}
                                                    totalPaidInTokenB={detail.totalPaidInTokenB}
                                                    onPayment={() => {
                                                        fetchOffersIds();
                                                        fetchLeaseDetails();
                                                        fetchOfferDetails();
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Collapse>
                            </>
                        )}
                    </>
                ) : (
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {offerDetails.map((detail, index) => (
                            <Grid item lg={12} xs={12} key={index}>
                                <LeaseOpenOfferCard
                                    uniqueId={detail.uniqueId}
                                    tokenA={detail.tokenASymbol}
                                    tokenB={detail.tokenBSymbol}
                                    addressA={detail.offer[1]}
                                    addressB={detail.offer[2]}
                                    originalSize={detail.offer[3]}
                                    amountA={detail.offer[4]}
                                    reservedPrice={detail.offer[5]}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default LeaseMyCard;
