import { Progress } from 'antd';
import { useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { brandColor } from 'themes/constants';
import { useWeb3React } from '@web3-react/core';
import InfoIcon from '@mui/icons-material/Info';
import TokenSupplyGraph from './TokenSupplyGraph';
import alxlogo from '../../../assets/alxlogo.svg';
import usdtlogo from '../../../assets/UsdtLogo.png';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import { Grid, Typography, Box, LinearProgress, IconButton, Button } from '@mui/material';
import { getReservesALX, totalALXCirculating, totalALXburnt } from 'components/wallet/sharesABI';

const formatValue = (value) => {
    // Convert the input to a number
    const num = Number(value);
    // Format the number based on its size
    if (num >= 1000 && num < 1000000) {
        return `${(num / 1000).toFixed(2)}K`; // Thousands
    }
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(2)}M`; // Millions
    }
    return value; // Return the original value for numbers less than 1000
};

const SupplyStats = () => {
    const theme = useTheme();
    const { account } = useWeb3React();
    const [popText, setPopText] = useState('');
    const [lpReserves, setLpReserves] = useState([]);
    const [alphaBurnt, setAlphaBurnt] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [alphaCirculating, setAlphaCirculating] = useState(0);
    const data = [
        { name: 'Holders', value: alphaCirculating - alphaBurnt - lpReserves[0] / 1e18 },
        { name: 'Burnt', value: alphaBurnt },
        { name: 'DEX', value: lpReserves[0] / 1e18 }
    ];

    useEffect(() => {
        const fetchTotalBurnt = async () => {
            const balance = await totalALXburnt();
            setAlphaBurnt(balance);
        };

        const fetchCirculatingALX = async () => {
            const balance = await totalALXCirculating();
            setAlphaCirculating(balance);
        };

        const fetchGetReserves = async () => {
            const reserves = await getReservesALX();
            setLpReserves(reserves);
        };

        fetchTotalBurnt();
        fetchGetReserves();
        fetchCirculatingALX();
    }, [account]);
    return (
        <>
            <Grid container lg={6} md={6} sx={{ p: { lg: 5, md: 5, sm: 0, xs: 0 } }}>
                <Grid container width="100%">
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500, fontSize: 20 }}>
                        On-chain analysis & statistics
                    </Typography>
                </Grid>
                <Grid container width="100%" mt={4}>
                    <Typography sx={{ color: theme.palette.text.invertedthird, fontWeight: 600, fontSize: 18 }}>Supply info</Typography>
                </Grid>
                <Grid container width="100%" mt={4} alignItems="center" display="flex">
                    <Grid item lg="auto" xs="auto" mt={1}>
                        <Progress
                            trailColor={theme.palette.mode === 'dark' ? grey[700] : grey[400]}
                            strokeColor={brandColor}
                            strokeWidth={7}
                            size={100}
                            type="circle"
                            percent={(((alphaCirculating - alphaBurnt) / 100000000) * 100).toFixed(2)}
                            format={(percent) => (
                                <Typography sx={{ color: theme.palette.text.invertedsecondary, fontSize: 18, fontWeight: 405 }}>
                                    {percent}%
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid item lg={7} ml={2} xs={7} display="flex" justifyContent="left" flexDirection="column">
                        <Grid container display="flex" alignItems="center">
                            <Typography sx={{ color: theme.palette.text.invertedfourth }}>Circulating</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The circulating supply of ALX tokens, available in the market, equals the maximum supply minus the sum of staked and burnt tokens.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 15 }} />
                            </IconButton>
                        </Grid>
                        <Typography sx={{ color: theme.palette.text.invertedsecondary, mt: 0.5, fontWeight: 505 }}>
                            {formatValue(alphaCirculating - alphaBurnt)} of 100.00M
                        </Typography>
                        <Grid container display="flex">
                            <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 13 }}>
                                $
                                {((lpReserves[1] / lpReserves[0]) * (alphaCirculating - alphaBurnt)).toLocaleString('en-US', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}{' '}
                                of $
                                {((lpReserves[1] / lpReserves[0]) * 100000000).toLocaleString('en-US', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="100%" py={3} display="flex" justifyContent="center">
                    <TokenSupplyGraph data={data} />
                </Grid>
                <Grid container width="100%" display="flex" justifyContent="left" alignItems="center" mt={4}>
                    <Typography color={theme.palette.text.invertedprimary} fontWeight={405} pb={1} fontSize={15}>
                        Maximum supply
                    </Typography>
                    <Grid
                        container
                        sx={{
                            width: '100%',
                            bgcolor: theme.palette.borderColor,
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: '#FFFDC5',
                            p: 2,
                            borderRadius: 1,
                            alignItems: 'center'
                        }}
                    >
                        <Grid container width="10%">
                            <InfoIcon sx={{ color: brandColor, fontSize: 25 }} />
                        </Grid>
                        <Grid container width="90%">
                            <Typography sx={{ color: '#FEE3D9', fontWeight: 500, fontSize: 15 }}>
                                AlphaDAO initial supply is 100M ALX and can only be reduced through burning mechanisms.
                            </Typography>
                            <Typography sx={{ color: '#FEE3D9', fontWeight: 500, fontSize: 13, mt: 2 }}>
                                The ALX tokenomics and platform features provides several burning mechanisms and liquidity provision
                                allowing stability over long period of time.
                            </Typography>
                            <Button
                                href="https://alphadao.finance/whitepaper"
                                sx={{
                                    color: '#FEE3D9',
                                    bgcolor: 'transparent',
                                    textDecoration: 'underline',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    fontSize: 13
                                }}
                            >
                                Learn more
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="100%" display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                    <Grid
                        item
                        width="32%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedfourth}>MC</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The displayed value represents the current market capitalization of AlphaDAO, calculated as the product of the current price and the circulating tokens.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={505}>
                                ${formatValue((lpReserves[1] / lpReserves[0]) * (alphaCirculating - alphaBurnt))}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        width="32%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedfourth}>Burnt</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText('The number displays the total amount of ALX tokens that have been burnt.');
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                        </Grid>
                        <Grid container width="100%">
                            <Typography color={theme.palette.text.invertedprimary} pr={0.25} fontWeight={505}>
                                {formatValue(alphaBurnt)}
                            </Typography>
                            <img src={alxlogo} alt="logo" width={15} />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        width="32%"
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        justifyContent="left"
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedfourth}>Price</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText('The figure displays the current ALX price in dollar value.');
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                        </Grid>
                        <Grid container width="100%" display="flex" alignItems="center">
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={505}>
                                {(lpReserves[1] / lpReserves[0]).toLocaleString('en-US', {
                                    maximumFractionDigits: 6,
                                    minimumFractionDigits: 2
                                })}
                            </Typography>
                            <img src={usdtlogo} alt="logo" width={15} height={15} />
                        </Grid>
                    </Grid>
                    <Box
                        border={1}
                        borderRadius={1}
                        borderColor={theme.palette.text.invertedfourth}
                        p={1}
                        width="100%"
                        justifyContent="left"
                        mt={1}
                        color={theme.palette.text.invertedthird}
                    >
                        <Grid container width="100%" alignItems="center">
                            <Typography color={theme.palette.text.invertedthird}>Circ. ALX supply</Typography>
                            <IconButton
                                size="small"
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText('The figure the total amount of ALX tokens in circulation. TotalSupply - TotalBurnt');
                                    setModalOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ color: brandColor, fontSize: 14 }} />
                            </IconButton>
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405} pl={1}>
                                {formatValue(alphaCirculating - alphaBurnt)}
                            </Typography>
                            <Typography color={theme.palette.text.invertedfourth} px={0.5}>
                                of
                            </Typography>
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={405}>
                                100.00M
                            </Typography>
                        </Grid>
                        <LinearProgress
                            variant="determinate"
                            color="success"
                            value={((alphaCirculating - alphaBurnt) / 100000000) * 100}
                            sx={{ borderRadius: 1 }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <PopUpInfo open={modalOpen} setOpen={setModalOpen} text={popText} />
        </>
    );
};

export default SupplyStats;
