import { Box, Button, linearProgressClasses, Grid, LinearProgress, Typography, Popover } from '@mui/material';
import { useTheme } from '@mui/system';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { grey, lightGreen, red } from '@mui/material/colors';
import styled from '@emotion/styled';
import CountdownTimer from '../CountdownTimer';
import PaymentModal from 'ui-component/PaymentModal';
import busdlogo from '../../../assets/busdtransparent.svg';
import cakelogo from '../../../assets/cakelogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import wethlogo from '../../../assets/ethlogo.png';
import { useWeb3React } from '@web3-react/core';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useEffect, useState } from 'react';

// Custom styling for the LinearProgress
const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15, // You can change the height
    borderRadius: 1, // Set the border radius
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: grey[600]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 1,
        backgroundColor: lightGreen[400]
    }
}));

const LeasesMyInfo = ({
    tokenA,
    tokenB,
    amountA,
    amountTaken,
    totalPaidInTokenB,
    reservedPrice,
    installments,
    lessor,
    lessee,
    offset,
    installmentsPaid,
    createdLeaseTime,
    id,
    onPayment,
    addressA,
    addressB
}) => {
    const theme = useTheme();
    const { account } = useWeb3React();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id2 = open ? 'simple-popover' : undefined;
    // Calculate the target timestamp in milliseconds
    const totalDurationTimestamp = parseFloat(createdLeaseTime * 1000) + 86400000 * offset * (installments - 1);
    const nextPaymentTimestamp = (parseFloat(createdLeaseTime) + parseFloat(installmentsPaid * offset * 86400)) * 1000;

    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [tokens] = useState([
        {
            address: '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551',
            logoURI: cakelogo
        },
        {
            address: '0xd389253265dd6b85C47c410EC5fF0c6A383CE949',
            logoURI: busdlogo
        },
        {
            address: '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
            logoURI: wethlogo
        },
        {
            address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
            logoURI: btclogo
        }
    ]);
    const getTokenLogoURI = (address) => {
        const token = tokens.find((token) => token.address.toLowerCase() === address.toLowerCase());
        return token ? token.logoURI : null;
    };

    // Get the logo URI for tokenB
    const tokenBLogoURI = getTokenLogoURI(addressB);

    return (
        <>
            <Grid
                container
                sx={{
                    border: 1,
                    height: 60,
                    minWidth: 1350,
                    display: 'flex',
                    overflow: 'auto',
                    background: theme.palette.card.main,
                    borderColor: theme.palette.borderColor,
                    ':hover': {
                        background: theme.palette.card.light
                    }
                }}
            >
                <Grid item width="11%" alignItems="center" display="flex" pl={1}>
                    <Brightness1Icon sx={{ color: lessor === account ? red[500] : lightGreen[500], fontSize: 10 }} />
                    <Typography sx={{ color: theme.palette.text.invertedprimary, ml: 0.3, fontWeight: 500, fontSize: 14 }}>
                        {parseFloat(amountTaken / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })}{' '}
                        {tokenA}
                    </Typography>
                </Grid>
                <Grid item width="19%" alignItems="center" display="flex">
                    <Grid container display="flex" width="80%" justifyContent="center">
                        <Box sx={{ width: '100%', justifyContent: 'center' }}>
                            <CustomLinearProgress
                                variant="determinate"
                                value={(totalPaidInTokenB * 1e18 * 100) / ((reservedPrice / 1e18) * amountTaken) / 1e18}
                            />
                        </Box>
                        <Typography
                            sx={{
                                color: grey[50],
                                zIndex: 999,
                                fontSize: 14,
                                fontWeight: 700,
                                mt: -2.2
                            }}
                        >
                            {(totalPaidInTokenB / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 4,
                                minimumFractionDigits: 2
                            })}{' '}
                            /{' '}
                            {((amountTaken * reservedPrice) / 1e18 / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 4,
                                minimumFractionDigits: 2
                            })}
                            {` `} {tokenB}
                        </Typography>
                    </Grid>
                </Grid>
                {installments !== installmentsPaid ? (
                    <Grid item width="15%" alignItems="center" display="flex">
                        <HourglassTopIcon className="blinking-text" sx={{ color: grey[200], fontSize: 15 }} />
                        <CountdownTimer targetTimestamp={totalDurationTimestamp} />
                    </Grid>
                ) : (
                    <Grid item width="15%" alignItems="center" display="flex">
                        <HourglassFullIcon sx={{ color: grey[200], fontSize: 15 }} />
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500, fontSize: 14 }}>Finished</Typography>
                    </Grid>
                )}
                {installments !== installmentsPaid ? (
                    <Grid item width="15%" alignItems="center" display="flex">
                        <HourglassTopIcon className="blinking-text" sx={{ color: grey[200], fontSize: 15 }} />
                        <CountdownTimer targetTimestamp={nextPaymentTimestamp} />
                    </Grid>
                ) : (
                    <Grid item width="15%" alignItems="center" display="flex">
                        <HourglassFullIcon sx={{ color: grey[200], fontSize: 15 }} />
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500, fontSize: 14 }}>Finished</Typography>
                    </Grid>
                )}
                <Grid item width="15%" alignItems="center" display="flex">
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500, fontSize: 14, mr: 0.5 }}>
                        {parseFloat(((reservedPrice / 1e18) * amountTaken) / 1e18 / installments).toLocaleString('en-US', {
                            maximumFractionDigits: 6,
                            minimumFractionDigits: 2
                        })}{' '}
                    </Typography>
                    <img src={tokenBLogoURI} alt="logo" width={18} height={18} />
                </Grid>
                <Grid item width="15%" display="flex" alignItems="center">
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 500, fontSize: 14, mr: 0.5 }}>
                        1 {tokenA} /{' '}
                        {parseFloat(reservedPrice / 1e18).toLocaleString('en-US', {
                            maximumFractionDigits: 6,
                            minimumFractionDigits: 2
                        })}{' '}
                    </Typography>
                    <img src={tokenBLogoURI} alt="logo" width={18} height={18} />
                </Grid>
                <Grid item width="5%" display="flex" justifyContent="center" alignItems="center">
                    <Button onClick={handleClick} sx={{ ':hover': { background: theme.palette.card.light } }}>
                        <MoreHorizIcon sx={{ color: theme.palette.text.invertedprimary, fontSize: 16 }} />
                    </Button>
                </Grid>
            </Grid>
            <Popover
                id={id2}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    sx: {
                        width: 270,
                        bgcolor: 'transparent',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 3
                    }
                }}
            >
                <Grid container display="flex" justifyContent="space-between" my={1} px={2}>
                    <Grid item width="80" display="flex" justifyContent="left">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600 }}>
                            {tokenA} / {tokenB}
                        </Typography>
                    </Grid>
                    <Grid item width="20%" display="flex" justifyContent="right">
                        <Box sx={{ background: '#352e47', borderRadius: 5, px: 1, display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: '#e94798', fontSize: 12, fontWeight: 600 }}>
                                {installmentsPaid === installments ? 'Finished' : 'Lease'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" my={1} px={2}>
                    <Grid item display="flex" justifyContent="left">
                        <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 13, borderBottom: '1px dotted' }}>
                            {account === lessor ? `Received` : `Paid`} {tokenB}
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        <Typography textAlign="right" sx={{ color: lightGreen[300], fontWeight: 600, fontSize: 13 }}>
                            {(totalPaidInTokenB / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })}{' '}
                            /{' '}
                            {((amountTaken * reservedPrice) / 1e18 / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })}
                            {` `} {tokenB}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" my={1} px={2}>
                    <Grid item display="flex" justifyContent="left">
                        <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 13, borderBottom: '1px dotted' }}>
                            Payments made
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 13 }}>
                            {installmentsPaid} / {installments}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    my={1}
                    px={2}
                    sx={{ display: installmentsPaid === installments ? 'none' : 'flex' }}
                >
                    <Grid item display="flex" justifyContent="left">
                        <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 13, borderBottom: '1px dotted' }}>
                            Payment size
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 13 }}>
                            {parseFloat(((reservedPrice / 1e18) * amountTaken) / 1e18 / installments).toLocaleString('en-US', {
                                maximumFractionDigits: 4,
                                minimumFractionDigits: 2
                            })}{' '}
                            {tokenB}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" mt={1} mb={installmentsPaid === installments ? 2 : 1} px={2}>
                    <Grid item display="flex" justifyContent="left">
                        <Typography sx={{ color: theme.palette.text.invertedfourth, fontSize: 13, borderBottom: '1px dotted' }}>
                            Next payment
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        {installmentsPaid === installments ? (
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 13 }}>
                                Finished
                            </Typography>
                        ) : (
                            <CountdownTimer targetTimestamp={nextPaymentTimestamp} />
                        )}
                    </Grid>
                </Grid>
                {account === lessee ? (
                    <Grid container sx={{ display: installmentsPaid === installments ? 'none' : 'flex' }}>
                        <Grid item width="100%" my={2} px={2}>
                            <Button
                                fullWidth
                                onClick={() => {
                                    setPaymentModalOpen(true);
                                }}
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: grey[600],
                                    fontWeight: 500,
                                    borderRadius: 2,
                                    color: grey[50]
                                }}
                            >
                                Make a payment
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid container my={2} px={2}>
                            <Button
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: grey[600],
                                    fontWeight: 500,
                                    borderRadius: 2,
                                    color: grey[50]
                                }}
                            >
                                Terminate
                            </Button>
                        </Grid>
                    </>
                )}
            </Popover>
            <PaymentModal
                open={paymentModalOpen}
                id={id}
                setOpen={setPaymentModalOpen}
                tokenA={tokenA}
                tokenB={tokenB}
                addressA={addressA}
                addressB={addressB}
                leasesize={amountTaken}
                installments={installments}
                installmentspaid={installmentsPaid}
                reservedprice={reservedPrice}
                totalpaid={totalPaidInTokenB}
                onPayment={onPayment}
            />
        </>
    );
};

export default LeasesMyInfo;
