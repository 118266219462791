import { Grid, Typography, Box, TextField, styled, IconButton, InputAdornment, Modal } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTheme } from '@mui/system';
import { lightColorUI } from 'themes/constants';
import CircleIcon from '@mui/icons-material/Circle';
import { grey } from '@mui/material/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import { useState, useEffect } from 'react';
import GreyButton from 'ui-component/GreyButton';
import BrandButton from 'ui-component/BrandButton';
import { anyTokenSymbol, approveAnyToken, balanceOfAnyToken, getAllowanceAnyToken, takeLoanOffer } from 'components/wallet/sharesABI';
import busdlogo from '../../../assets/busdtransparent.svg';
import cakelogo from '../../../assets/cakelogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import ethlogo from '../../../assets/ethlogo.png';
import TransactionModal from 'ui-component/SuccessfulTransactionModal';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: '#433E49',
        textAlign: 'right',
        color: grey[200],
        borderRadius: 5,
        fontSize: 22,
        width: 'auto',
        fontWeight: 500,
        height: 40
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent'
        },
        '&:hover fieldset': {
            borderColor: 'transparent'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'right', // Ensure input text aligns right
            '&::placeholder': {
                fontSize: '16px', // Custom font size for placeholder
                color: 'rgba(0, 0, 0, 0.6)' // Custom color for placeholder
            }
        },
        '& .MuiInputLabel-outlined': {
            textAlign: 'right' // Align label to the right
        }
    }
});

const LeaseCard = ({
    handleOpen,
    onClose,
    tokenA,
    tokenB,
    originalSize,
    amountA,
    offset,
    installments,
    reservedPrice,
    tokenAddress,
    id,
    onPayment
}) => {
    const theme = useTheme();
    const [symbolA, setSymbolA] = useState('');
    const [symbolB, setSymbolB] = useState('');
    const { active, account } = useWeb3React();
    const [allowance, setAllowance] = useState(0);
    const [inputValue, setInputValue] = useState(0);
    const [amountToLease, setAmountToLease] = useState(1);
    const tokenImages = {
        '0xd389253265dd6b85C47c410EC5fF0c6A383CE949': busdlogo,
        '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551': cakelogo,
        '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8': btclogo,
        '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378': ethlogo
    };
    const imageUrl = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [successfullModal, setSuccessfullModal] = useState(false);
    const [popText, setPopText] = useState('');
    const [txnHash, setTxnHash] = useState('');
    const [myBalance, setMyBalance] = useState(0);
    const [formattedReservedPrice, setFormattedReservedPrice] = useState(0);
    const web3 = new Web3(window.ethereum);
    const { BN, toWei, fromWei } = web3.utils;
    const handleChangeAmount = (event) => {
        let value = event.target.value;

        // Remove any non-digit and non-decimal characters except the first dot
        value = value.replace(/[^\d.]/g, '');

        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18) {
                parts[1] = parts[1].slice(0, 18);
                value = parts.join('.');
            }
        }

        if (value) {
            // Ensure only one decimal point is present
            const decimalCount = value.split('.').length - 1;
            if (decimalCount > 1) {
                value = value.slice(0, value.lastIndexOf('.'));
            }

            const valueInWei = toWei(value, 'ether');

            const valueBN = web3.utils.toBN(valueInWei);
            const amountABN = web3.utils.toBN(amountA.toString());
            const tempInputValue = web3.utils.fromWei(amountA, 'ether');

            const valueReservedPrice = value * reservedPrice;
            const finalValueInWei = web3.utils.toWei((valueReservedPrice / 1e18 / installments).toString(), 'ether');

            if (valueBN.gt(amountABN)) {
                setInputValue(tempInputValue);
                setAmountToLease(amountA); // Update amountBN state
                setFormattedReservedPrice(finalValueInWei);
            } else {
                setInputValue(value);
                setAmountToLease(valueInWei);
                setFormattedReservedPrice(finalValueInWei);
            }
        } else {
            setAmountToLease(new BN(0)); // If input value is empty, set amountBN to 0
            setInputValue(0);
        }
    };

    const handleTakeLoan = async () => {
        try {
            await takeLoanOffer(id, amountToLease, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setSuccessfullModal(true); // Open the success modal
            onClose();
            onPayment();
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };
    const handleApprove = async () => {
        try {
            await approveAnyToken(tokenB, account, formattedReservedPrice, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            const newAllowance = await getAllowanceAnyToken(tokenB, account);
            setAllowance(newAllowance);
            setModalSubmitted(false);
            setSuccessfullModal(true); // Open the success modal
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };
    useEffect(() => {
        const handleFetchSymbol = async () => {
            if (tokenB) {
                const fetchedSymbolA = await anyTokenSymbol(tokenA);
                const fetchedSymbolB = await anyTokenSymbol(tokenB);
                setSymbolA(fetchedSymbolA || 'Symbol not found');
                setSymbolB(fetchedSymbolB || 'Symbol not found');
            }
        };

        const getAllowance = async () => {
            const tmpallowance = await getAllowanceAnyToken(tokenB, account);
            setAllowance(tmpallowance);
            console.log(`Your allowance: ${tmpallowance}`);
        };

        const fetchTokenBalance = async () => {
            const balance = await balanceOfAnyToken(tokenB, account);
            setMyBalance(parseFloat(balance * 1e18));
            console.log(balance * 1e18);
        };

        handleFetchSymbol();
        if (account && active) {
            getAllowance();
            fetchTokenBalance();
        }
    }, [tokenA, tokenB, allowance, account, active, amountToLease, reservedPrice, myBalance, symbolB, amountA, symbolA]);

    let buttonText;
    if (amountToLease > allowance) {
        buttonText = 'Approve';
    } else if (amountToLease === 0) {
        buttonText = 'Enter amount';
    } else {
        buttonText = 'Lease';
    }

    return (
        <>
            <Modal open={handleOpen} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                        background: theme.palette.card.main,
                        border: 0,
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Grid item display="flex" justifyContent="space-between">
                        <Grid item display="flex" xs={5}>
                            <img src={tokenImages[tokenA]} alt="alphaxlogo" width={45} height={45} style={{ borderRadius: '50%' }} />
                            <Typography fontWeight={505} color={theme.palette.text.invertedsecondary} fontSize={20} m={1}>
                                ${symbolA.toUpperCase()}
                            </Typography>
                        </Grid>
                        <Grid item display="flex" xs={4} justifyContent="right">
                            <a
                                href={`https://testnet.bscscan.com/token/${tokenAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography
                                    sx={{ textDecoration: 'underline' }}
                                    color={theme.palette.text.invertedthird}
                                    fontSize={15}
                                    mt={1}
                                >
                                    Bscscan
                                </Typography>
                            </a>
                            <LaunchIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15, mt: 1.45, ml: 0.5 }} />
                        </Grid>
                    </Grid>
                    <Grid container width="100%" justifyContent="left">
                        <Box
                            sx={{
                                width: 'auto',
                                border: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                borderColor: lightColorUI,
                                alignItems: 'center',
                                borderRadius: 1,
                                px: 1,
                                mt: 1.5
                            }}
                        >
                            <CircleIcon sx={{ color: lightColorUI, fontSize: 8 }} />
                            <Typography color={lightColorUI} fontSize={14} pl={0.5}>
                                Lease
                            </Typography>
                        </Box>
                        <Typography color={theme.palette.text.invertedsecondary} fontSize={16} fontWeight={500} px={1.25} mt={1.5}>
                            Duration: {offset * installments} days
                        </Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid container width="auto" alignItems="center" mt={1.5}>
                            <Typography color={theme.palette.text.invertedsecondary}>Original amount</Typography>
                            <IconButton
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText(
                                        'The display shows the initial quantity of tokens available for lease at the time of offer creation.'
                                    );
                                    setModalOpen(true);
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }} />
                            </IconButton>
                        </Grid>
                        <Typography color={theme.palette.text.invertedprimary} fontWeight={500} textAlign="right" mt={1.5}>
                            {parseFloat(originalSize / 1e18).toLocaleString('en-US', {
                                maximumFractionDigits: 7,
                                minimumFractionDigits: 2
                            })}{' '}
                            {symbolA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid container width="auto" alignItems="center" mt={1.5}>
                            <Typography color={theme.palette.text.invertedsecondary}>Available amount</Typography>
                            <IconButton
                                sx={{ bgcolor: 'transparent', ':hover': { bgcolor: 'transparent' } }}
                                onClick={() => {
                                    setPopText('The display indicates the maximum number of tokens available for lease.');
                                    setModalOpen(true);
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }} />
                            </IconButton>
                        </Grid>
                        <Typography color={theme.palette.text.invertedprimary} fontWeight={500} textAlign="right" mt={1.5}>
                            {parseFloat(amountA / 1e18).toLocaleString('en-US', { maximumFractionDigits: 7, minimumFractionDigits: 2 })}{' '}
                            {symbolA}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography color={theme.palette.text.invertedsecondary} mt={1.5}>
                            Pay with
                        </Typography>
                        <Grid item display="flex" alignItems="center" mt={1.5}>
                            <Typography color={theme.palette.text.invertedprimary} fontWeight={500} textAlign="right">
                                ${symbolB.toUpperCase()}
                            </Typography>
                            <img src={tokenImages[tokenB]} alt="Icon" style={{ width: 16, height: 16, borderRadius: '50%' }} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography color={theme.palette.text.invertedsecondary} mt={1.5}>
                            Price
                        </Typography>
                        <Typography color={theme.palette.text.invertedprimary} fontWeight={500} textAlign="right" mt={1.5}>
                            1 {symbolA} / {parseFloat(reservedPrice / 1e18).toLocaleString('en-US', { maximumFractionDigits: 5 })} {symbolB}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid container width="55%">
                            <Typography color={theme.palette.text.invertedsecondary}>Amount to lease:</Typography>
                        </Grid>
                        <Grid container width="40%">
                            <CustomTextField
                                value={inputValue}
                                onChange={handleChangeAmount}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img
                                                src={tokenImages[tokenA]}
                                                alt="Icon"
                                                style={{ width: 20, height: 20, borderRadius: '50%' }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="right" display="flex" width="100%" alignItems="center" py={1} px={1}>
                        <Grid item width="auto" justifyContent="right">
                            <Typography
                                color={theme.palette.text.invertedsecondary}
                                textAlign="right"
                                fontSize={13}
                                fontWeight={400}
                                letterSpacing="0.5px"
                            >
                                Payment size:{' '}
                                {(((amountToLease / 1e18) * reservedPrice) / 1e18 / installments).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 7
                                })}{' '}
                                {symbolB}
                            </Typography>
                            <Typography
                                color={theme.palette.text.invertedsecondary}
                                fontSize={13}
                                textAlign="right"
                                fontWeight={400}
                                letterSpacing="0.5px"
                            >
                                Total lease:{' '}
                                {(((amountToLease / 1e18) * reservedPrice) / 1e18).toLocaleString('en-us', {
                                    maximumFractionDigits: 7,
                                    minimumFractionDigits: 2
                                })}{' '}
                                {symbolB}
                            </Typography>
                            <Typography
                                color={theme.palette.text.invertedsecondary}
                                fontSize={13}
                                textAlign="right"
                                fontWeight={700}
                                letterSpacing="0.5px"
                            >
                                {installments - 1} payments -{' '}
                                {(((amountToLease / 1e18) * reservedPrice) / 1e18 / installments).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 7
                                })}{' '}
                                {symbolB} each {offset} days
                            </Typography>
                            <Typography
                                color={theme.palette.text.invertedsecondary}
                                fontSize={13}
                                textAlign="right"
                                fontWeight={400}
                                letterSpacing="0.5px"
                            >
                                Lease end date: 08.12.24
                            </Typography>
                            <Grid
                                container
                                sx={{
                                    borderTop: 1,
                                    justifyContent: 'right',
                                    borderColor: theme.palette.text.invertedsecondary,
                                    pt: 0.2,
                                    mt: 0.3
                                }}
                            >
                                <Typography
                                    color={theme.palette.text.invertedsecondary}
                                    textAlign="right"
                                    fontSize={13}
                                    fontWeight={700}
                                    letterSpacing="0.5px"
                                >
                                    Upfront payment:{' '}
                                    {(((amountToLease / 1e18) * reservedPrice) / 1e18 / installments).toLocaleString('en-us', {
                                        maximumFractionDigits: 7,
                                        minimumFractionDigits: 2
                                    })}{' '}
                                    {symbolB}
                                </Typography>
                                <LocalGasStationIcon sx={{ fontSize: 18, color: theme.palette.text.invertedsecondary }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container width="100%" display="flex" justifyContent="space-around" mt={2}>
                        <BrandButton
                            text={buttonText}
                            disabled={amountToLease === 0}
                            onClick={() => {
                                if (amountToLease > allowance) {
                                    handleApprove();
                                } else {
                                    handleTakeLoan();
                                }
                            }}
                            _width="49%"
                        />
                        <GreyButton text="Close" _width="49%" onClick={onClose} />
                    </Grid>
                </Box>
            </Modal>
            <PopUpInfo open={modalOpen} setOpen={setModalOpen} text={popText} />
            <TransactionModal open={successfullModal} setOpen={setSuccessfullModal} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default LeaseCard;
