import { Grid, Typography, Box, Modal, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalLinearStepper from './HorizontalLinearStepper';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 3,
    width: { xs: '95%', sm: '70%', md: '40%', lg: '30%' },
    boxShadow: 24,
    p: 4
};

const LearnHowItWorksLease = ({ open, setOpen, activeContent, activeSteps }) => {
    const theme = useTheme();
    const handleClose = () => setOpen(false);
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Grid container display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 18, fontWeight: 700 }}>
                        How to take a lease?
                    </Typography>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon sx={{ color: theme.palette.text.invertedprimary }} />
                    </IconButton>
                </Grid>
                <HorizontalLinearStepper setOpen={setOpen} activeContent={activeContent} activeSteps={activeSteps} />
            </Box>
        </Modal>
    );
};

export default LearnHowItWorksLease;
