import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { brandColor, mainColorUI } from 'themes/constants';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

export default function FilterERC20Amount({ isOpen, onClose, onAmountChange }) {
    const theme = useTheme();
    const [lowerRange, setLowerRange] = useState(0);
    const [higherRange, setHigherRange] = useState(0);

    const handleAmountChange = (amount1, amount2) => {
        const lowerAmountInWei = amount1 * 10 ** 18;
        const higherAmountInWei = amount2 * 10 ** 18;
        setLowerRange(lowerAmountInWei);
        setHigherRange(higherAmountInWei);
        if (onAmountChange) {
            onAmountChange(lowerAmountInWei, higherAmountInWei);
        }
    };

    const list = () => (
        <Box
            sx={{
                width: 'auto',
                bgcolor: 'transparent',
                borderRadius: 15,
                flexDirection: 'column',
                p: 2,
                display: 'flex',
                justifyContent: 'left'
            }}
        >
            <Grid
                container
                pb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={1}
                borderColor={theme.palette.text.invertedfourth}
            >
                <Grid item>
                    <Typography color={theme.palette.text.invertedprimary} pl={0.5} fontSize={18}>
                        Select an amount
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 22 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <FormControl>
                    <RadioGroup
                        defaultValue="10-100"
                        onChange={(event) => {
                            handleAmountChange(...JSON.parse(event.target.value));
                            onClose();
                        }}
                    >
                        <FormControlLabel
                            value={JSON.stringify([10, 100])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="10.00 - 100.00"
                        />
                        <FormControlLabel
                            value={JSON.stringify([100, 1000])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="100.00 - 1,000.00"
                        />
                        <FormControlLabel
                            value={JSON.stringify([1000, 10000])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="1,000.00 - 10,000.00"
                        />
                        <FormControlLabel
                            value={JSON.stringify([10000, 100000])}
                            control={<Radio sx={{ color: theme.palette.text.invertedfourth, '&.Mui-checked': { color: brandColor } }} />}
                            sx={{ color: theme.palette.text.invertedsecondary }}
                            label="10,000.00 - 100,000.00"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid container mt={2} display="flex" justifyContent="space-evenly" alignItems="center">
                <FormControl variant="outlined" fullWidth size="small" sx={{ width: '40%' }}>
                    <InputLabel
                        sx={{
                            color: theme.palette.text.invertedthird,
                            '&.Mui-focused': {
                                color: theme.palette.text.invertedthird
                            }
                        }}
                    >
                        Min. amount
                    </InputLabel>
                    <OutlinedInput
                        onChange={(event) => {
                            setLowerRange(parseFloat(event.target.value));
                        }}
                        label="Min. amount3"
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            input: { color: theme.palette.text.invertedsecondary }
                        }}
                    />
                </FormControl>
                <Typography sx={{ fontSize: 40, color: theme.palette.text.invertedfourth }}>-</Typography>
                <FormControl variant="outlined" fullWidth size="small" sx={{ width: '40%' }}>
                    <InputLabel
                        sx={{
                            color: theme.palette.text.invertedthird,
                            '&.Mui-focused': {
                                color: theme.palette.text.invertedthird
                            }
                        }}
                    >
                        Max. amount
                    </InputLabel>
                    <OutlinedInput
                        onChange={(event) => {
                            setHigherRange(parseFloat(event.target.value));
                        }}
                        label="Min. amount3"
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.invertedthird
                            },
                            input: { color: theme.palette.text.invertedsecondary }
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid container mt={2} display="flex" justifyContent="space-evenly" alignItems="center">
                <Button
                    fullWidth
                    onClick={() => {
                        handleAmountChange(lowerRange, higherRange);
                        onClose();
                    }}
                    sx={{
                        textTransform: 'none',
                        borderRadius: 2,
                        bgcolor: brandColor
                    }}
                >
                    <Typography color={theme.palette.text.invertedprimary} fontSize={17}>
                        Set amount
                    </Typography>
                </Button>
            </Grid>
        </Box>
    );

    return (
        <div>
            <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
                <Box
                    sx={{
                        bgcolor: theme.palette.card.main
                    }}
                >
                    {list()}
                </Box>
            </Drawer>
        </div>
    );
}

FilterERC20Amount.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAmountChange: PropTypes.func.isRequired
};
