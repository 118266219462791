import { Box, Button, Modal, Grid, IconButton, TextField, Typography, Avatar } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { useState, useEffect } from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import BigNumber from 'bignumber.js';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import { useWeb3React } from '@web3-react/core';
import { approveAnyToken, balanceOfAnyToken, getAllowanceAnyToken } from 'components/wallet/sharesABI';
import SupplyTokenListModal from 'ui-component/SupplyTokenListModal';
import PaymentTokensModal from 'ui-component/PaymentTokensModal';
import BrandButton from 'ui-component/BrandButton';
import GreyButton from 'ui-component/GreyButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import Web3 from 'web3';
import TransactionModal from 'ui-component/SuccessfulTransactionModal';
import cakelogo from '../../../assets/cakelogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import PreviewCreateOffer from './PreviewCreateOffer';
import { brandColor } from 'themes/constants';

const CreateLendOffer = ({ handleOpen, onClose, onOfferCreated }) => {
    const theme = useTheme();
    const web3 = new Web3(window.ethereum);
    const { active, account } = useWeb3React();
    const [allowance, setAllowance] = useState(0);
    const [txnHash, setTxnHash] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInfoOpen, setModalInfoOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [modalTokensOpen, setModalTokensOpen] = useState(false);
    const [previewOfferModal, setPreviewOfferModal] = useState(false);
    const [popText, setPopText] = useState('');
    const [offset, setOffset] = useState(7);
    const [gracePeriod, setGracePeriod] = useState(30);
    const [amountValue, setAmountValue] = useState(0);
    const [collateralAmountValue, setCollateralAmountValue] = useState(0);
    const [myBalance, setMyBalance] = useState(0);
    const [inputValue, setInputValue] = useState(0);
    const [modalCollateralTokensOpen, setModalCollateralTokensOpen] = useState(false);

    const [selectedSupplyToken, setSelectedSupplyToken] = useState({
        symbol: 'CAKE',
        address: '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551',
        logoURI: cakelogo
    });

    const [selectedCollateralToken, setSelectedCollateralToken] = useState({
        symbol: 'WBTC',
        address: '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
        logoURI: btclogo
    });

    const handleIncrementGracePeriod = () => {
        setGracePeriod((prevOffset) => prevOffset + 1); // Increments the offset
    };

    const handleDecrementGracePeriod = () => {
        setGracePeriod((prevOffset) => Math.max(2, prevOffset - 1)); // Decrements the offset but not below 1
    };

    const handleIncrementOffset = () => {
        setOffset((prevOffset) => prevOffset + 1); // Increments the offset
    };

    const handleDecrementOffset = () => {
        if (offset > 7) {
            setOffset((prevOffset) => Math.max(1, prevOffset - 1)); // Decrements the offset but not below 1
        } else {
            setOffset(7);
        }
    };

    const handleCollateralAmountChange = (event) => {
        // Get the input value and remove any non-numeric characters except the decimal point
        let value = event.target.value.replace(/[^0-9.]/g, '');

        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18) {
                parts[1] = parts[1].slice(0, 18);
                value = parts.join('.');
            }
        }
        // If the result is an empty string or only the decimal point, treat it as '0'
        if (value === '' || value === '.') {
            value = '0';
        }

        // Convert the cleaned value to Wei if it's a valid number
        if (!Number.isNaN(value) && value !== '') {
            const web3 = new Web3();
            // Ensure the value is in standard form without scientific notation
            const valueStandard = new BigNumber(value).toFixed();
            const valueInWei = web3.utils.toWei(valueStandard, 'ether');
            setCollateralAmountValue(valueInWei);
        } else {
            setCollateralAmountValue('0'); // Set to '0' Wei if the input is still invalid
        }

        // Optionally, update the input field to reflect the cleaned and validated numeric value
        event.target.value = value;
    };

    const handleAmountChange = (event) => {
        let value = event.target.value;
        value = value.replace(/[^\d.]/g, '');
        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18) {
                parts[1] = parts[1].slice(0, 18);
                value = parts.join('.');
            }
        }

        if (value) {
            // Ensure only one decimal point is present
            const decimalCount = value.split('.').length - 1;
            if (decimalCount > 1) {
                value = value.slice(0, value.lastIndexOf('.'));
            }

            // Convert the value to Wei
            const valueInWei = web3.utils.toWei(value, 'ether');

            // Convert the input value to Wei-level BigNumber for comparison
            const valueBN = web3.utils.toBN(valueInWei);
            const balanceBN = web3.utils.toBN(myBalance.toString());
            const tempInputValue = web3.utils.fromWei(myBalance, 'ether');

            if (valueBN.gt(balanceBN)) {
                setAmountValue(myBalance);
                setInputValue(tempInputValue);
            } else {
                setAmountValue(valueInWei);
                setInputValue(value);
            }
        } else {
            setAmountValue('0'); // Set to '0' Wei if the input is still invalid
            setInputValue(0);
        }
    };

    const handleSupplyTokenSelect = (token) => {
        setSelectedSupplyToken(token);
        setModalTokensOpen(false); // Optionally close the modal upon selection
    };

    const handleCollateralTokenSelect = (token) => {
        setSelectedCollateralToken(token);
        setModalCollateralTokensOpen(false); // Optionally close the modal upon selection
    };

    const handleApprove = async () => {
        try {
            await approveAnyToken(selectedSupplyToken.address, account, amountValue, (hash) => {
                setTxnHash(hash); // Set the transaction hash
                setModalSubmitted(true);
            });
            setModalSubmitted(false);
            setModalOpen(true); // Open the success modal
            const tmpallowance = await getAllowanceAnyToken(selectedSupplyToken.address, account);
            setAllowance(tmpallowance * 1e18);
        } catch (error) {
            console.error('Transaction failed', error);
            if (error.code === 4001) {
                setModalRejection(true);
            }
        }
    };

    useEffect(() => {
        const fetchTokenBalance = async () => {
            const balance = await balanceOfAnyToken(selectedSupplyToken.address, account);
            setMyBalance(balance);
        };

        const getAllowance = async () => {
            const tmpallowance = await getAllowanceAnyToken(selectedSupplyToken.address, account);
            setAllowance(tmpallowance * 1e18);
            console.log(tmpallowance * 1e18);
        };

        if (active && account) {
            fetchTokenBalance();
            getAllowance();
        }
    }, [account, active, selectedSupplyToken.address, allowance]);
    let buttonText;
    if (amountValue > allowance) {
        buttonText = 'Approve';
    } else if (amountValue > 0 && collateralAmountValue > 0) {
        buttonText = 'Create offer';
    } else {
        buttonText = 'Enter amounts';
    }
    return (
        <>
            <Modal open={handleOpen} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: '60%', md: '40%', lg: '30%' },
                        background: theme.palette.card.main,
                        border: 0,
                        borderRadius: 2,
                        boxShadow: 24,
                        py: 4,
                        px: 2
                    }}
                >
                    <Grid container sx={{ width: '100%', p: 2, alignItems: 'center' }}>
                        <Typography color={theme.palette.text.invertedthird} fontSize={24} fontWeight={500} letterSpacing="0.5px">
                            Lend {selectedSupplyToken.symbol}
                        </Typography>
                        <Avatar src={selectedSupplyToken.logoURI} sx={{ width: 20, height: 20, ml: 1 }} />
                    </Grid>
                    <Grid container justifyContent="space-between" display="flex" width="100%" alignItems="center" my={1} px={2}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Typography color={theme.palette.text.invertedthird} fontSize={15} fontWeight={400} letterSpacing="0.5px">
                                Supply asset
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setPopText('The asset and amount that will be available for users to lease.');
                                    setModalInfoOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ fontSize: 15, color: brandColor }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                borderRadius: 1,
                                border: 1,
                                borderColor: theme.palette.text.invertedfourth,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Grid item lg={8} xs={5.5} display="flex" flexDirection="row" alignItems="center">
                                <TextField
                                    variant="standard"
                                    onChange={handleAmountChange}
                                    value={inputValue}
                                    fullWidth
                                    size="small"
                                    InputProps={{ disableUnderline: true }}
                                    sx={{
                                        '& .MuiInputBase-input': { color: theme.palette.text.invertedthird, fontSize: 18, fontWeight: 600 },
                                        ml: 1.5,
                                        mt: 0.5
                                    }}
                                />
                                {amountValue !== 0 ? (
                                    <IconButton
                                        sx={{ height: 25, width: 25 }}
                                        onClick={() => {
                                            setAmountValue(0);
                                        }}
                                    >
                                        <CancelIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }} />
                                    </IconButton>
                                ) : null}
                            </Grid>
                            <Grid container width="auto" justifyContent="right" mr={0.5}>
                                <Button
                                    onClick={() => {
                                        setModalTokensOpen(true);
                                    }}
                                    endIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.invertedprimary, fontSize: 17 }} />}
                                    startIcon={<Avatar src={selectedSupplyToken.logoURI} sx={{ width: 20, height: 20 }} />}
                                    sx={{
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        fontSize: 17,
                                        color: theme.palette.text.invertedprimary
                                    }}
                                >
                                    {selectedSupplyToken === 'null' ? 'BUSD' : selectedSupplyToken.symbol}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography color={theme.palette.text.invertedfourth} ml={1.8} fontSize={13}>
                                    $0
                                </Typography>
                            </Grid>
                            <Grid item xs={7} justifyContent="right" display="flex" mr={2}>
                                <Button sx={{ color: theme.palette.text.invertedsecondary, bgcolor: 'transparent', textTransform: 'none' }}>
                                    Balance: {(myBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" display="flex" width="100%" alignItems="center" mt={3} px={2}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Typography color={theme.palette.text.invertedthird} fontSize={15} fontWeight={400} letterSpacing="0.5px">
                                Collateral
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setPopText('Please indicate the type and quantity of tokens required for the lease payment.');
                                    setModalInfoOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ fontSize: 15, color: brandColor }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                width: '100%',
                                borderRadius: 1,
                                border: 1,
                                borderColor: theme.palette.text.invertedfourth,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Grid item lg={8} xs={5.5} display="flex" flexDirection="row" alignItems="center">
                                <TextField
                                    variant="standard"
                                    label="0"
                                    onChange={handleCollateralAmountChange}
                                    fullWidth
                                    size="small"
                                    InputProps={{ disableUnderline: true }}
                                    sx={{
                                        '& .MuiInputBase-input': { color: theme.palette.text.invertedthird, fontSize: 18, fontWeight: 600 },
                                        ml: 1.5,
                                        mt: -1
                                    }}
                                />
                                {collateralAmountValue !== 0 ? (
                                    <IconButton
                                        sx={{ height: 25, width: 25, ':hover': { bgcolor: 'transparent' } }}
                                        onClick={() => {
                                            setCollateralAmountValue(0);
                                        }}
                                    >
                                        <CancelIcon sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }} />
                                    </IconButton>
                                ) : null}
                            </Grid>
                            <Grid item justifyContent="right" width="auto" display="flex">
                                <Button
                                    onClick={() => {
                                        setModalCollateralTokensOpen(true);
                                    }}
                                    endIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.invertedprimary, fontSize: 17 }} />}
                                    startIcon={<Avatar src={selectedCollateralToken.logoURI} sx={{ width: 20, height: 20 }} />}
                                    sx={{
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        fontSize: 17,
                                        color: theme.palette.text.invertedprimary,
                                        px: 2
                                    }}
                                >
                                    {selectedCollateralToken === 'null' ? 'WBTC' : selectedCollateralToken.symbol}
                                </Button>
                            </Grid>
                            <Grid container width="35%" alignItems="top" mb={1}>
                                <Typography color={theme.palette.text.invertedfourth} ml={1.8} fontSize={13}>
                                    $0
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            mt: 2
                        }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            width="44%"
                            sx={{ bgcolor: theme.palette.card.light, borderRadius: 2, py: 0.5 }}
                        >
                            <Typography sx={{ color: grey[100] }}>Grace period</Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setPopText('The amount of payments that the lessee must pay before receiving the supplied asset.');
                                    setModalInfoOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ fontSize: 15, color: brandColor }} />
                            </IconButton>
                            <Grid container justifyContent="space-around" alignItems="center">
                                <IconButton
                                    size="small"
                                    sx={{ color: grey[100] }}
                                    onClick={() => {
                                        handleDecrementGracePeriod();
                                    }}
                                >
                                    <RemoveIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                                <Typography sx={{ color: grey[100] }}>{gracePeriod}</Typography>
                                <IconButton
                                    size="small"
                                    sx={{ color: grey[100] }}
                                    onClick={() => {
                                        handleIncrementGracePeriod();
                                    }}
                                >
                                    <AddIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="center"
                            width="44%"
                            sx={{ bgcolor: theme.palette.card.light, borderRadius: 2, py: 0.5 }}
                        >
                            <Typography sx={{ color: grey[100] }}>Interest rate</Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setPopText(
                                        'The grace period is the duration for which the supplied asset will be locked. Once this period ends, the supplied asset is unlocked, allowing the lender to terminate the loan and retain the collateral.'
                                    );
                                    setModalInfoOpen(true);
                                }}
                            >
                                <InfoIcon sx={{ fontSize: 15, color: brandColor }} />
                            </IconButton>
                            <Grid container justifyContent="space-around" alignItems="center">
                                <IconButton
                                    size="small"
                                    sx={{ color: grey[100] }}
                                    onClick={() => {
                                        handleDecrementOffset();
                                    }}
                                >
                                    <RemoveIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                                <Typography sx={{ color: grey[100] }}>{offset}</Typography>
                                <IconButton
                                    size="small"
                                    sx={{ color: grey[100] }}
                                    onClick={() => {
                                        handleIncrementOffset();
                                    }}
                                >
                                    <AddIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="right" display="flex" width="100%" alignItems="center" py={1} px={3}>
                        <Grid container width="80%" sx={{ justifyContent: 'right', display: 'flex', flexDirection: 'column' }}>
                            <Typography color={theme.palette.text.invertedsecondary} textAlign="right" fontSize={13} fontWeight={600}>
                                Supplying amount: {amountValue} {selectedSupplyToken.symbol}
                            </Typography>
                            <Typography color={theme.palette.text.invertedsecondary} fontSize={13} textAlign="right" fontWeight={600}>
                                Collateral:
                                {collateralAmountValue !== 0
                                    ? (collateralAmountValue / 1e18).toLocaleString('en-us', {
                                          maximumFractionDigits: 4,
                                          minimumFractionDigits: 2
                                      })
                                    : '0'}{' '}
                                {selectedCollateralToken.symbol}
                            </Typography>
                            <Typography color={theme.palette.text.invertedsecondary} textAlign="right" fontSize={13} fontWeight={600}>
                                Grace period: {gracePeriod} days
                            </Typography>
                            <Typography
                                color={theme.palette.text.invertedsecondary}
                                textAlign="right"
                                borderBottom={1}
                                fontSize={13}
                                fontWeight={600}
                            >
                                Interest rate: {gracePeriod} days
                            </Typography>
                        </Grid>
                        <Grid container sx={{ justifyContent: 'right' }}>
                            <Typography color={theme.palette.text.invertedsecondary} textAlign="right" fontSize={13} fontWeight={600}>
                                GWEI +{' '}
                                {(amountValue / 1e18).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                                {selectedSupplyToken.symbol}
                            </Typography>
                            <LocalGasStationIcon sx={{ fontSize: 18, color: theme.palette.text.invertedsecondary }} />
                        </Grid>
                    </Grid>
                    <Grid container width="100%" p={2}>
                        <BrandButton
                            text={buttonText}
                            disabled={amountValue === 0 || collateralAmountValue === 0}
                            onClick={() => {
                                if (amountValue > allowance) {
                                    handleApprove();
                                } else {
                                    setPreviewOfferModal(true);
                                }
                            }}
                            _width="49%"
                            startIcon={amountValue > myBalance || amountValue === 0 || collateralAmountValue === 0 ? null : <FeedIcon />}
                            sx={{ fontSize: 16 }}
                        />
                        <GreyButton text="Close" _width="49%" onClick={onClose} sx={{ ml: 0.5 }} />
                    </Grid>
                </Box>
            </Modal>
            <PopUpInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={popText} />
            <SupplyTokenListModal open={modalTokensOpen} setOpen={setModalTokensOpen} onTokenSelect={handleSupplyTokenSelect} />
            <PaymentTokensModal
                open={modalCollateralTokensOpen}
                setOpen={setModalCollateralTokensOpen}
                onTokenSelect={handleCollateralTokenSelect}
            />
            <TransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
            <PreviewCreateOffer
                tokenA={selectedSupplyToken.address}
                tokenB={selectedCollateralToken.address}
                amountA={amountValue}
                amountB={collateralAmountValue}
                paymentsQunatity={gracePeriod}
                offset={offset}
                handleOpen={previewOfferModal}
            />
        </>
    );
};

export default CreateLendOffer;
