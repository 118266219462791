// material-ui
import { useTheme } from '@emotion/react';
import { Typography, Modal, Box, Grid, IconButton } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useState } from 'react';
import PropTypes from 'prop-types';
import PaymentTokensList from 'views/utilities/PaymentTokensList';

// ==============================|| TransactionModal ||============================== //

const PaymentTokensModal = ({ open, setOpen, onTokenSelect }) => {
    const theme = useTheme();
    const handleClose = () => setOpen(false);
    const [selectedToken] = useState(null);
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                    background: theme.palette.card.main,
                    border: 0,
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: 1,
                        pb: 2,
                        borderColor: theme.palette.text.invertedsecondary
                    }}
                >
                    <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 20, fontWeight: 500 }}>
                        Select a token
                    </Typography>
                    <IconButton
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <ClearRoundedIcon sx={{ color: theme.palette.text.invertedsecondary }} />
                    </IconButton>
                </Grid>
                <Grid container sx={{ maxHeight: '60vh', overflow: 'scroll', mt: 3 }}>
                    <PaymentTokensList tokenAddress={selectedToken} onTokenSelect={onTokenSelect} />
                </Grid>
            </Box>
        </Modal>
    );
};

// Define prop types for PaymentTokensModal
PaymentTokensModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onTokenSelect: PropTypes.func.isRequired
};

export default PaymentTokensModal;
