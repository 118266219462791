import { Grid, Typography, Chip, Button } from '@mui/material';
import { darken, lighten, useTheme } from '@mui/system';
import { useState, useEffect } from 'react';
import LeaseCard from './LeaseCard';
import { anyTokenSymbol } from 'components/wallet/sharesABI';
import busdlogo from '../../../assets/busdtransparent.svg';
import alxlogo from '../../../assets/alxlogo.svg';
import cakelogo from '../../../assets/cakelogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import ethlogo from '../../../assets/ethlogo.png';
import { grey } from '@mui/material/colors';

const LeaseSimpleListCard = ({
    tokenA,
    tokenB,
    originalSize,
    amountA,
    reservedPrice,
    offset,
    installments,
    lessor,
    onPayment,
    tokenAddress,
    id
}) => {
    const theme = useTheme();
    const [symbolA, setSymbolA] = useState('');
    const [symbolB, setSymbolB] = useState('');
    const [payer, setPayer] = useState('');
    const [openDetailedCard, setOpenDetailedCard] = useState(false);
    const tokenImages = {
        '0xd389253265dd6b85C47c410EC5fF0c6A383CE949': busdlogo,
        '0x8d008B313C1d6C7fE2982F62d32Da7507cF43551': cakelogo,
        '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8': btclogo,
        '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378': ethlogo,
        default: alxlogo
    };

    // const imageUrl = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
    const handleFetchSymbol = async () => {
        if (tokenB) {
            const fetchedSymbolA = await anyTokenSymbol(tokenA);
            const fetchedSymbolB = await anyTokenSymbol(tokenB);
            const fetchedPayer = await lessor;
            setSymbolA(fetchedSymbolA || 'Symbol not found');
            setSymbolB(fetchedSymbolB || 'Symbol not found');
            setPayer(fetchedPayer || 'Lessor not found');
        }
    };
    const openDetailedOfferCard = () => {
        setOpenDetailedCard(true);
    };

    const closeDetailedOfferCard = () => {
        setOpenDetailedCard(false);
    };

    useEffect(() => {
        handleFetchSymbol();
    }, [tokenB]);

    return (
        <>
            <Grid
                container
                sx={{
                    p: 2,
                    my: 0.5,
                    boxShadow: 6,
                    display: 'flex',
                    borderRadius: 2,
                    overflowX: 'scroll',
                    justifyContent: 'space-between',
                    background: theme.palette.card.main,
                    ':hover': {
                        cursor: 'pointer',
                        background: darken(theme.palette.card.main, 0.1)
                    }
                }}
            >
                {/* Pool */}
                <Grid container display="flex" alignItems="center" sx={{ width: { lg: 'auto', md: 'auto', sm: '100%', xs: '100%' } }}>
                    <Grid item sx={{ width: 30, height: 30, borderRadius: '50%' }}>
                        <img src={tokenImages[tokenA]} alt="logo" style={{ borderRadius: '50%', height: '100%' }} />
                    </Grid>
                    <Grid item sx={{ ml: -1, width: 30, height: 30, borderRadius: '50%' }}>
                        <img src={tokenImages[tokenB]} alt="logo" style={{ borderRadius: '50%', height: '100%' }} />
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Typography fontWeight={600} color={theme.palette.text.invertedsecondary} fontSize={14} ml={1}>
                            {symbolA.toUpperCase()} / {symbolB.toUpperCase()}
                        </Typography>
                        <Grid container ml={0.75}>
                            <Chip
                                sx={{ bgcolor: '#432847', width: 'auto', height: 20 }}
                                label={<Typography sx={{ fontSize: 10, color: '#e74797', fontWeight: 700 }}>P2P</Typography>}
                            />
                            <Chip
                                sx={{ bgcolor: lighten(theme.palette.card.main, 0.1), width: 'auto', height: 20, ml: 0.5 }}
                                label={
                                    <Typography sx={{ fontSize: 10, color: grey[400], fontWeight: 700 }}>
                                        {offset * installments - offset} days
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* Available */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                        mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                        width: { lg: 'auto', md: 'auto', sm: '48%', xs: '48%' },
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        color={theme.palette.text.invertedfourth}
                        sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' } }}
                    >
                        Available
                    </Typography>
                    <Typography color={theme.palette.text.invertedsecondary}>
                        {parseFloat(amountA / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 })}{' '}
                        {symbolA.toUpperCase()}
                    </Typography>
                    <Typography color={theme.palette.text.invertedfourth} fontSize={12}>
                        $
                        {(parseFloat(amountA / 1e18) * 3.84).toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}
                    </Typography>
                </Grid>
                {/* Price */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                        ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                        mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                        width: { lg: 'auto', md: 'auto', sm: '48%', xs: '48%' },
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        color={theme.palette.text.invertedfourth}
                        sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' } }}
                    >
                        Price
                    </Typography>
                    <Typography
                        color={theme.palette.text.invertedsecondary}
                        sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}
                    >
                        {parseFloat(reservedPrice / 1e18).toLocaleString('en-US', {
                            maximumFractionDigits: 4,
                            minimumFractionDigits: 4
                        })}{' '}
                        <img src={tokenImages[tokenB]} alt="logo" style={{ borderRadius: '50%', height: 17, width: 17, marginLeft: 4 }} />
                    </Typography>
                    <Typography
                        color={theme.palette.text.invertedfourth}
                        sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: 12 }}
                    >
                        per {symbolA.toUpperCase()}
                    </Typography>
                </Grid>
                {/* Payments */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                        ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                        width: { lg: 'auto', md: 'auto', sm: '48%', xs: '48%' }
                    }}
                >
                    <Typography
                        color={theme.palette.text.invertedfourth}
                        sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }, mr: 1 }}
                    >
                        Payments
                    </Typography>
                    <Typography color={theme.palette.text.invertedsecondary} fontWeight={600}>
                        {installments} pmts
                    </Typography>
                </Grid>
                {/* Offset */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                        ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                        width: { lg: 'auto', md: 'auto', sm: '48%', xs: '48%' }
                    }}
                >
                    <Typography
                        color={theme.palette.text.invertedfourth}
                        sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }, mr: 1 }}
                    >
                        Offset
                    </Typography>
                    <Typography color={theme.palette.text.invertedsecondary} fontWeight={600}>
                        {offset} days
                    </Typography>
                </Grid>
                {/* Lease button */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: { lg: 2, md: 2, sm: 0, xs: 0 },
                        mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                        width: { lg: 'auto', md: 'auto', sm: '48%', xs: '48%' }
                    }}
                >
                    <Button
                        onClick={() => {
                            openDetailedOfferCard();
                        }}
                        sx={{
                            py: 1,
                            border: 1,
                            width: 80,
                            fontSize: 12,
                            color: '#fff',
                            borderRadius: 2,
                            textTransform: 'none',
                            borderColor: '#353951',
                            background: 'transparent',
                            ':hover': {
                                borderColor: lighten('#353951', 0.2),
                                background: 'transparent'
                            }
                        }}
                    >
                        Lease
                    </Button>
                </Grid>
            </Grid>
            <LeaseCard
                onClose={closeDetailedOfferCard}
                handleOpen={openDetailedCard}
                amountA={amountA}
                tokenA={tokenA}
                originalSize={originalSize}
                tokenB={tokenB}
                reservedPrice={reservedPrice}
                tokenAddress={tokenAddress}
                offset={offset}
                lessor={lessor}
                installments={installments}
                id={id}
                onPayment={onPayment}
            />
        </>
    );
};

export default LeaseSimpleListCard;
