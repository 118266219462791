// material-ui
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';

const GreyButton = ({ text, _width, onClick, sx }) => {
    useTheme();
    return (
        <Button
            onClick={() => {
                onClick();
            }}
            sx={{
                background: '#757575',
                color: grey[100],
                borderRadius: 2,
                textTransform: 'none',
                width: _width,
                ':hover': { background: grey[800] },
                ...sx
            }}
        >
            {text}
        </Button>
    );
};

GreyButton.propTypes = {
    text: PropTypes.string.isRequired, // assuming text should be a string and is required
    _width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // width can be either string or number
    onClick: PropTypes.func, // onClick should be a function
    sx: PropTypes.object // sx should be an object
};

export default GreyButton;
