import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

function CountdownTimerAuction({ startingTimestamp }) {
    const theme = useTheme();
    const sixtyMinutesInMilliseconds = 60 * 60 * 1000;

    const calculateTimeLeft = (startTime) => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        const timeLeftInCurrentInterval = sixtyMinutesInMilliseconds - (elapsed % sixtyMinutesInMilliseconds);

        const hours = Math.floor((timeLeftInCurrentInterval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeftInCurrentInterval % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeftInCurrentInterval % (1000 * 60)) / 1000);

        return {
            hours: hours < 10 ? `0${hours}` : hours.toString(),
            minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
            seconds: seconds < 10 ? `0${seconds}` : seconds.toString()
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startingTimestamp));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(startingTimestamp));
        }, 1000);

        return () => clearInterval(timer);
    }, [startingTimestamp]);

    return (
        <>
            <Typography sx={{ color: theme.palette.text.invertedsecondary, fontWeight: 600, fontSize: 18 }}>
                {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </Typography>
        </>
    );
}

CountdownTimerAuction.propTypes = {
    startingTimestamp: PropTypes.number.isRequired
};

export default CountdownTimerAuction;
