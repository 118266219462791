import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import { grey, red } from '@mui/material/colors';

function CountdownTimer({ targetTimestamp, onCountdownEnd }) {
    const theme = useTheme();
    const [timeLeft, setTimeLeft] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
        negative: false
    });

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const difference = targetTimestamp - now;

            let days;
            let hours;
            let minutes;
            let seconds;
            let negative = false;

            if (difference >= 0) {
                days = Math.floor(difference / (1000 * 60 * 60 * 24));
                hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                seconds = Math.floor((difference % (1000 * 60)) / 1000);
            } else {
                negative = true;
                const positiveDifference = Math.abs(difference);
                days = Math.floor(positiveDifference / (1000 * 60 * 60 * 24));
                hours = Math.floor((positiveDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                minutes = Math.floor((positiveDifference % (1000 * 60 * 60)) / (1000 * 60));
                seconds = Math.floor((positiveDifference % (1000 * 60)) / 1000);
            }

            setTimeLeft({
                days: days < 10 ? `0${days}` : days.toString(),
                hours: hours < 10 ? `0${hours}` : hours.toString(),
                minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
                seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
                negative
            });

            if (difference < 0 && onCountdownEnd) {
                onCountdownEnd();
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetTimestamp, onCountdownEnd]);

    return (
        <>
            <Typography sx={{ color: timeLeft.negative ? red[600] : theme.palette.text.invertedsecondary, fontWeight: 600, fontSize: 14 }}>
                {timeLeft.negative ? '-' : ''}
                {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </Typography>
        </>
    );
}

CountdownTimer.propTypes = {
    targetTimestamp: PropTypes.number.isRequired,
    onCountdownEnd: PropTypes.func
};

export default CountdownTimer;
